import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
    shifts: [],
    cycles: [],
  },
})
  .get({
    action: 'getWorkCalendar',
    path: ({ startDate, endDate }) => `/v2/work-calendar/${startDate}/${endDate}`,
  })
  .get({
    action: 'getShiftCycle',
    path: ({ cycleId }) => `/v2/work-calendar/cycle/${cycleId}`,
  })
  .post({
    action: 'createWorkCalendar',
    path: '/v2/work-calendar/cycle',
  })
  .put({
    action: 'updateShiftInstance',
    path: ({ shiftInstanceId }) => `/v2/work-calendar/instance/${shiftInstanceId}`,
  })
  .delete({
    action: 'deleteShiftInstance',
    path: ({ shiftInstanceId }) => `/v2/work-calendar/instance/${shiftInstanceId}`,
  })
  .put({
    action: 'updateShiftCycle',
    path: '/v2/work-calendar/cycle',
  })
  .delete({
    action: 'deleteShiftCycle',
    path: '/v2/work-calendar/cycle',
  })
  .getStore();

store.getters = {
  shiftInstances: (s, g, rs, rg) => s.list && rg['plant/shifts'] && s.list.map(b => ({
    ...b,
    name: rg['plant/shifts'].find(c => c.id === b.shiftId)?.name || '',
  })),

  shiftInstancesBetween: (s, g) => (startDate, endDate) => g.shiftInstances
    && g.shiftInstances.filter(a => a.startDate >= startDate && a.startDate < endDate),

  shiftInstancesDuringPeriod: (s, g) => (startDate, endDate) => g.shiftInstances
    && g.shiftInstances
      .filter(a => {
        if (a.startDate < startDate) {
          return a.endDate > startDate;
        }
        return a.startDate < endDate;
      }),

  aggregatedDayShifts: (s, g) => (startDate, endDate, elementIds = null) => {
    let shiftInstances = g.shiftInstancesBetween(startDate, endDate);
    if (elementIds && elementIds.length) {
      shiftInstances = shiftInstances.filter(a => elementIds.includes(a.elementId));
    }

    if (!shiftInstances) return [];
    const result = shiftInstances.reduce((acc, elem) => {
      const found = acc.find(a => a.shiftId === elem.shiftId
        && a.id === elem.id
        && moment.unix(a.startDate).format('L') === moment.unix(elem.startDate).format('L'));

      if (found) {
        found.instances.push({
          id: elem.id,
          name: elem.name,
          elementId: elem.elementId,
          startDate: moment.unix(elem.startDate).format(),
          endDate: moment.unix(elem.endDate).format(),
        });
        found.startDate = Math.min(found.startDate, elem.startDate);
        found.endDate = Math.max(found.endDate, elem.endDate);
      } else {
        acc.push({
          ...elem,
          instances: [{
            id: elem.id,
            name: elem.name,
            elementId: elem.elementId,
            startDate: moment.unix(elem.startDate).format(),
            endDate: moment.unix(elem.endDate).format(),
          }],
        });
      }
      return acc;
    }, []);

    return result;
  },
};

store.mutations.setWorkCalendar = (s, v) => {
  s.list = v.map(x => ({
    ...x,
    startDate: moment.utc(x.startDate).unix(),
    endDate: moment.utc(x.endDate).unix(),
  }));
};

export default store;
