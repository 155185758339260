var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative editor-wrapper"},[_c('div',{staticClass:"close-button cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fas fa-times"})]),_c('h4',[_vm._v(" "+_vm._s(_vm.$t('customLayout.configureWidget'))+" ")]),_c('hr',{staticClass:"mx-n4"}),_c('div',{staticClass:"w-100 text-center mx-auto mt-4 preview"},[_c('div',{staticClass:"widget-wrapper mx-auto",style:({
        minWidth: `${_vm.cellSize * (Math.max(2, _vm.item.size.w) + 0.5)}px`,
        width: `${_vm.cellSize * (Math.max(2, _vm.item.size.w) + 0.5)}px`,
      })},[_c('div',{staticClass:"droppable-element",style:({
          minWidth: `${_vm.cellSize * _vm.item.size.w}px`,
          width: `${_vm.cellSize * _vm.item.size.w}px`,
          minHeight: `${_vm.cellSize * _vm.item.size.h}px`,
          height: `${_vm.cellSize * _vm.item.size.h}px`,
        })},[(_vm.customLayoutService.getComponentForWidgetDetails(_vm.item))?_c(_vm.customLayoutService.getComponentForWidgetDetails(_vm.item),_vm._b({tag:"Component",attrs:{"current-tags":_vm.currentTags}},'Component',_vm.customLayoutService.getComponentPropsForWidgetDetails(_vm.item),false)):_vm._e()],1)]),_c('span',{staticClass:"small text-black-50"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.widgetPreview'))+" ")])]),_c('hr'),_c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"calc(100vh - 460px)"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-4 mt-4"},[_c('div',{staticClass:"d-flex flex-column w-100 pr-4"},[_c('label',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.title'))+" ")]),_c('BInput',{attrs:{"size":"sm","placeholder":`${_vm.$t('customLayout.enterWidgetTitle')}...`},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.size'))+" ")]),(_vm.item.availableSizes)?_c('select',{staticClass:"form-control-sm",domProps:{"value":_vm.getSizeOptionValue(_vm.item.size)},on:{"input":_vm.changeSize}},_vm._l((_vm.item.availableSizes),function(size){return _c('option',{key:_vm.getSizeOptionValue(size),domProps:{"value":_vm.getSizeOptionValue(size)}},[_vm._v(" "+_vm._s(_vm.getSizeOptionValue(size))+" ")])}),0):_c('p',{staticClass:"mb-0 form-control-sm cursor-not-allowed"},[_vm._v(" "+_vm._s(_vm.getSizeOptionValue(_vm.item.size))+" ")])])]),_vm._l((_vm.item.fields),function(field){return _c('div',{key:field.name,staticClass:"mb-4"},[_c('label',[_vm._v(" "+_vm._s(field.name)+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"field-type-button",class:{
            'selected': field.type === _vm.customLayoutWidgetFieldType.static
          },on:{"click":function($event){return _vm.changeFieldType(field, _vm.customLayoutWidgetFieldType.static)}}},[_c('i',{staticClass:"fas fa-a"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('customLayout.staticValue'))+" ")])]),_c('div',{staticClass:"field-type-button",class:{
            'selected': field.type === _vm.customLayoutWidgetFieldType.tag
          },on:{"click":function($event){return _vm.changeFieldType(field, _vm.customLayoutWidgetFieldType.tag)}}},[_c('i',{staticClass:"fas fa-tag"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('customLayout.tag'))+" ")])]),_c('div',{staticClass:"field-type-button",class:{
            'selected': field.type === _vm.customLayoutWidgetFieldType.noInput
          },on:{"click":function($event){return _vm.changeFieldType(field, _vm.customLayoutWidgetFieldType.noInput)}}},[_c('i',{staticClass:"fas fa-times-circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('customLayout.noInput'))+" ")])])]),_c('div',{staticClass:"mt-2"},[(field.type === _vm.customLayoutWidgetFieldType.static)?_c('BInput',{attrs:{"placeholder":`${_vm.$t('customLayout.enterStaticValue')}...`,"size":"sm"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}):_vm._e(),(field.type === _vm.customLayoutWidgetFieldType.tag)?_c('CurrentTagSelect',{attrs:{"current-tags":_vm.currentTags},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}):_vm._e()],1)])}),_c('div',{staticClass:"bottom-actions"},[_c('BButton',{staticClass:"mr-4",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('BButton',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$emit('update-item', _vm.item)}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_vm._v(" "+_vm._s(_vm.$t('general.confirm'))+" ")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }