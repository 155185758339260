import activities from './activities';
import datatables from './datatables';
import downtimeSelector from './downtimeSelector';
import downtimes from './downtimes';
import error from './error';
import notify from './notify';
import orders from './orders';
import reports from './reports';
import settings from './settings';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,
  //
  world: 'Świat',
  plant: 'Fabryka',
  area: 'Obszar',
  areas: 'Obszary',
  line: 'Linia',
  lines: 'Linie',
  delete: 'Usuń',
  segment: 'Segment',
  machine: 'Maszyna',
  machines: 'Maszyny',
  element: 'Element',
  map: 'Mapa',
  issue: 'Problem',
  typeOfIssue: 'Typ Problemu',
  currentShift: 'Bieżąca zmiana',
  noShift: 'Brak zmiany',
  currentOrder: 'Bieżące zlecenie',
  currentWeek: 'Bieżący tydzień',
  today: 'Dzisiaj',
  shift: 'Zmiana',
  shifts: 'Zmiany',
  noShifts: 'Brak przypisanych zmian',
  factoryStructure: 'Struktura fabryki',
  asset: {
    globalCounter: 'Licznik globalny',
    localCounter: 'Licznik lokalny',
    waterCounter: 'Licznik wody',
    valvePosition: 'Pozycja zaworu',
    waterLevel: 'Poziom wody',
    waterFlow: 'Przepływ wody',
    pumpBalance: 'Wysterowanie pompy',
  },
  wall: {
    comment: 'Komentarz',
    comments: 'Komentarze',
    vision: 'Wizja',
    downtimeEditorSettings: 'Ustawienia edytora przestojów',
  },
  menu: {
    monitoring: 'Monitorowanie',
    reports: 'Raporty',
    overview: 'Przegląd',
    andon: 'Andon',
    weeklyAnalysis: 'Analiza tygodniowa',
    scoreAnalysis: 'Analiza punktowa',
    settings: 'Ustawienia',
    userSettings: 'Ustawienia konta',
    subscriptions: 'Subskrypcje',
    cameras: 'Kamery',
    edgeDevices: 'Urządzenia brzegowe',
    tags: 'Tagi',
    tagHistory: 'Historia Tagów',
    user: 'Użytkownik',
    language: 'Język',
    setAsHome: 'Ustaw jako strona domowa',
    setFullScreen: 'Pełny ekran',
    logout: 'Wyloguj',
    login: 'Zaloguj',
    loadMore: 'Więcej',
    selectPlant: 'Wybierz fabrykę',
    activities: 'Aktywności',
    preview: 'Podgląd',
    homePageDevice: {
      title: 'Strona domowa dla tego urządzenia',
      description: 'Ten ekran zostanie wyświetlony zawsze po zalogowaniu na tym urządzeniu, niezależnie od użytkownika',
    },
    homePageUser: {
      title: 'Strona domowa dla twojego konta',
      description: 'Ten ekran zostanie wyświetlony po zalogowaniu, jeśli urządzenie nie będzie miało ustawionej własnej strony domowej',
    },
    lineGraph: 'Graf liniowy',
    machineGraph: 'Graf maszyn',
    lineView: 'Wizja',
  },
  import: {
    upload: 'Upuść plik tutaj żeby wysłać',
    notSaved: 'Nie zapisano',
    saved: 'Zapisano',
    noChanges: 'Brak zmian',
    dataSaved: 'Dane zostały pomyślnie zapisane',
    actionRequired: 'Zweryfikuj',
    checked: 'Zweryfikowano',
    fillAndAdd: 'Wypełnij i dodaj',
    chooseImage: 'Wybierz obrazek',
    previousImports: 'Poprzednie importy',
    nothingImported: 'Brak poprawnych danych do importu',
    fileIsBeingProcessed: 'Plik jest przetwarzany. Wyślemy ci notyfikację kiedy import się zakończy',
    groupSuccessfullyAssign: 'Przypisano do grupy',
    groupFailedToAssign: 'Nie udało się przypisać do grupy',
    groupSuccessfullyRemoved: 'Usunięto z grupy',
    groupFailedToRemove: 'Nie udało się usunąć z grupy',
    seeTemplates: 'Zobacz Szablony',
    fileReview: 'Podgląd Pliku',
    templates: 'Szablony',
    addTemplate: 'Dodaj Szablon',
    editTemplate: 'Edytuj Szablon',
    import: 'Import',
    status: 'Status',
    uploadFile: 'Wrzuć plik',
    template: 'Szablon',
    toUploadFilePickTemplate: 'Wybierz szablon aby wrzucić plik',
    result: 'Wynik',
    testFile: 'Wytestuj plik',
    toSeeResultUploadTestFile: 'Aby zobaczyć wynik wrzuć plik testowy',
    toTestFileSaveChanges: 'Aby wytestować plik zapisz swoje zmiany',
    visible: 'Widoczne',
    saveFunction: 'Zapisz Funkcję',
    key: 'Klucz',
    value: 'Wartośc',
    oldValue: 'Stara Wartość',
    newValue: 'Nowa Wartość',
    unchanged: 'Bez zmian',
    wasSuccessfully: 'pomyślnie',
    added: 'dodany',
    imported: 'zaimportowany',
    failed: 'nieudany',
    failedToFetchTemplates: 'Nie udało się pobrać szablonów.',
  },
  work: {
    event: 'Problem',
    events: 'Problemy',
    state: 'Stan',
    states: 'Stany',
    systemStates: 'Stany systemowe',
    reason: 'Przyczyna',
    reasons: 'Przyczyny',
    correctiveAction: 'Akcja korekcyjna',
    correctiveActions: 'Akcje korekcyjne',
    downtime: 'Przestój',
    downtimes: 'Przestoje',
    wiki: 'Artykuły z wiki',
    disconnected: 'Brak połączenia',
    rootCause: 'Źródło problemu',
    detachRootCause: 'Odepnij źródło problemu',
    rootMachine: 'Maszyna źródłowa',
    spectrum: 'Widmo pracy',
    downtimeList: 'Lista przestojów',
    tags: 'Tagi',
    addTags: 'Dodaj tagi',
    addSelected: 'Dodaj zaznaczone',
    default: 'Domyślny',
    byState: 'Po stanie',
    plannedDowntimes: 'Przestoje planowane',
    unplannedDowntimes: 'Przestoje nieplanowane',
    workTime: 'Czas pracy',
    actionTaken: 'Podjęta akcja korekcyjna',
    noOngoingOrder: 'Brak trwającego zlecenia',
    noOngoingShift: 'Brak trwającej zmiany',
    persisted: 'Zapisano zmiany',
    processing: 'Trwa przetwarzanie',
    saving: 'Zapisywanie...',
    updateQueued: 'Aktualizacja przestojów oczekuje na przetworzenie, może potrwać kilka sekund.',
    overrideQueued: 'Dodanie przestoju oczekuje na przetworzenie, może potrwać kilka sekund.',
    noForcedState: 'Nie zdefiniowano żadnych problemów z tagiem \'Forced\'. Dodaj definicję przestoju z tym tagiem aby zatrzymać maszynę lub linię',
    downtimeSince: 'Przestój od',
    lasting: 'trwający',
    updateType: {
      SetWorkLogProblem: 'Aktualizacja typu przestoju',
      SetWorkLogReasonMessage: 'Aktualizacja przyczyny',
      SetWorkLogCorrectiveActionMessage: 'Aktualizacja akcji korekcyjnej',
      AddWorkLogAnnotationMessage: 'Nowy komentarz',
      ReplayHistory: 'Ponowne przetwarzanie',
      DeleteWorkLogAnnotationMessage: 'Usunięcie komentarza',
      OpenWorkLogMessage: 'Rozpoczynanie wymuszonego zatrzymania',
      RecalculateElementStateMessage: 'Zakończenie wymuszonego zatrzymania',
      DeleteWorkLogMessage: 'Usunięcie przestoju',
      OverrideWorkLogMessage: 'Utworzenie przestoju',
      AdjustWorkLogDuration: 'Aktualizacja czasu trwania',
      WorkLogSplitMessage: 'Podział przestoju',
      ManualProductionMessage: 'Produkcja manualna',
    }
  },
  general: {
    name: 'Nazwa',
    from: 'Od',
    search: 'Wyszukaj',
    max: 'Max',
    errors: 'Błędy',
    min: 'Min',
    noResults: 'Brak wyników',
    title: 'Tytuł',
    description: 'Opis',
    fromStart: 'od początku',
    all: 'Wszystkie',
    clear: 'Wyczyść',
    confirm: 'Potwierdź',
    tryAgain: 'Spróbuj ponownie',
    to: 'Do',
    toEnd: 'do zakończenia',
    finish: 'Zakończ',
    add: 'Dodaj',
    hidden: 'ukryte',
    remove: 'Usuń',
    detach: 'Odepnij',
    attach: 'Przypnij',
    close: 'Zamknij',
    cancel: 'Anuluj',
    edit: 'Edytuj',
    refresh: 'Odśwież',
    lastRefresh: 'Ostatnia aktualizacja: {0} temu',
    reset: 'Zresetuj',
    event: 'Zdarzenie',
    details: 'Szczegóły',
    collapseDetails: 'Zwiń szczegóły',
    summary: 'Podsumowanie',
    save: 'Zapisz',
    change: 'Zmień',
    noData: 'Brak danych',
    nothingDefined: 'Brak definicji',
    others: 'Inne',
    back: 'Powrót',
    next: 'Dalej',
    results: 'wyników',
    selected: 'Wybrane',
    allSelected: 'Wszystkie wybrane',
    unchecked: 'odznaczone',
    selectAll: 'Wybierz wszystkie',
    createNew: 'Utwórz nowy',
    useExisting: 'Użyj istniejącego',
    select: 'Wybierz',
    saveSuccessed: 'Zapis udany',
    saveFailed: 'Błąd zapisu',
    shouldArchive: 'Archiwizacja',
    areYouSure: 'Jesteś pewien?',
    manHours: 'Roboczogodziny',
    view: 'Widok',
    yes: 'Tak',
    no: 'Nie',
    reason: 'Przyczyna',
    actions: 'Akcje',
    reasonUnknown: 'Przyczyna nieznana',
    forcedByOperator: 'Wymuszone przez Operatora',
    type: 'Typ',
    valueType: 'Typ wartości',
    value: 'Wartość',
    unit: 'Jednostka',
    disable: 'Wyłącz',
    disabled: 'Wyłączony',
    disabledShe: 'Wyłączona',
    inactive: 'Nie aktywna',
    enable: 'Włącz',
    enabled: 'Włączony',
    enabledShe: 'Włączona',
    ignore: 'Ignoruj',
    cancelled: 'Anulowane',
    nominal: 'Nominalne',
    unsavedChanges: 'Czy na pewno chcesz wyjść? Masz nie zapisane zmiany!',
    now: 'Teraz',
    showMore: 'Pokaż więcej',
    showAll: 'Pokaż wszystkie',
    continue: 'Dalej',
    group: 'Grupuj',
    download: 'Pobierz',
    progress: 'Postęp',
    dates: 'Daty',
    pickDate: 'Wybierz datę',
    inherited: 'Dziedziczny',
    copy: 'Kopiuj',
    dir: {
      top: 'Góra',
      bottom: 'Dół',
      left: 'Lewo',
      right: 'Prawo',
    },
    sourceURI: 'Source URI',
    dimensions: 'Pomiary',
    status: 'Status',
    code: 'Kod',
    start: 'Początek',
    end: 'Koniec',
    update: 'Zaktualizuj',
    deviceId: 'ID Urządzenia',
    time: 'Czas',
    show: 'Pokaż',
    hide: 'Ukryj',
    last: 'Ostatnie',
    recent: 'Ubiegłe',
    requestReceived: 'Żądanie otrzymane',
    deleted: 'Skasowano',
    filters: 'Filtry',
    total: 'Suma',
    restoreDefaults: 'Przywróć domyślne',
    setDate: 'Ustaw datę',
    setTime: 'Ustaw czas',
    line: 'Linia',
    default: 'Domyślna',
    set: 'Ustaw',
    percent: 'Procent',
    audit: 'Audyt',
    pickAMachine: 'Wybierz maszynę',
    others: 'Inne',
  },
  machineControl: {
    turnOn: 'Włącz',
    turnOff: 'Wyłącz',
    current: 'Bieżące',
    liveStream: 'Podgląd wideo',
    disconnectedWarning: 'UWAGA! Obecnie nie ma połączenia z maszyną - '
      + 'wyłączenie maszyny spowoduje utratę zbuforowanych danych',
    startMachine: 'Wystartować maszynę <b>{line}</b>?',
  },
  lineControl: {
    startLine: 'Wystartować linię <b>{line}</b>?',
    stop: 'Zatrzymaj',
    start: 'Wystartuj',
  },
  time: {
    none: 'Brak',
    week: 'Tydzień',
    date: 'Data',
    days: 'dni',
    day: 'Dzień',
    minutes: 'Minut',
    hour: 'Godzina',
    hours: 'Godziny',
    hoursPlural: 'Godzin',
    resolution: 'Precyzja',
    month: 'Miesiąc',
    year: 'Rok',
    quarter: 'Kwartał',
    duration: 'Czas trwania',
    seconds: 'sekund',
    startDate: 'Data startu',
    endDate: 'Moment końca',
    selectDay: 'Wybierz dzień',
    longerThan: 'Dłuższe niż',
    shorterThan: 'Krótsze niż',
    scope: 'Zakres czasu',
    currentOrder: 'Obecne zlecenie',
    showMinorStops: 'Pokaż mikroprzestoje',
    plannedOnly: 'Pokaż tylko zaplanowane',
    unplannedOnly: 'Pokaż tylko nieplanowane',
    performanceOnly: 'Straty wydajności',
    availabilityOnly: 'Straty dostępności',
    utilizationOnly: 'Straty wykorzystania',
    excludeDefaults: 'Ukryj domyślne',
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',
    plannedDate: 'Zaplanowany start',
    custom: 'Niestandardowy',
    factoryTimezone: 'Strefa czasowa fabryki',
    timestamp: 'Znacznik czasu'
  },
  graph: {
    flow: 'Przepływ',
    flows: 'Przepływy',
    production: 'Przepływ produkcji',
    physical: 'Fizyczny',
    supplying: 'Zaopatrzenie',
    archiveChanges: 'Historyzuj zmiany',
    isActive: 'Aktywny',
    addElement: 'Dodaj element',
    detached: 'Odłączone',
    andon: 'Andon',
    noActiveFlow: 'Brak aktywnego przepływu',
    setAsABaseAvailability: 'Ustaw jako bazowa dostępność',
    setAsABaseQuantity: 'Ustaw jako bazowa produkcja',
    youNeedToSpecifyBaseMachines: 'Musisz sprecyzować maszyny bazowej produkcji i dostępności.',
  },
  production: {
    rawCounterIncreases: 'Surowe wartości liczników w tym okresie',
  },
  monitoring: {
    rerun: 'Przetwórz ponownie',
    ofThePlan: 'osiągnąłeś %{value}% tej wartości',
    noPlan: 'Brak planu',
    performanceSettingHistory: 'Historia ustawień wydajności',
    unitConversionsHistory: 'Historia struktur pakowania',
    averageTargetPerformance: 'Średnia zakładana wydajność',
    reportParam: 'Parametryzacja raportu',
    performanceType: 'Typ wydajności',
    designSpeed: 'Nominalna',
    validatedSpeed: 'Zmierzona',
    targetProduction: 'Cel produkcyjny',
    maxPerfProduction: 'Maksymalna wydajność',
    plannedFromOrders: 'Zaplanowane zlecenia',
    planned: 'Planowano',
    notInFlow: 'Nie biorące udziału w produkcji',
    produced: 'Wyprodukowano',
    wasted: 'Straty',
    lastMessage: 'Ostatnia wiadomość',
    noMessages: 'Brak wiadomości',
    noAlarms: 'Brak alarmów',
    noConnectionInfo: 'Brak informacji o podłączeniu',
    noConnection: 'Brak połączenia z serwerem',
    processing: 'Przetwarzanie',
    dataProcessingFromLast: 'Przetwarzanie wiadomości z ostanich ',
    lineStates: 'Stan linii',
    disconnected: 'odłączona | odłączone',
    serverDisconnected: 'Brak połączenia z serwerem',
    working: 'pracuje | pracują',
    ready: 'w gotowości',
    notWorking: 'w przestoju',
    productionProgress: 'Postęp produkcji',
    production: 'Produkcja',
    productionMap: 'Mapa produkcji',
    workStates: 'Stany\xa0pracy',
    workLog: 'Historia\xa0pracy',
    event: 'Zdarzenie',
    events: 'Zdarzenia',
    cause: 'Przyczyna',
    causes: 'Przyczyny',
    reasons: 'Przyczyny',
    warnings: 'Ostrzeżenia',
    correctiveAction: 'Akcja korekcyjna',
    realTime: 'Czas rzeczywisty',
    toRealTime: 'Do czasu rzeczywistego',
    stopRealTime: 'Zatrzymaj',
    stoppedRealTime: 'Zatrzymane',
    downtimes: 'Przestoje',
    changeCurrentDowntime: 'Zmień przestój',
    doubleClickToEdit: 'Kliknij dwukrotnie aby edytować przestój',
    percentShare: 'Procentowy udział',
    timeShare: 'Czasowy udział',
    minorStops: 'w tym mikroprzestoje',
    remainingPlannedProduction: 'Możliwa pozostała produkcja według wydajności nominalnej',
    remainingEstimatedProduction: 'Możliwa pozostała produkcja według wydajności efektywnej',
    remainingTime: 'Pozostały czas',
    performance: {
      short: 'Wydajność ostatnich {0}',
      effective: 'Wydajność efektywna',
      nominal: 'Wydajność nominalna',
    },
    segment: {
      baseQuantity: 'Bazowa produkcja',
      baseQuality: 'Bazowa jakość',
      baseAvailability: 'Bazowa dostępność',
      basePerformance: 'Bazowa wydajność',
    },
    machine: {
      counter: 'Licznik',
      parameters: 'Parametry',
      alarms: 'Alarmy',
      processParameters: 'Parametry procesowe',
      currentParameters: 'Bieżące parametry',
      activeAlarms: 'Aktywne alarmy',
      counters: 'Liczniki',
      alarmsHoldups: 'Alarmy i Zatory',
      performanceInfo: 'Średnia wydajność za ostatnie {0}',
      others: 'Inne',
      lifespan: 'Żywotność',
      environmentCondition: 'Stan środowiska',
      condition: 'Stan zasobu',
      cost: 'Nakład',
      failurePreditcion: 'Przewidywanie awarii',
      probabilityFailure: 'Prawdopodobieństwo awarii w ciągu 10 dni',
      failureType: 'Typ awarii',
      timeToFailure: 'Przewidywany czas do awarii',
      predictedFailure: 'Przewidywana awaria',
      maintenanceHistory: 'Historia serwisów',
    },
    lineInfo: {
      advices: 'Porady',
      forService: 'Zadania',
      jobs: 'Prace',
      completed: 'Zrealizowane',
      problems: 'Problemy',
      planned: 'Zaplanowane',
      toAchieveTarget: 'Oczekiwana wielkość produkcji w tym momencie:',
      confirmLinePause: 'Potwierdź wstrzymanie linii',
    },
    worstAlarms: 'Największe problemy',
    problems: 'brak problemów | 1 problem | {0} problemy',
    overdue: 'zaległy {0}',
    left: 'pozostało {0}',
  },
  components: {
    title: 'Komponenty',
    one: 'Komponent',
    usagePerUnit: 'Użycie na jednostkę',
    convertUnit: 'Przelicz jednostkę',
    required: 'Wymagany',
    validation: 'Wymagana walidacja',
    batchTracking: 'Śledzenie',
    scanTrackingId: 'Zeskanuj numer serii',
    batchTrackingHelp: 'Jeśli śledzenie jest włączone, dla każdej zeskanowanej paczki musi zostać podany identyfikator',
    defaultPackageQuantity: 'W opakowaniu',
    warningLevelPercentage: 'Ostrzeżenie przy',
    warningLevel: 'Poziom ostrzegawczy',
    warningLevelHelp: '% - ostrzeżenie gdy procent paczki zostanie zużyty\n min - ostrzeżenie gdy pozostało tyle czasu do skończenia paczki',
    scannerCode: 'Kod skanera',
    scannerCodeType: 'Typ kodu',
    noTrackingId: 'Brak numeru serii',
    scan: 'Zeskanuj',
    packages: 'Opakowania',
    scanPackages: 'Zeskanuj opakowania',
    noComponents: 'Brak przypisanych komponentów',
    invalidCode: 'Nie znaleziono komponentu',
    addComponent: 'Dodaj komponent',
    closed: 'Zamknięta',
    scanned: 'Zeskanowana',
    closeConfirm: 'Zamknij',
    eta: 'Szacowany czas zużycia',
    active: 'Aktywna',
    clickToActivate: 'Kliknij żeby aktywować',
    used: 'Wykorzystano',
    estimatedTotalQuantity: 'Szacowana potrzebna ilość',
    packageScannerCode: 'Kod opakowania',
    defaultPackageQuantitySum: 'Razem w opakowaniach',
    registeredDate: 'Data zeskanowania',
    trackingId: 'Numeru serii',
    consumptionType: {
      title: 'Typ zużycia',
      help: '\'MULTIPLE\' pozwala na wiele aktywnych paczek jednocześnie, pierwsza aktywna jest konsumowana.\n\'SINGLE\' zamyka automatycznie poprzednią paczkę kiedy nowa jest aktywowana, ostatnia jest konsumowana.',
    },
  },
  oee: {
    availability: 'Dostępność',
    performance: 'Wydajność',
    quality: 'Jakość',
    oee: 'OEE',
    teep: 'TEEP',
    oeeLoss: 'OEE Loss',
    utilization: 'Wykorzystanie',
    target: 'Cel',
  },
  metrics: {
    mtbf: 'Średni czas między awariami (MTBF)',
    mttr: 'Średni czas między naprawami (MTTR)',
    mtbm: 'Średni czas między serwisami (MTBM)',
    reliabilityMetrics: 'Wskaźniki niezawodności',
  },
  energy: {
    usedEnergy: 'Zużyta energia',
    currentLeastEfficient: 'Obecnie najmniej wydajna maszyna',
    currentPower: 'Obecne zużycie',
    baseline: 'Średni pobór',
    max: 'Maksymalny średni pobór',
  },
  items: {
    items: 'Produkty',
    item: 'Produkt',
    import: 'Importuj produkty',
    export: 'Eksportuj',
    itemGroups: 'Grupy produktów',
    itemGroup: 'Grupa produktów',
    noLineConfigured: 'Brak produktów skonfigurowanych na tą linię',
    inheritedFromGroup: 'Odziedziczone z grupy',
    groups: 'Grupy',
    lineConfiguration: 'Konfiguracja linii',
    add: 'Dodaj produkt',
    addGroup: 'Dodaj grupę',
    new: 'Nowy produkt',
    edit: 'Edycja produktu',
    number: 'Numer',
    description: 'Opis',
    quantity: 'Ilość/paleta',
    unit: 'Jednostka',
    performance: 'Wydajność',
    expectedPerformance: 'Oczekiwana wydajność',
    expectedChangeover: 'Oczekiwany czas przezbrojenia',
    packingStructure: 'Struktura pakowania',
    validation: 'Walidacja',
    noValidationErrors: 'Brak błędów walidacji',
    groupPackingStructure: 'Struktura pakowania grup',
    itemRoutingSpeed: 'Wydajności produktu',
    groupRoutingSpeed: 'Wydajności grupy produktów',
    components: 'Komponenty',
    bulkDeleteWarning: 'Czy na pewno chcesz usunąć zaznaczone produkty?',
    bulkDeleteConfirm: 'Usuń zaznaczone produkty',
    additionalFields: 'Dodatkowe pola',
    noAdditionalFields: 'Brak dodatkowych pól',

    severity: {
      warning: 'Ostrzeżenie',
      error: 'Błąd',
      critical: 'Krytyczny',
    },
  },
  pageNotFoundExplanation: 'Nie możemy znaleźć tej strony, przepraszamy za utrudnienia',
  processParams: {
    compositionName: 'Nazwa kompozycji',
    compositions: 'Kompozycje',
    saveComposition: 'Zapisz kompozycję',
    signals: 'Sygnały',
    signalName: 'Nazwa sygnału',
    signalValue: 'Wartość sygnału',
    ongoingState: 'Trwający stan',
    ongoingProblem: 'Trwający problem',
    ongoingOrder: 'Trwające zlecenie',
    problemStart: 'Początek problemu',
    problemEnd: 'Koniec problemu',
    orderStart: 'Początek zlecenia',
    orderEnd: 'Koniec zlecenia',
    orderProduction: 'Wyprodukowano na zleceniu',
    showAsTable: 'Pokaż jako tabelę',
  },
  healthcheck: {
    noDriverConnection: 'Brak połączenia ze sterownikiem',
  },
  vision: {
    vision: 'Wizja',
    showAllClips: 'Pokaż wszystkie klipy',
    selectCamera: 'Wybierz kamerę',
    getClip: 'Pobierz Klip ',
    getTheTwoPreviousMinutes: 'Pobierz 2 minuty przed i 2 po czasie startu przestoju',
    noClips: 'Nie znaleziono klipów',
    noRecords: 'Kamera nie posiada nagrań do pobrania z tego dnia',
    currentVideoTime: 'Bieżący czas nagrania',
    startVideoTime: 'Czas startu nagrania',
    startDowntime: 'Czas startu przestoju',
    endDowntime: 'Czas końca przestoju',
    showLiveVideo: 'Pokaż podgląd na żywo',
    selectStartClip: 'Wybierz początek wideo',
    selectEndClip: 'Wybierz koniec wideo',
    videoDurationIs: 'Długość wideo:',
    invalidTimeRange: 'Nieprawidłowy zakres czasu.',
    toMoreBefore: 'Wybierz wcześniejszą datę początku wideo.',
    toMoreAfter: 'Wybierz późniejszą datę końca wideo.',
    maxVideoIs: 'Maksymalna długość wideo',
    auto: 'A',
    autoLong: 'Zażądano wideo automatycznie przez system',
    manual: 'M',
    manualLong: 'Zażądano wideo manualnie przez użytkownika',
    videoTrigger: 'Po wybraniu, znacznik VideoTrigger jest skonfigurowany dla tego rodzaju problemu',
    autoDownload: 'Poproś o wideo dla wszystkich przyszłych wydarzeń tego typu',
    watchVideo: 'Oglądaj klip',
    clipIsNotReadyYet: 'Klip wideo nie jest jeszcze gotowy',
    hasCamera: 'Posiada kamerę',
    videoTriggerWorkTagError: 'Tag video trigger nie istnieje',
    cannotDownloadThisClip: 'Nie można pobrać tego klipu.',
    youCanOnlyRequestClipsFromTheLast: 'Możesz pobierać klipy z ostatnich',
    fromNow: 'przed aktualnym czasem',
    thisScreenIsToSmall: 'Ten ekran jest zbyt mały.',
    thisScreenIsToSmallInfo: 'Niestety, odtwarzacz wideo nie jest obsługiwany w Twojej rozdzielczości.',
    goBack: 'Wróć',
    firstSelectCamerasIn: 'Najpierw wybierz kamery w sekcji',
    cameras: 'Kamery',
    section: '',
    selected: 'Wybrane',
    inProgress: 'W trakcie',
    downloaded: 'Pobrane',
    failed: 'Niepowodzenie',
    downloadList: 'Lista pobierania',
    cameraIsNotShown: 'Kamera nie jest pokazana',
    downloadThisClip: 'Pobrać ten klip?',
    lineView: 'Wizja',
    clearAllSettings: 'Wyczyść wszystkie ustawienia',
  },
  downloadStatus: {
    failed: 'Przerwano',
    inProgress: 'W Trakcie',
    success: 'Gotowy',
    failedOnEdge: 'Błąd kamery',
    noArchiveRecordings: 'Kamera nie posiadała nagrań.',
    deleted: 'Skasowano',
  },
  quests: 'Zadania',
  quest: {
    quest: 'Zadanie',
    questTitle: 'Tytuł zadania',
    questDescription: 'Opis zadania',
    project: 'Projekt',
    type: 'Typ zadania',
    state: 'Stan zadania',
    closeToState: 'Zamknij do stanu',
    assignToGroups: 'Przypisz do grup',
    taskDetails: 'Szczegóły zadania',
    files: 'Pliki',
    noFiles: 'brak plików',
  },
  health: {
    health: 'Zdrowie',
    healthHistory: 'Historia zdrowia',
  },

  // TODO: Remove
  panel: {
    number: 'Numer',
    sku: 'Sku',
    selectState: 'Wybierz stan',
    selectMachine: 'Wybierz maszynę',
    comment: 'Komentarz',
    downtimeFrom: 'Przestój od',
    ongoing: 'trwający',
    averagePerformance: 'Średnia wydajność',
    currentPerformance: 'Wydajność Bieżąca',
    currentLineStatus: 'Aktualny stan linii',
    provideReasonAndComment: 'Podaj przyczynę i komentarz',
    confirmData: 'Potwierdź dane',
    commentPlaceholder: 'Dodaj komentarz',
    inputPlaceholder: 'Wpisz tutaj',
  },
  workCalendar: 'Kalendarz pracy',
  schedule: 'Harmonogram',
  importSchedule: 'Importuj harmonogram',
  addSchedule: 'Dodaj harmonogram',
  repeatEvery: 'Powtarzaj co',
  repeatOn: 'Powtarzaj w',
  ends: 'Kończy się',
  starts: 'Zaczyna się',
  never: 'Nigdy',
  on: 'Dnia',
  calendar: 'Kalendarz',
  cycle: 'Cykl',
  deleteRecurringInstance: 'Usuwanie wystąpienia cyklicznego',
  editRecurringInstance: 'Edytowanie wystąpienia cyklicznego',
  thisInstance: 'To wystąpienie',
  thisAndFollowingInstances: 'To wystąpienie i kolejne wystąpienia',
  allInstances: 'Wszystkie wystąpienia',
  order: {
    addNewProperty: 'Dodaj nową właściwość',
    sortByDate: 'Sortuj według daty',
    sortBy: 'Sortuj według',
    ascending: 'rosnąco',
    descending: 'malejąco',
    quantityProduced: 'Ilości wyprodukowanej',
    plannedProduction: 'Zaplanowanej produkcji',
    endDate: 'Daty zakończenia',
    activatedDate: 'Daty aktywacji',
    totalTime: 'Czasu trwania',
    plannedDate: 'Daty startu',
    eta: 'ETA',
    delete: 'Wyczyść filtr',
  },
  noCycle: 'Brak definicji cyklu',
  users: {
    firstName: 'Imię',
    lastName: 'Nazwisko',
  },
  teams: {
    teamName: 'Nazwa zespołu',
  },
  schedules: {
    addLine: 'Dodaj linię do filtrów',
    clearLines: 'Usuń wszystkie linie',
  },
  chart: {
    weekSymbol: 'T',
  },
  calendar: {
    period: 'Okres',
  },
  taxonomies: {
    taxonomy: 'Taksonomie',
    classification: 'Klasyfikacja',
    selectClassification: 'Wybierz klasyfikację',
    selectClassificationLevel: 'Wybierz poziom klasyfikacji',
    selectClassificationNodes: 'Wybierz węzły klasyfikacji',
    defaultTaxonomy: 'Domyślna',
    pickCategory: 'Wybierz kategorię',
    selectTaxonomy: 'Wybierz taksonomie',
    selectTaxonomyLevel: 'Wybierz poziom taksonomii',
  },
  andon: {
    top3Downtimes: '3 najczęstsze przestoje',
    todayOeeTrend: 'Dzisiejszy trend OEE',
    stateDistribution: 'Dystrybucja stanów',
    thisWeekOeeTrend: 'Trend OEE w tym tygodniu',
    currentState: 'Stan aktulany',
    noActiveOrder: 'Brak aktywnej aktywności',
  },
  customLayout: {
    customLayout: 'Własny układ',
    layout: 'Układ',
    text: 'Tekst',
    gaugeChart: 'Wykres wskaźnikowy',
    percentageChart: 'Wykres procentowy',
    progressBar: 'Pasek postępu',
    configureWidget: 'Konfiguruj widget',
    widgetPreview: 'Podgląd widgetu',
    title: 'Tytuł',
    size: 'Rozmiar',
    staticValue: 'Wartość statyczna',
    tag: 'Tag',
    noInput: 'Brak wartości',
    selectWidgetSize: 'Wybierz rozmiar widgetu',
    dragAndDropSelectedWidgetIntoTheGrid: 'Przeciągnij i upuść wybrany widget na siatkę.',
    selectWidgetType: 'Wybierz typ widgetu',
    noWidgetsAvailable: 'Brak dostępnych widgetów',
    enterStaticValue: 'Wprowadź wartość statyczną',
    enterWidgetTitle: 'Wprowadź tytuł widgetu',
  },
};
