export default {
  rootcause: {
    impact: {desc: '(Durée de l\'effet / Durée de la cause première)', title: 'Impacter'},
    effectDuration: 'Durée de l\'effet',
    rootcauseDuration: 'Durée de la cause première'
  },
  operatorStop: {
    fixing: 'Durée de l\'action',
    stopsBecauseOf: 'S\'arrête à cause de',
    timeSum: 'Durée totale',
    waiting: 'En attente d\'une action',
  },
  selectors: {
    addElement: 'Ajouter un élément d\'usine au rapport',
    dateFrom: 'Date de',
    dateTo: 'Date à',
    day: 'Jour',
    lastThreeMonths: '3 derniers mois',
    moment: 'Moment',
    month: 'Mois',
    period: 'Période',
    previousMonth: 'Le mois précédent',
    previousWeek: 'Semaine précédente',
    selectDatesFromList: 'Sélectionnez les dates dans la liste',
    selectExactDates: 'Sélectionnez les dates exactes',
    selectLine: 'Sélectionnez la ligne',
    selectLineAndMachine: 'Sélectionnez la ligne et la machine',
    selectLineOrMachine: 'Sélectionnez la ligne ou la machine',
    shift: 'Équipe',
    thisMonth: 'Ce mois-ci',
    thisWeek: 'Cette semaine',
    timeFilter: 'Filtre horaire',
    today: 'Aujourd\'hui',
    week: 'La semaine',
    yesterday: 'Hier',
    amount: 'Montant',
    year: 'Année',
    thisYear: 'Cette année',
    previousYear: 'Année précédente',
  },
  aggregated: 'Agrégrées',
  types: {
    alarms: 'Alarmes',
    availability: 'Disponibilité',
    column: 'Colonne',
    commentsFromLosses: 'Commentaires venant des pertes',
    custom: 'Usage',
    downtimeDuration: 'Temps d\'arrêt',
    downtimeDurations: 'Temps d\'arrêts',
    rootcauses: 'Causes des temps d\'arrêt',
    downtimes: 'Temps d\'arrêt',
    efficiency: 'Efficacité',
    energy: 'Énergie',
    heatmap: 'Carte de chaleur',
    oee: 'TRS',
    oeeDetails: 'Détails du TRS',
    oeeLosses: 'Pertes TRS',
    operatorStops: 'L\'opérateur s\'arrête',
    pie: 'Diagramme',
    production: 'Production',
    productionMap: 'Carte de fabrication',
    reasons: 'Les raisons',
    energySankey: 'Sankey',
    typeOfLosses: 'Type de pertes',
    energyHistory: 'Historique d\'utilisation',
    'comments': 'Commentaires des pertes',
    'reportsDownload': 'Téléchargements de rapports',
    'downtimeTracking': 'Suivi des temps d\'arrêt',
    'energyTrends': 'Tendances',
  },
  select: {
    allLines: 'Toutes les lignes',
    allMachines: 'Toutes les machines',
    clear: 'Effacer',
    selectLineAreaOrPlant: 'Sélectionnez l\'usine, la ligne ou la zone',
    selectLine: 'Sélectionner une ligne',
    selectMachine: 'Sélectionner la machine',
  },
  oeeLoss: {
    availabilityLosses: 'Perte de disponibilité',
    base: 'Heure de base',
    combined: 'Combiné',
    time: {
      effectiveTime: 'Délai effectif',
      operatingTime: 'Temps de fonctionnement',
      productionTime: 'Temps de production',
      scheduledTime: 'Heure prévue',
      totalTime: 'Temps total',
    },
    oeeLoss: 'Perte de TRS',
    performanceLosses: 'Pertes de performance',
    productionLoss: 'Perte de production',
    qualityLoss: 'Perte de qualité',
    qualityLosses: 'Pertes de qualité',
    speedIncrease: 'Gain d’augmentation de vitesse',
    speedLoss: 'Perte de vitesse',
    byMachine: 'Fractionnement par ligne/machine',
    byProblem: 'Divisé par problème',
    byState: 'Divisé par État',
    undefinedIssue: 'Type de problème non défini',
    utilizationLosses: 'Pertes d’utilisation',
    timeLoss: 'Perte de temps',
    performanceStops: 'Arrêts de perte de performance',
    qualityStops: 'Arrêts de perte de qualité',
    splitBy: 'Divisé par',
    byClassification: 'Classification',
    classificationInfoTooltip: 'Pour utiliser un type de classification différent, choisissez d\'abord "Divisé par Classification" dans l\'une des catégories de pertes.',
    notInClassification: 'Hors classification',
    calculatedBasedOnTotalAvailabilityLoss: 'Calculé en fonction de la perte totale de disponibilité',
  },
  availabilityImpact: 'Perte de disponibilité',
  completedOrders: 'Commandes terminées',
  alarms: {
    description: 'Description',
    duration: 'Durée',
    noDataInThisPeriod: 'Aucune donnée sur cette période',
    numberOfOccurances: 'Numéro',
    sumOfSmallerAlarms: 'Somme des petites alarmes',
  },
  detailed: 'Détaillé',
  dropHereToCreateNew: 'Faites glisser et déposez ici pour créer un nouveau graphique',
  expected: 'Attendu',
  addFactory: 'Tableau de bord de l\'usine',
  hideOthers: 'Masquer les autres',
  history: 'Historique',
  minorStops: 'Arrêts mineurs',
  new: 'Nouveau',
  performanceImpact: 'Perte de performance',
  addPersonal: 'Tableau de bord personnel',
  plannedDowntime: 'Planifié',
  quantity: 'Quantité',
  report: 'Rapport',
  rootElement: 'éléments de base',
  chooseEvent: 'Sélectionner le problème',
  showNLongest: 'Afficher <b>{0}</b> plus longtemps',
  showNAlarms: 'Afficher <b>{0}</b> alarmes les plus longues',
  showOthers: 'Montrer aux autres',
  splitByShifts: 'Fractionnement par équipe',
  sum: 'Somme',
  sumOfSmaller: 'Somme des autres',
  time: 'Heure',
  chart: {toggleFullView: 'Basculer en mode plein écran'},
  unitConversionComplete: 'Conversion de l’unité terminée',
  unitConversionNotComplete: 'La conversion de l’unité n’est pas terminée',
  unplannedDowntime: 'Imprévus',
  usedEnergy: 'Énergie utilisée',
  utilizationImpact: 'Pertes d’utilisation',
  unableToFullyConvertUnitMessage: 'Nous ne sommes pas en mesure de convertir toute la production en \'{unité}\', vérifier la structure d’emballage des références qui ont été produites au cours de cette période',
  work: 'Travail',
  needToSelectEvent: 'Vous devez sélectionner le problème',
  splitBy: 'Divisé par',
  lineMachine: 'Ligne / Machine',
  viewMinorStops: 'Afficher les arrêts mineurs',
  minorStopsIncluded: 'Arrêts mineurs (inclus dans la durée totale)',
  detailedEventsReport: 'Rapport des événements détaillés',
  siteOeeReport: 'Rapport OEE du site',
  speedLossReport: 'Rapport de perte de vitesse',
  labourVarianceReport: 'Rapport de variance de main-d\'œuvre',
  siteWeeklyScorecard: 'Tableau de bord hebdomadaire du site',
  siteMonthlyScorecard: 'Tableau de bord mensuel du site',
  exportReport: 'Exporter le rapport',
  excludeUtilizationLoss: 'Exclure la perte d\'utilisation',
  decrease: 'diminuer',
  increase: 'augmenter',
  seeDowntimesReport: 'Voir le rapport des arrêts',
  downloadReport: 'Télécharger le rapport',
  numberOfItemsProduced: 'Nombre d\'articles produits',
  downtimesPerProducedUnit: 'Arrêts par unité produite',
  trackerOptions: 'Options de suivi des arrêts',
  expandCollapseAll: 'Développer/Replier tout',
  downtimeComments: {
    comment: 'Commentaire',
    downtimeElement: 'Ligne/Machine',
    downtime: 'Arrêt',
    time: 'Heure',
    duration: 'Durée',
    commentAuthor: 'Auteur du commentaire',
    showDowntime: 'Afficher l\'arrêt',
    details: 'Détails',
  },
  downtimeTracker: {
    track: 'Suivre',
    resolutionForThisSelection: 'Résolution étroite pour cette sélection',
    workspectrumForThisSelection: 'Afficher le spectre de travail pour cette sélection',
  },
};
