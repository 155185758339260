import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,

  'world': 'Welt',
  'plant': 'Werk',
  'area': 'Bereich',
  'areas': 'Bereiche',
  'line': 'Linie',
  'lines': 'Linien',
  'delete': 'löschen',
  'segment': 'Abschnitt',
  'machine': 'Maschine',
  'machines': 'Maschinen',
  'element': 'Element',
  'map': 'Karte',
  'issue': 'Problem',
  'typeOfIssue': 'Art des Problems',
  'currentShift': 'aktuelle Schicht',
  'noShift': 'keine Schicht',
  'currentOrder': 'aktueller Auftrag',
  'today': 'heute',
  'shift': 'Schicht',
  'shifts': 'Schichten',
  'noShifts': 'keine Schichten zugeordnet',
  'asset': {
    'globalCounter': 'globaler Zähler',
    'localCounter': 'lokaler Zähler',
    'waterCounter': 'Wasser Zähler',
    'valvePosition': 'Ventil Position',
    'waterLevel': 'Wasserlevel',
    'waterFlow': 'Wasserfluss',
    'pumpBalance': 'Bilanz Ausgleich'
  },
  'wall': {
    'comment': 'Kommentar',
    'comments': 'Kommentare',
    'vision': 'Vision',
    'downtimeEditorSettings': 'Einstellungen des Ausfallzeiteditors'
  },
  'menu': {
    'monitoring': 'Produktion',
    'reports': 'Berichte',
    'overview': 'Übersicht',
    'andon': 'Andon',
    'weeklyAnalysis': 'wöchentliche Analyse',
    'scoreAnalysis': 'Ergebnisanalyse',
    'settings': 'Einstellungen',
    'userSettings': 'Kontoeinstellungen',
    'notifications': 'Benachrichtigungen',
    'language': 'Sprache',
    'user': 'Benutzer',
    'setAsHome': 'als Startseite festlegen',
    'setFullScreen': 'Vollbildschirm einstellen',
    'logout': 'Logout',
    'loadMore': 'mehr laden',
    'selectPlant': 'Standort auswählen',
    'activities': 'Aktivitäten',
    'login': 'Anmeldung',
    'preview': 'Vorschau',
    'homePageDevice': {
      'title': 'Startseite für dieses Gerät',
      'description': 'Dieser Bildschirm wird immer nach der Anmeldung auf diesem Gerät angezeigt'
    },
    'homePageUser': {
      'title': 'Startseite für dieses Konto',
      'description': 'Dieser Bildschirm wird nach Ihrer Anmeldung angezeigt, wenn das Gerät keine Startseite hat'
    },
    'lineGraph': 'Liniendiagramm',
    'machineGraph': 'Maschinendiagramm',
    'version': 'Version'
  },
  'import': {
    'upload': 'ziehe Daten hier hin zum hochladen',
    'notSaved': 'nicht gespeichert',
    'saved': 'gespeichert',
    'actionRequired': 'Handlung erforderlich',
    'checked': 'überprüft',
    'fillAndAdd': 'ausfüllen und hinzufügen',
    'chooseImage': 'Voreinstellung auswählen',
    'nothingImported': 'Keine gültigen Daten zum einfügen',
    'previousImports': 'vorherige Importe',
    'fileIsBeingProcessed': 'Datei wird verarbeitet. Wir senden Ihnen eine Benachrichtigung, wenn es fertig ist',
    'groupSuccessfullyAssign': 'Erfolgreich einer Gruppe zugeordnet',
    'groupFailedToAssign': 'Zuweisen zu einer Gruppe fehlgeschlagen',
    'groupSuccessfullyRemoved': 'Erfolgreich aus einer Gruppe entfernt',
    'groupFailedToRemove': 'Entfernen aus der Gruppe fehlgeschlagen',
    'noChanges': 'Keine Änderungen',
    'dataSaved': 'Daten erfolgreich gespeichert',
    'multiplierIsInvalid': 'Multiplikator steht im Konflikt mit bereits definierter Konvertierung',
    'multiplierIsLessOrEqualZero': 'Der Multiplikator kann nicht kleiner oder gleich Null sein',
    'seeTemplates': 'Siehe Vorlagen',
    'fileReview': 'Dateiüberprüfung',
    'templates': 'Vorlagen',
    'addTemplate': 'Vorlage hinzufügen',
    'editTemplate': 'Vorlage bearbeiten',
    'import': 'Importieren',
    'uploadFile': 'Datei hochladen',
    'template': 'Vorlage',
    'toUploadFilePickTemplate': 'Um eine Datei hochzuladen, wählen Sie die Vorlage aus',
    'result': 'Ergebnis',
    'testFile': 'Testdatei',
    'toSeeResultUploadTestFile': 'Um das Ergebnis zu sehen, laden Sie eine Testdatei hoch',
    'toTestFileSaveChanges': 'Um die Datei zu testen, speichern Sie Ihre Änderungen',
    'visible': 'Sichtbar',
    'saveFunction': 'Funktion speichern',
    'key': 'Schlüssel',
    'value': 'Wert',
    'oldValue': 'alter Wert',
    'newValue': 'neuer Wert',
    'unchanged': 'Ungeändert',
    'status': {
      'applied': 'Erfolg',
      'appliedWithErrors': 'Mit Fehlern angewendet',
      'scheduled': 'geplant',
      'faulted': 'Fehlerhaft',
      'processing': 'wird bearbeitet'
    }
  },
  'work': {
    'event': 'Problem',
    'events': 'Probleme',
    'state': 'Zustand',
    'states': 'Zustände',
    'systemStates': 'Systemzustände',
    'reason': 'Grund',
    'reasons': 'Gründe',
    'correctiveAction': 'Korrekturmaßnahme',
    'correctiveActions': 'Korrekturmaßnahmen',
    'downtime': 'Stillstandzeit',
    'downtimes': 'Stillstandzeiten',
    'wiki': 'Wiki-Artikel',
    'disconnected': 'getrennt',
    'rootCause': 'Hauptursache',
    'detachRootCause': 'Ursache lösen',
    'rootMachine': 'Maschine mit der Hauptursache',
    'spectrum': 'Arbeitsspektrum',
    'downtimeList': 'Liste Stillstandzeiten',
    'default': 'Grundeinstellung',
    'tags': 'Stichworte',
    'addTags': 'Füge Stichworte hinzu',
    'addSelected': 'Auswahl hinzufügen',
    'plannedDowntimes': 'geplante Stillstandzeiten',
    'unplannedDowntimes': 'ungeplante Stillstandzeiten',
    'workTime': 'Arbeitszeit',
    'actionTaken': 'Korrekturmaßnahme ausgeführt',
    'noOngoingOrder': 'kein laufender Auftrag',
    'updateQueued': 'Stillstand hat Warteschlange aktualisiert. Dies kann einige Sekunden dauern',
    'noForcedState': 'Keine Probleme mit dem Tag \'Forced\' definiert. Fügen Sie mit diesem Tag eine Ausfallzeitdefinition hinzu, um die Maschine oder Linie zu stoppen',
    'downtimeSince': 'Ausfallzeit seit',
    'lasting': 'dauerhaft',
    'noOngoingShift': 'Keine laufende Schicht',
    'overrideQueued': 'Ausfallzeit-Einfügung in die Warteschlange gestellt. Dies kann einige Sekunden dauern.'
  },
  'general': {
    'name': 'Name',
    'from': 'von',
    'search': 'Suche:',
    'max': 'max',
    'min': 'min',
    'noResults': 'keine Ergebnisse',
    'title': 'Titel',
    'description': 'Beschreibung',
    'fromStart': 'von Start',
    'confirm': 'bestätige',
    'tryAgain': 'erneut versuchen',
    'to': 'zu',
    'toEnd': 'beenden',
    'finish': 'Beenden',
    'add': 'hinzufügen',
    'all': 'alle',
    'clear': 'löschen',
    'hidden': 'verborgen',
    'remove': 'löschen',
    'detach': 'anfügen',
    'attach': 'ablösen',
    'close': 'schließen',
    'cancel': 'abbrechen',
    'refresh': 'aktualisieren',
    'edit': 'bearbeiten',
    'event': 'Ereignis',
    'details': 'Details',
    'summary': 'Zusammenfassung',
    'save': 'speichern',
    'change': 'ändern',
    'noData': 'keine Daten',
    'nothingDefined': 'nicht definiert',
    'others': 'Andere',
    'back': 'zurück',
    'results': 'Ergebnisse',
    'selected': 'ausgewählt',
    'selectAll': 'alle auswählen',
    'createNew': 'erstelle neu',
    'useExisting': 'bestehenden benutzen',
    'select': 'auswählen',
    'saveSuccessed': 'Speichern erfolgreich',
    'saveFailed': 'Speichern fehlgeschlagen',
    'shouldArchive': 'sollte archivieren',
    'areYouSure': 'Sind Sie sicher?',
    'view': 'Ansicht',
    'yes': 'ja',
    'no': 'nein',
    'reason': 'Grund',
    'actions': 'Aktionen',
    'reasonUnknown': 'Grund unbekannr',
    'forcedByOperator': 'gesetzt durch Bediener',
    'type': 'Typ',
    'valueType': 'Werttyp',
    'value': 'Wert',
    'unit': 'Einheit',
    'disable': 'deaktivieren',
    'disabled': 'deaktiviert',
    'disabledShe': 'deaktiviert',
    'inactive': 'inaktiv',
    'enable': 'aktivieren',
    'enabled': 'aktiviert',
    'enabledShe': 'aktiviert',
    'ignore': 'ignorieren',
    'nominal': 'nominal',
    'unsavedChanges': 'Möchten Sie die Seite wirklich verlassen? Sie haben ungesicherte Änderungen!',
    'now': 'jetzt',
    'showAll': 'zeige Alle',
    'showMore': 'zeige mehr',
    'continue': 'fortfahren',
    'group': 'gruppieren',
    'download': 'runterladen',
    'dir': {'top': 'Anfang', 'bottom': 'Ende', 'left': 'links', 'right': 'rechts'},
    'sourceURI': 'Quell URI',
    'dimensions': 'Dimensionen',
    'status': 'Status',
    'code': 'Code',
    'start': 'Start',
    'end': 'Ende',
    'update': 'Update',
    'deviceId': 'Geräte ID',
    'time': 'Zeit',
    'show': 'zeige',
    'hide': 'verberge',
    'last': 'letzter',
    'requestReceived': 'Anfrage erhalten',
    'restoreDefaults': 'Standardeinstellungen wiederherstellen',
    'setDate': 'Datum setzen',
    'setTime': 'Zeit setzen',
    'errors': 'Error',
    'inherited': 'Vererbt',
    'lastRefresh': 'Letzte Aktualisierung: vor {0}',
    'reset': 'Zurücksetzen',
    'collapseDetails': 'yet be translated',
    'next': 'Nächster',
    'allSelected': 'Alle ausgewählt',
    'unchecked': 'nicht ausgewählt',
    'manHours': 'Personenstunden',
    'cancelled': 'Abgebrochen',
    'progress': 'Fortschritt',
    'dates': 'Zeiträume',
    'pickDate': 'Zeitpunkt auswählen',
    'deleted': 'Gelöscht',
    'filters': 'Filter',
    'total': 'Gesamt',
    'line': 'Linie',
    'option': 'Option',
    'default': 'Standard',
    'set': 'einstellen',
    'apiUrls': 'API Adresse',
    'done': 'erledigt',
    'filterBy': 'Filtern nach',
    'apply': 'Anwenden',
    'clearAll': 'Alles löschen'
  },
  'machineControl': {
    'turnOn': 'anschalten',
    'turnOff': 'ausschalten',
    'current': 'aktuell',
    'liveStream': 'live stream',
    'disconnectedWarning': 'WARNUNG! Maschine ist nicht verbunden - durch Ausschalten gehen Daten verloren',
    'startMachine': 'Maschine <b>{machine}</b> starten'
  },
  'lineControl': {'startLine': 'Start linie <b>{linie}</b>?', 'stop': 'Stopp', 'start': 'Start'},
  'time': {
    'week': 'Woche',
    'minutes': 'Minuten',
    'date': 'Datum',
    'day': 'Tag',
    'hour': 'Stunde',
    'hours': 'Stunden',
    'hoursPlural': 'Stunden',
    'days': 'Tage',
    'month': 'Monat',
    'year': 'Jahr',
    'quarter': 'Quartal',
    'resolution': 'Auflösung',
    'duration': 'Dauer',
    'seconds': 'Sekunden',
    'startDate': 'Startdatum',
    'endDate': 'Enddatum',
    'startTime': 'Startzeit',
    'endTime': 'Endzeit',
    'selectDay': 'Tag auswählen',
    'longerThan': 'Länger als',
    'shorterThan': 'Kürzer als',
    'scope': 'Zeitumfang',
    'currentOrder': 'aktueller Auftrag',
    'showMinorStops': 'Zeige Kleinstörungen',
    'plannedOnly': 'Zeige nur geplante',
    'unplannedOnly': 'Zeige nur ungeplante',
    'performanceOnly': 'Leistungsverlust',
    'availabilityOnly': 'Verfügbarkeitsverlust',
    'utilizationOnly': 'Nutzungsverluste',
    'excludeDefaults': 'Standardwerte ausblenden',
    'monday': 'Montag',
    'tuesday': 'Dienstag',
    'wednesday': 'Mittwoch',
    'thursday': 'Donnerstag',
    'friday': 'Freitag',
    'saturday': 'Samstag',
    'sunday': 'Sonntag',
    'plannedDate': 'geplannter Zeitraum',
    'custom': 'anpassen'
  },
  'graph': {
    'flow': 'Fluss',
    'flows': 'Flüsse',
    'production': 'Produktionsfluss',
    'physical': 'physisch',
    'supplying': 'versorgend',
    'archiveChanges': 'Änderungen speichern',
    'isActive': 'aktiv',
    'addElement': 'Element hinzufügen',
    'detached': 'abgetrennt',
    'andon': 'Andon',
    'noActiveFlow': 'Kein aktiver Flow'
  },
  'monitoring': {
    'ofThePlan': 'Sie haben %{value}% des Wertes erreicht',
    'noPlan': 'kein Auftrag',
    'averageTargetPerformance': 'Durchschnittliche Zielleistung',
    'reportParam': 'Berichtsparametrierung',
    'designSpeed': 'Geplante',
    'validatedSpeed': 'Validierte',
    'targetProduction': 'Produktionsziel',
    'maxPerfProduction': 'Leistungsbasierter Plan',
    'plannedFromOrders': 'Auftragsbasierter Plan',
    'planned': 'geplant',
    'notInFlow': 'Nicht im Produktionsfluss',
    'produced': 'Produziert',
    'wasted': 'Ausschuss',
    'lastMessage': 'letzte Nachricht',
    'noMessages': 'keine Nachrichten',
    'noAlarms': 'keine Alarme',
    'noConnectionInfo': 'keine Information über die Verbindung',
    'noConnection': 'keine Verbindung zu dem Server',
    'processing': 'wird bearbeitet',
    'dataProcessingFromLast': 'letzte Daten werden bearbeitet',
    'lineStates': 'Linien Zustand',
    'disconnected': 'getrennt',
    'serverDisconnected': 'Server getrennt',
    'working': 'produziert',
    'ready': 'bereit',
    'notWorking': 'Störung',
    'productionProgress': 'Produktionsfortschritt',
    'production': 'Produktion',
    'productionMap': 'Produktionsplan',
    'workStates': 'Zustände',
    'workLog': 'Zustandshistorie',
    'event': 'Ereignis',
    'events': 'Ereignisse',
    'cause': 'Ursache',
    'causes': 'Ursachen',
    'reasons': 'Gründe',
    'warnings': 'Warnungen',
    'correctiveAction': 'Korrekturmaßnahme',
    'stopRealTime': 'Stoppe Echtzeit',
    'stoppedRealTime': 'reale Zeit gestoppt',
    'realTime': 'reale Zeit',
    'toRealTime': 'Start Echtzeit',
    'downtimes': 'Stillstände',
    'changeCurrentDowntime': 'Ändere Stillstandsgrund',
    'doubleClickToEdit': 'doppel Klick um Stillstandsgrund zu ändern',
    'percentShare': 'prozentualer Anteil',
    'timeShare': 'zeitlicher Anteil',
    'minorStops': 'inklusive Kleinstörungen',
    'remainingPlannedProduction': 'Mögliche Restproduktion basierend auf Nenndrehzahl',
    'remainingEstimatedProduction': 'Mögliche Restproduktion basierend auf effektiver Geschwindigkeit',
    'remainingTime': 'verbleibende Zeit',
    'performance': {
      'short': 'Leistung der letzten 3 Minuten',
      'effective': 'effektive Leistung',
      'nominal': 'Nominale Leistung'
    },
    'segment': {
      'baseQuantity': 'Basismenge',
      'baseQuality': 'Basisqualität',
      'baseAvailability': 'Basisverfügbarkeit',
      'basePerformance': 'Basisleistung'
    },
    'machine': {
      'counter': 'Zähler',
      'parameters': 'Parameter',
      'alarms': 'Alarme',
      'processParameters': 'Prozessparameter',
      'currentParameters': 'aktuelle Parameter',
      'activeAlarms': 'aktive Alarme',
      'counters': 'Zähler',
      'alarmsHoldups': 'Alarme und Hindernisse',
      'performanceInfo': 'Durchschnittliche Leistung der letzten 3 Minuten',
      'others': 'Andere',
      'lifespan': 'Lebensdauer',
      'environmentCondition': 'Umgebungsbedingungen',
      'condition': 'Anlagenzustand',
      'cost': 'Kosten',
      'failurePreditcion': 'Fehlervorhersage',
      'probabilityFailure': 'Wahrscheinlichkeit eines Ausfalls innerhalb von 10 Tagen',
      'failureType': 'Ausfalltyp',
      'timeToFailure': 'Vorhergesagte Zeit bis zum Ausfall',
      'predictedFailure': 'Vorhergesagter Ausfall',
      'maintenanceHistory': 'Wartungshistorie'
    },
    'lineInfo': {
      'advices': 'Hinweise',
      'forService': 'Aufgaben',
      'jobs': 'Aufträge',
      'completed': 'erledigte Aufträge',
      'problems': 'Probleme',
      'planned': 'Aufträge in der Warteschlange',
      'toAchieveTarget': 'Erwartetes Produktionsvolumen zum jetzigen Zeitpunkt:',
      'confirmLinePause': 'bestätige Linien Stopp'
    },
    'worstAlarms': 'schlimmste Probleme',
    'problems': 'keine Probleme | ein Problem | {0} Probleme',
    'performanceSettingHistory': 'Verlauf der Leistungs Einstellungen',
    'unitConversionsHistory': 'Verlauf der Einheiten Umrechnung',
    'performanceType': 'Leistungs Typ',
    'goodCount': 'Gut Menge',
    'wasteCount': 'Ausschuss Menge',
    'overdue': '{0} überfällig',
    'left': '{0} übrig'
  },
  'components': {
    'title': 'Komponenten',
    'one': 'Komponente',
    'usagePerUnit': 'Verbrauch pro Einheit',
    'required': 'erforderlich',
    'convertUnit': 'Einheit umrechnen',
    'validation': 'Bestätigung erforderlich',
    'batchTracking': 'Chargenverfolgung',
    'scanTrackingId': 'Scanne Tracking Nummer',
    'batchTrackingHelp': 'Wenn die Sendungsverfolgung aktiviert ist, müssen Sie für jedes gescannte Paket eine Chargenidentifikationsnummer angeben',
    'defaultPackageQuantity': 'im Paket',
    'warningLevelPercentage': 'Anteil Warnung',
    'warningLevel': 'Warnungs Level',
    'warningLevelHelp': '\\n % - Warnung, wenn ein bestimmter Prozentsatz des Pakets verbraucht ist - Warnung, wenn die geschätzte Anzahl der verbleibenden Minuten erreicht ist',
    'scannerCode': 'Scannercode',
    'scannerCodeType': 'Code Typ',
    'noTrackingId': 'keine Tracking ID',
    'scan': 'Scan',
    'packages': 'Pakete',
    'scanPackages': 'Pakete scannen',
    'noComponents': 'keine zugewiesenen Komponenten',
    'invalidCode': 'Komponente nicht gefunden',
    'addComponent': 'Komponente hinzufügen',
    'closed': 'geschlossen',
    'scanned': 'gescannt',
    'closeConfirm': 'schließe',
    'eta': 'ETA (Estimated time of availability / vorraussichtlicher Ende des Auftrags)',
    'active': 'aktiv',
    'clickToActivate': 'klicke zum aktivieren',
    'used': 'verbraucht',
    'estimatedTotalQuantity': 'geschätzte benötigte Menge',
    'packageScannerCode': 'Paket Code',
    'defaultPackageQuantitySum': 'Gesamtmenge in Paketen',
    'registeredDate': 'Scan Datum',
    'trackingId': 'Tracking ID',
    'consumptionType': {
      'title': 'Verbrauchsart',
      'help': '\\n \'MULTIPLE\' erlaubt viele geöffnete Pakete auf einmal, das erste wird verbraucht.\\n\\n \'SINGLE\' schließt automatisch das vorherige Paket, wenn ein neues aktiviert wird, das letzte wird verbraucht \\n'
    }
  },
  'oee': {
    'oee': 'OEE',
    'oeeLoss': 'OEE Verluste',
    'availability': 'Verfügbarkeit',
    'performance': 'Leistung',
    'quality': 'Qualität',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Verfügbarkeit [%]',
    'performancePercentage': 'Leistung [%]',
    'teep': 'TEEP',
  },
  'metrics': {
    'mtbf': 'Mittlere Zeit zwischen Ausfällen',
    'mttr': 'Mittlere Reperaturzeit',
    'mtbm': 'Mittlere Zeit zwischen den Wartungen',
    'reliabilityMetrics': 'Kennzahlen "Zuverlässigkeit"'
  },
  'energy': {
    'usedEnergy': 'Energieverbrauch',
    'currentLeastEfficient': 'derzeit am wenigsten effiziente Maschine',
    'currentPower': 'aktuelle Energie',
    'baseline': 'Ausgangswert',
    'max': 'Durchschnittliches Maximum'
  },
  'items': {
    'items': 'Artikel',
    'import': 'Artikel importieren',
    'itemGroups': 'Artikel Gruppen',
    'itemGroup': 'Artikel Gruppe',
    'groups': 'Gruppen',
    'add': 'Artikel hinzufügen',
    'addGroup': 'Gruppe hinzufügen',
    'new': 'Neuer Artikel',
    'edit': 'Artikel Ändern',
    'number': 'Nummer',
    'description': 'Beschreibung',
    'quantity': 'Menge',
    'unit': 'Mengeneinheit',
    'performance': 'Leistung',
    'expectedPerformance': 'erwartete Leistung',
    'expectedChangeover': 'erwartete Leistung Rüsten',
    'packingStructure': 'Verpackungsstruktur',
    'groupPackingStructure': 'guppiere Verpackungsstruktur',
    'itemRoutingSpeed': 'Leitungsgeschwindigkeit der Artikel',
    'groupRoutingSpeed': 'gruppiere Leitungsgeschwindigkeit',
    'components': 'Komponenten',
    'item': 'Artikel',
    'inheritedFromGroup': 'Von der Gruppe geerbt',
    'lineConfiguration': 'Linien Konfiguration',
    'noLineConfigured': 'Keine Produkte für diese Linie konfiguriert'
  },
  'pageNotFoundExplanation': 'Wir können diese Seite nicht finden. Entschuldigung für die Unannehmlichkeiten.',
  'processParams': {
    'compositionName': 'Zusammensetzung Name',
    'compositions': 'Zusammensetzungen',
    'saveComposition': 'Zusammensetzung speichern',
    'signals': 'Signale',
    'signalName': 'Signal Name',
    'signalValue': 'Signal Wert',
    'ongoingState': 'aktueller Zustand',
    'ongoingProblem': 'aktuelles Problem',
    'ongoingOrder': 'aktueller Auftrag',
    'problemStart': 'Problem Start',
    'problemEnd': 'Problem Ende',
    'orderStart': 'Auftrag Start',
    'orderEnd': 'Auftrag Ende',
    'orderProduction': 'Autfrag Produktion'
  },
  'healthcheck': {'noDriverConnection': 'Keine Triber Verbindung'},
  'vision': {
    'vision': 'Vision',
    'showAllClips': 'zeige alle Clips',
    'selectCamera': 'Kamera auswählen',
    'getClip': 'Erhalte den Clip',
    'getTheTwoPreviousMinutes': 'Erhalten Sie 2 Minuten vor und 2 Minuten nach dem Start der Ausfallzeit',
    'noClips': 'Keine Clips gefunden',
    'noRecords': 'Kamera hat keine Aufzeichnungen von diesem Tag zum Download',
    'currentVideoTime': 'aktuelle Aufnahmezeit',
    'startVideoTime': 'starte Aufnahmezeit',
    'startDowntime': 'starte Stillstandzeit',
    'endDowntime': 'beende Stillstandzeit',
    'showLiveVideo': 'zeige Livebild der Kamera',
    'selectStartClip': 'wähle start Video',
    'selectEndClip': 'wähle beende Video',
    'videoDurationIs': 'Videodauer ist',
    'invalidTimeRange': 'ungültige Zeitspanne',
    'toMoreBefore': 'wähle ein früheres Startdatum für die Aufzeichnung',
    'toMoreAfter': 'wähle ein späteres Enddatum für die Aufzeichnung',
    'maxVideoIs': 'maximale Videolänge ist',
    'auto': 'automatisch',
    'autoLong': 'Video automatisch vom System angefordert',
    'manual': 'manuell',
    'manualLong': 'Video manuell vom Benutzer angefordert',
    'videoTrigger': 'Automatischen Video-Upload aktivieren',
    'autoDownload': 'Automatischer Video-Upload',
    'watchVideo': 'Video ansehen'
  },
  'downloadStatus': {
    'failed': 'fehlgeschlagen',
    'inProgress': 'in Arbeit',
    'success': 'bereit',
    'failedOnEdge': 'Kamera fehlgeschlagen',
    'noArchiveRecordings': 'Kamera hat keine Aufzeichnugen',
    'deleted': 'gelöscht'
  },
  'quests': 'Aufgaben',
  'quest': {
    'quest': 'Aufgabe',
    'questTitle': 'Aufgaben Titel',
    'questDescription': 'Aufgaben Beschreibung',
    'project': 'Projekt',
    'type': 'Aufgaben Typ',
    'state': 'Aufgaben Status',
    'closeToState': 'Status wenn Stillstand beendet ist',
    'assignToGroups': 'zu Gruppen zuweisen',
    'taskDetails': 'Aufgabendetails',
    'files': 'Daten',
    'comments': 'Kommentare',
    'noFiles': 'Keine Daten'
  },
  'health': {'health': 'Gesundheitszustand', 'healthHistory': 'Gesundheitszustand Verlauf'},
  'panel': {
    'number': 'Nummer',
    'sku': 'SKU (Artikelnummer)',
    'selectState': 'wähle Status aus',
    'selectMachine': 'wähle Maschine aus',
    'comment': 'Kommentar',
    'downtimeFrom': 'Stillstandszeit von',
    'ongoing': 'laufend',
    'averagePerformance': 'durchschnittliche Leistung',
    'currentPerformance': 'aktuelle Leistung',
    'currentLineStatus': 'Aktueller Linienstatus',
    'provideReasonAndComment': 'Grund und Kommentar hinzufügen',
    'commentPlaceholder': 'Kommentar einfügen',
    'confirmData': 'Daten bestätigen',
    'inputPlaceholder': 'Hier schreiben'
  },
  'workCalendar': 'Schichtplan',
  'schedule': 'Zeitplan',
  'importSchedule': 'Zeitplan importieren',
  'addSchedule': 'Zeitplan hinzufügen',
  'repeatEvery': 'Wiederhole jeden',
  'repeatOn': 'Wiederhole an',
  'ends': 'endet',
  'starts': 'beginnt',
  'never': 'nie',
  'on': 'an',
  'cycle': 'Zyklus',
  'deleteRecurringInstance': 'Wiederkehrende Instanz löschen',
  'editRecurringInstance': 'Wiederkehrende Instanz bearbeiten',
  'thisInstance': 'diese Instanz',
  'thisAndFollowingInstances': 'diese und folgende Instanzen',
  'allInstances': 'alle Instanzen',
  'order': {
    'addNewProperty': 'neuen Wert hinzufügen',
    'sortByDate': 'Sortieren nach Datum',
    'sortBy': 'Sortieren nach',
    'ascending': 'aufsteigend',
    'descending': 'absteigend',
    'quantityProduced': 'produzierte Menge',
    'plannedProduction': 'Geplante Produktion',
    'endDate': 'Enddatum',
    'activatedDate': 'Aktivierungsdatum',
    'totalTime': 'Dauer des Auftrags',
    'plannedDate': 'Startdatum',
    'eta': 'voraussichtliches Ende',
    'delete': 'Filter reinigen'
  },
  'noCycle': 'kein Zyklus definiert',
  'users': {'firstName': 'Vorname', 'lastName': 'Nachname'},
  'teams': {'teamName': 'Teamname'},
  'schedules': {'addLine': 'Zeile zu Filtern hunzufügen', 'clearLines': 'alle Zeilen löschen'},
  'chart': {'weekSymbol': 'W'},
  'currentWeek': 'Aktuelle Woche',
  'factoryStructure': 'Fabrikstruktur',
  'production': {'rawCounterIncreases': 'Rohzählerwerte in diesem Zeitraum'},
  'calendar': {'period': 'Zeitraum'},
  'taxonomies': {
    'taxonomy': 'Taxonomie',
    'classification': 'Klassifizierung',
    'selectClassification': 'Wähle Klassifizierung',
    'selectClassificationLevel': 'Wähle Klassifizierungslevel',
    'selectClassificationNodes': 'Wähle Klassifizierungsvernetzung',
    'defaultTaxonomy': 'Standard',
    'pickCategory': 'Wähle Kategorie',
    'selectTaxonomy': 'Wähle Taxonomie',
    'selectTaxonomyLevel': 'Wähle Taxonomielevel'
  },
  'menu_logoutQuestion': 'Möchten Sie ausloggen?',
};
