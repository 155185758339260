<template>
  <RouterView
    v-if="haveProperties && haveStructure && haveLocalization"
    @page="$emit('page', $event)"
  />
  <div
    v-else
    class="loading-overlay"
  >
    <Loader
      :error="error"
      class="overlay-spinner"
    />
  </div>
</template>

<script>
import axios from '@/api/axios';
import Graph from '@/components/graph/Graph';
import { elementType } from '@/utils/dictionary';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    plantId: String,
  },
  data: () => ({
    haveProperties: false,
    haveStructure: false,
    haveLocalization: false,
    previousPlantId: null,
    error: null,
    elementTypeEnum: elementType,
  }),
  computed: {
    ...mapGetters('plant', [
      'structure',
      'elements',
    ]),
    ...mapGetters('element', [
      'elementType',
    ]),
    ...mapGetters('core', {
      lang: 'language',
    }),
    langCode() {
      return this.lang.lang;
    },
    nPlantId() {
      if (this.plantId === 'null' || this.plantId === 'undefined') {
        return null;
      }
      return this.plantId;
    },
  },
  watch: {
    plantId() {
      this.updateStore();
      if (this.nPlantId) {
        this.haveProperties = false;
        this.haveStructure = false;
        this.haveLocalization = false;
      }
      this.$store.dispatch('report/selected', null);
      this.requests();
      this.$forceUpdate();
      this.previousPlantId = this.plantId;
    },
    langCode() {
      this.haveProperties = false;
      this.requests();
      this.$forceUpdate();
    },
  },
  methods: {
    ...mapActions([
      'getPlantUnits',
      'getSkus',
      'getLocalization',
    ]),
    ...mapActions('element', [
      'getElementProperties',
    ]),
    ...mapActions('plant', [
      'getElements',
      'getShifts',
      'getPlantGraphs',
      'getGraphs',
    ]),
    ...mapActions('work', [
      'getPlantEventsConfig',
    ]),
    ...mapActions('taxonomies', [
      'getWorkEventTaxonomies',
      'getElementTaxonomies',
    ]),
    ...mapActions('customLayout', [
      'getCustomLayoutElementProperties',
    ]),
    updateStore() {
      this.$store.commit('setPlantId', this.nPlantId);
      this.$store.dispatch('changePlant', this.nPlantId);
      this.$nextTick(async () => {
        try {
          await this.getLocalization();
        } finally {
          this.haveLocalization = true;
        }
      });
    },
    setWeekStart(properties) {
      const weekStartProp = properties.find(x => x.name.toLowerCase() === 'weekstart');
      const weekStart = parseInt(weekStartProp?.value, 10);
      if (!Number.isNaN(weekStart)) {
        this.$store.commit('core/setWeekStart', weekStart);
      }
    },
    requests() {
      if (!this.nPlantId) return;
      const { plantId } = this;

      axios.post('v2/plant/accessed');

      this.getElementProperties({
        params: {
          plantId,
          elementId: plantId,
        },
      })
        .then(({ data }) => {
          this.$store.commit('plant/setProperties', data);
          this.$store.commit('plant/isLoaded', true);
          this.setWeekStart(data);
          this.haveProperties = true;
        });

      this.getPlantUnits({ params: { plantId: this.plantId } });

      this.getElements({
        params: {
          plantId,
          query: {
            with_capabilities: true,
          },
        },
      }).then(({ data }) => {
        this.haveStructure = true;
        this.$store.commit('plant/fillFromElements', data);
        this.requestGraphs();
      })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.error = { detail: this.$t('error.criticalPleaseRefresh') };
        });
      this.getSkus({ params: { plantId } });

      this.getPlantEventsConfig({ params: { plantId } })
        .then(({ data }) => {
          this.$store.commit('work/setWorkEvents', data.workEvents);
          this.$store.commit('work/setWorkEventTaxonomies', data.workEventTaxonomies);
          this.$store.commit('work/setWorkStates', data.workStates);
          this.$store.commit('work/setWorkTags', data.workTags);
        });

      this.getShifts({ params: { plantId } });
      this.requestTaxonomies();

      this.requestCustomLayoutElementProperties();
    },
    requestCustomLayoutElementProperties() {
      this.getCustomLayoutElementProperties({
        params: {
          plantId: this.plantId,
        },
      });
    },
    requestGraphs() {
      const { plantId } = this;
      this.getGraphs({
        params: {
          plantId,
          elementId: plantId,
          query: {
            type: ['Supplying', 'Physical'],
          },
        },
      })
        .then(({ data }) => {
          const graphs = data.map(f => {
            const nodes = f.nodes.map(n => this.structure.node(n));
            const g = new Graph(f.id, nodes, f.edges, f.elementId);
            g.name = f.name;
            g.type = f.type;
            return g;
          });
          this.$store.commit('plant/savePlantGraphs', graphs);
        });
      this.getPlantGraphs({
        params: {
          plantId,
          query: {
            type: ['Flow'],
          },
        },
      })
        .then(({ data }) => {
          const graphs = data
            .filter(x => x.isActive)
            .map(f => {
              const nodes = f.nodes.map(n => this.structure.node(n));
              const g = new Graph(f.id, nodes, f.edges, f.elementId);
              g.name = f.name;
              g.type = f.type;
              return g;
            });
          this.$store.commit('plant/updateFlows', graphs);
        });
    },
    async requestTaxonomies() {
      await this.getElementTaxonomies({
        params: {
          query: {
            namespaceId: this.plantId,
            tags: ['Element'],
          },
        },
      });
      await this.getWorkEventTaxonomies({
        params: {
          query: {
            namespaceId: this.plantId,
            tags: ['WorkEvent'],
          },
        },
      });
    },
  },
  created() {
    this.updateStore();
    this.requests();
    if (!this.nPlantId) {
      this.haveProperties = true;
    }
  },
};
</script>
