<template>
  <div class="d-flex flex-column">
    <div>
      <div class="grid-layout-wrapper">
        <div id="content">
          <GridLayout
            ref="gridlayout"
            class="grid-layout mx-auto"
            :layout.sync="disabledLayout"
            :col-num="gridWidth"
            :max-rows="gridHeight"
            :row-height="cellSize"
            :is-resizable="false"
            :margin="[10, 10]"
            :vertical-compact="false"
            :prevent-collision="true"

            :style="{
              width: `${editorWidth}px`,
              height: `${editorHeight}px`,
            }"
          >
            <GridItem
              v-for="(item, index) in parsedLayoutArray"
              :key="item.i"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              drag-ignore-from=".no-drag"
              class="grid-item"
            >
              <div
                class="w-100 h-100 position-relative no-drag"
              >
                <Component
                  :is="customLayoutService.getComponentForWidgetDetails(layoutDetails[index])"
                  v-if="customLayoutService.getComponentForWidgetDetails(layoutDetails[index])"
                  v-bind="customLayoutService.getComponentPropsForWidgetDetails(layoutDetails[index])"
                  :current-tags="currentTags"
                />
              </div>
            </GridItem>
          </GridLayout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import LineStopOffcanvas from '@/components/offcanvases/LineStopOffcanvas.vue';
import CustomLayoutGridItemEditor
  from '@/components/customLayout/editor/CustomLayoutGridItemEditor';
import CustomLayoutWidgetsLibrary
  from '@/components/customLayout/editor/CustomLayoutWidgetsLibrary';
import { mapGetters } from 'vuex';
import { elementType } from '@/utils/dictionary';
import customLayoutService from '@/components/customLayout/utils/customLayoutService';

export default {
  name: 'CustomLayoutGrid',
  props: {
    elementId: String,
    layoutStringValue: String,
    currentTags: Array,
  },
  data: () => ({
    cellSize: 80,
    layout: [],
    layoutDetails: [],
  }),
  components: {
    CustomLayoutWidgetsLibrary,
    CustomLayoutGridItemEditor,
    LineStopOffcanvas,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'screenWidth',
    ]),
    ...mapGetters('element', [
      'elementType',
    ]),
    parsedLayoutArray() {
      return this.layout.map(i => ({
        ...i,
        x: this.fullSizeLayout
          ? i.x
          : i.x % 4,
        y: this.fullSizeLayout
          ? i.y
          : this.getYForSmallLayout(i.x, i.y),
      }));
    },
    fullSizeLayout() {
      return this.screenWidth >= 1420;
    },
    disabledLayout() {
      return this.layout.map(x => ({
        ...x,
        static: true,
        draggable: false,
      }));
    },
    customLayoutService() {
      return customLayoutService;
    },
    gridWidth() {
      if (this.elementType(this.elementId) === elementType.line) {
        if (this.fullSizeLayout) return 12;
        return 4;
      }
      return 2;
    },
    gridHeight() {
      if (this.elementType(this.elementId) === elementType.line) {
        if (!this.fullSizeLayout) return Math.max(...this.parsedLayoutArray.map(x => x.y + x.h));
        return 2;
      }
      return Math.max(...this.layout.map(x => x.y + x.h));
    },
    editorHeight() {
      return this.cellSize * this.gridHeight + 10 * this.gridHeight;
    },
    editorWidth() {
      return this.cellSize * this.gridWidth + 10 * this.gridWidth;
    },
  },
  watch: {
    layoutStringValue(v) {
      this.loadLayout(v);
    },
  },
  methods: {
    loadLayout(saveDataString) {
      if (!saveDataString) return;
      try {
        const {
          layout,
          layoutDetails,
        } = JSON.parse(saveDataString);

        this.layout = layout || [];
        this.layoutDetails = layoutDetails || [];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error loading layout', e);

        this.layout = [];
        this.layoutDetails = [];
      }
    },
    getYForSmallLayout(x, y) {
      if (x < 4) return y;
      if (x < 8) return y + 2;
      return y + 4;
    },
  },
  created() {
    this.loadLayout(this.layoutStringValue);
  },
};
</script>

<style scoped lang="scss">
  .grid-layout-wrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 90px;
    margin: 0 -10px;

    .grid-item {
      border-radius: 8px;
      background: #fff !important;
      border: 1px solid #F0F1F3;
      box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px;
    }
  }
</style>
