<template>
  <div class="position-relative editor-wrapper">
    <div
      class="close-button cursor-pointer"
      @click="$emit('close')"
    >
      <i class="fas fa-times" />
    </div>

    <h4>
      {{ $t('customLayout.configureWidget') }}
    </h4>

    <hr class="mx-n4">

    <div class="w-100 text-center mx-auto mt-4 preview">
      <div
        class="widget-wrapper mx-auto"
        :style="{
          minWidth: `${cellSize * (Math.max(2, item.size.w) + 0.5)}px`,
          width: `${cellSize * (Math.max(2, item.size.w) + 0.5)}px`,
        }"
      >
        <div
          class="droppable-element"
          :style="{
            minWidth: `${cellSize * item.size.w}px`,
            width: `${cellSize * item.size.w}px`,
            minHeight: `${cellSize * item.size.h}px`,
            height: `${cellSize * item.size.h}px`,
          }"
        >
          <Component
            :is="customLayoutService.getComponentForWidgetDetails(item)"
            v-if="customLayoutService.getComponentForWidgetDetails(item)"
            v-bind="customLayoutService.getComponentPropsForWidgetDetails(item)"
            :current-tags="currentTags"
          />
        </div>
      </div>

      <span class="small text-black-50">
        {{ $t('customLayout.widgetPreview') }}
      </span>
    </div>

    <hr>

    <div
      class="overflow-y-auto"
      style="max-height: calc(100vh - 460px)"
    >
      <div class="d-flex align-items-center justify-content-between mb-4 mt-4">
        <div class="d-flex flex-column w-100 pr-4">
          <label class="mb-0">
            {{ $t('customLayout.title') }}
          </label>
          <BInput
            v-model="item.title"
            size="sm"
            :placeholder="`${$t('customLayout.enterWidgetTitle')}...`"
          />
        </div>
        <div class="d-flex flex-column">
          <label class="mb-0">
            {{ $t('customLayout.size') }}
          </label>
          <select
            v-if="item.availableSizes"
            class="form-control-sm"
            :value="getSizeOptionValue(item.size)"
            @input="changeSize"
          >
            <option
              v-for="size in item.availableSizes"
              :key="getSizeOptionValue(size)"
              :value="getSizeOptionValue(size)"
            >
              {{ getSizeOptionValue(size) }}
            </option>
          </select>
          <p
            v-else
            class="mb-0 form-control-sm cursor-not-allowed"
          >
            {{ getSizeOptionValue(item.size) }}
          </p>
        </div>
      </div>

      <div
        v-for="field in item.fields"
        :key="field.name"
        class="mb-4"
      >
        <label>
          {{ field.name }}
        </label>

        <div class="d-flex">
          <div
            class="field-type-button"
            :class="{
              'selected': field.type === customLayoutWidgetFieldType.static
            }"
            @click="changeFieldType(field, customLayoutWidgetFieldType.static)"
          >
            <i class="fas fa-a" />
            <span>
              {{ $t('customLayout.staticValue') }}
            </span>
          </div>

          <div
            class="field-type-button"
            :class="{
              'selected': field.type === customLayoutWidgetFieldType.tag
            }"
            @click="changeFieldType(field, customLayoutWidgetFieldType.tag)"
          >
            <i class="fas fa-tag" />
            <span>
              {{ $t('customLayout.tag') }}
            </span>
          </div>

          <div
            class="field-type-button"
            :class="{
              'selected': field.type === customLayoutWidgetFieldType.noInput
            }"
            @click="changeFieldType(field, customLayoutWidgetFieldType.noInput)"
          >
            <i class="fas fa-times-circle" />
            <span>
              {{ $t('customLayout.noInput') }}
            </span>
          </div>
        </div>

        <div class="mt-2">
          <BInput
            v-if="field.type === customLayoutWidgetFieldType.static"
            v-model="field.value"
            :placeholder="`${$t('customLayout.enterStaticValue')}...`"
            size="sm"
          />
          <CurrentTagSelect
            v-if="field.type === customLayoutWidgetFieldType.tag"
            v-model="field.value"
            :current-tags="currentTags"
          />
        </div>
      </div>

      <div class="bottom-actions">
        <BButton
          class="mr-4"
          size="sm"
          @click="$emit('close')"
        >
          {{ $t('general.cancel') }}
        </BButton>

        <BButton
          size="sm"
          variant="primary"
          @click="$emit('update-item', item)"
        >
          <i class="fas fa-check mr-1" />
          {{ $t('general.confirm') }}
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { customLayoutWidgetFieldType } from '@/components/customLayout/customLayoutDictionary';
import customLayoutService from '@/components/customLayout/utils/customLayoutService';
import CurrentTagSelect from '@/components/customLayout/CurrentTagSelect.vue';

export default {
  name: 'CustomLayoutGridItemEditor',
  props: {
    cellSize: Number,
    currentTags: Array,
    editItem: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    item: {},
    customLayoutWidgetFieldType,
  }),
  components: {
    CurrentTagSelect,
  },
  computed: {
    customLayoutService() {
      return customLayoutService;
    },
  },
  methods: {
    getSizeOptionValue(option) {
      return `${option.w}x${option.h}`;
    },
    changeSize(event) {
      const [w, h] = event.target.value.split('x');
      this.item.size = { w: +w, h: +h };
    },
    changeFieldType(field, type) {
      // eslint-disable-next-line no-param-reassign
      field.type = type;
      // eslint-disable-next-line no-param-reassign
      field.value = '';
    },
  },
  created() {
    this.item = { ...this.editItem };
  },
};
</script>

<style scoped lang="scss">

  .widget-wrapper {
    background-color: #F0F0F0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 200px;
    min-height: 200px;
    height: 200px;
    position: relative;
    border-radius: 8px;
    transition: 0.3s all;
    border: 1px solid transparent;
  }

  .droppable-element {
    border-radius: 8px;
    background: #fff !important;
    border: 1px solid #F0F1F3;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px;
  }

  .close-button {
    position: absolute;
    top: 0;
    opacity: 0.5;
    font-size: 1.5rem;
    right: 0.25rem;
  }

  label {
    font-weight: 600;
  }

  .field-type-button {
    width: 70px;
    height: 70px;
    padding: 0.5rem;
    border: 1px solid gainsboro;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;

    &.selected {
      border-color: #5886C1;
      border-width: 3px;
    }

    i {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    span {
      text-align: center;
      font-size: 11px;
      line-height: 10px;
      font-weight: 500;
    }
  }

  .editor-wrapper {
    margin-bottom: 3rem;
  }

  .bottom-actions {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    width: 600px;
    display: flex;
    justify-content: center;
    margin: 0 -1.5rem;
    border-top: 1px solid #e9ecef;
  }
</style>
