/* eslint-disable max-len */
export default {
  rules: 'Rules',
  functions: 'Pre-processing Functions',
  hints: 'Hint rules',
  addRule: 'Add rule',
  help: 'Help',
  inputName: 'Input name',
  tag: 'Tag',
  operatorCockpit: 'Operator cockpit',
  weekStart: 'Week start',
  cockpitType: 'Cockpit type',
  cockpitLayout: 'Cockpit layout',
  countingOEE: 'Counting OEE',
  maxPerformance: 'Max instantaneous performance',
  maxPerformanceInfo: 'Used in the counter guard, to validate if the new counter value is feasible (0 = guard disabled)',
  excludeOEE: 'Exclude OEE',
  thresholds: 'Thresholds',
  saved: 'Saved',
  baseUnit: 'Base unit',
  baseOrderUnit: 'Default order unit',
  notSelected: 'Not Selected',
  saveFailed: 'Save Failed',
  addMachine: 'Add machine to filters',
  noCounter: 'Machine does not have a counter',
  machineBaseUnitSetInCounter: 'Machine unit is determined by the counter\'s unit',
  shortPerformancePeriod: 'Period',
  setupIssues: 'Setup issues',
  noIssuesDetected: 'No configuration issues detected!',
  test: 'Test',
  isDummy: 'Is dummy',
  isDummyInfo: 'Dummy machines are not monitored and have no signals. They can be marked manually as root cause of a downtime.',
  maxTimeBufferOffset: 'Stop delay after the previous machine',
  maxTimeBufferOffsetInfo: 'If the previous machine stopped working, how much time is needed for this machine to stop?',
  rootCauseDelay: 'Root cause delay',
  rootCauseDelayInfo: 'How late this machine can break, for other machines to still point to it as the root cause?',
  signalNameError: 'Signal name cannot contain "(" and ")" characters',
  dataSource: 'Data source',
  edgeDevice: 'Edge device',
  timeSourceHeader: 'Time source',
  timeSource: {
    origin: 'OPC time',
    edgeDevice: 'Edge device time',
    iotHub: 'IoT Hub time',
    queue: 'Queue time',
  },
  timeOffset: 'Time correction in seconds',
  timeOffsetNegativeValues: 'negative values turn back the clock',
  timeOffsetHeader: 'Time correction',
  rule: {
    rule: 'Rule',
    condition: 'Condition',
    addAndCondition: 'AND',
    showAdvanced: 'Use advanced editor',
    info: 'Machine state is determined based on the rules below. When a signal value change is observed,the rule engine will analyse the rules below starting from the highest priority.The first rule that returnes \'true\' will determine the new state',
    ruleType: 'Rule type',
    eventType: 'Simple conditions',
    cSharpType: 'C# function',
    timeBasedRule: 'Time based rule',
    script: 'Script',
  },
  events: {
    forcedOnly: 'Only forced',
    notAssignedEvents: 'Not assigned events',
    showSystem: 'Show system events',
  },
  tooltipState: {
    lack: 'This state appears when your machine does not receive enough components needed for its continuous work. The problem may be a faulty conveyor, the operators who don’t refill components on time, or the component storage being too far away from the line',
    work: 'This state appears when your machine is properly working and the production process is in progress',
    inactive: 'This is a neutral state that PackOS uses for your machine when there is no failure but the machine is not working',
    stop: 'This state indicates that your machine has been stopped on purpose by a factory worker because they wanted to, for example, configure or clean it. When your machine is in this state, PackOS shows the problem that occurred before the Stopped by Operator state went into effect',
    failure: 'This state indicates that your machine has stopped or is not functioning properly because of, for example, mechanical wear or operator error. In this case, the source of the problem is the problem that occurred before the Failure state appeared',
    off: 'This state appears when your machine is disconnected because, for example, power was cut or the machine lost the connection with the driver',
    idle: 'This state shows when your machine is not working although it is not broken and there is no problem with it. In this case, PackOS looks for the source of the problem by checking the upstream machine',
    nolivedata: 'This is a temporary state that appears when PackOS temporarily loses connection with the factory, for example, because of the internet connection problems. PackOS shows this state only temporarily and quickly changes it to other “operational” states',
    holdup: 'This state appears when your machine is not working because its output is blocked, usually by downstream machines. In this case, PackOS looks for the source of the problem by checking the downstream machine. The source of the problem in the downstream machine is copied as the state and problem for the machine with the status Holdup',
    changeover: 'This state appears when your machine is stopped because either the production line or the machine is adjusted for a new production process (changeover)',
  },
  shortPerformance: 'Instantaneous performance',
  shortPerformanceInfo: 'Instantaneous performance calculated as a recent average production increase',
  value: 'Value',
  priority: 'Priority',
  dayOfWork: 'Day of work',
  plantConfiguration: 'Plant configuration',
  machineSignals: 'Machine signals',
  signals: 'Signals',
  signalsExport: 'Export signals',
  logixGateConfigExport: 'Export LogixGate Config',
  signalsImport: 'Import signals',
  workEventsImport: 'Import problems',
  csvImport: 'Import CSV',
  csvExport: 'Export CSV',
  properties: 'Properties',
  signalsAndProperties: 'Signals and properties',
  machineLicenses: 'Machine licenses',
  addSignal: 'Add signal',
  addProperty: 'Add property',
  electricity: 'Electricity',
  electricityBaseline: 'Electricity baseline',
  electricityMax: 'Electricity average max',
  shiftDefinitions: 'Shift definitions',
  info: 'Info',
  deleteInfo: 'Press \'Save\' button to delete',
  timezone: 'Timezone',
  yourCurrent: 'Your current',
  differentTimezoneInfo: 'You are in a different timezone than the factory!',
  differentTimezoneSettingsInfo: 'Settings below will apply to factory timezone',
  differentTimezoneSelectorInfo: 'We\'ll display time converted to your timezone',
  differentialWaste: 'Differential waste',
  wasteBaseDiffCounter: 'Base machine to calculate differential waste',
  disableBufferResetBetweenOrders: 'Disable buffer reset between orders',
  workingHours: 'Working hours',
  addNewShift: 'Add new shift',
  productionExpectations: 'Production targets',
  doYouWantToDelete: 'Do you want to delete',
  newShift: 'New shift',
  editShift: 'Edit shift',
  removeShift: 'Remove shift',
  users: 'Users',
  nothingToAdd: 'Nothing to add',
  attachedLinesAndMachines: 'Attached lines and machines',
  addLineOrMachine: 'Add line / machine',
  layout: 'Layout',
  saveLayout: 'Save layout',
  elementsOrder: 'Elements order',
  advanced: 'Advanced',
  default: 'Default',
  noLayoutLine: 'Line is not defined in plant layout',
  units: 'Units',
  unitCode: 'Unit Code',
  isBaseUnit: 'Is Base',
  fromUnit: 'Child UoM',
  toUnit: 'Parent UoM',
  multiplier: 'Multiplier',
  shortName: 'Short Name',
  blockCard: 'Block card',
  activeSince: 'Active since',
  production: 'Production',
  card: 'Card',
  cardId: 'Card id',
  cardPin: 'Card PIN',
  oldPin: 'Previous PIN',
  newPin: 'New PIN',
  pinChange: 'Change PIN',
  pinLengthError: 'PIN must have exactly 4 numbers',
  cardIdLengthError: 'Card ID must have at lest 4 characters',
  connections: 'Flows',
  customLayout: 'Custom layout',
  groupOfMachinesWithCustomLayout: 'Group of machines with custom layout',
  displaySettings: 'Display',
  adminPlantSettings: 'Admin plants settings',
  dataValidation: 'Data validation',
  importTemplates: 'Import templates',
  orderAutostartThreshold: 'Order autostart',
  forcedStateThreshold: 'Ending forced downtime',
  downtimeThreshold: 'Downtime counter lock',
  minorStopThreshold: 'Threshold of a minor stop',
  designPerformanceInfo: 'The machine performance that can achieved according to the manufacturer',
  validatedPerformanceInfo: 'Performance of the machine that was measured and validated in real conditions',
  orderAutostartThresholdInfo: 'Threshold defining the amount of production needed to start an order automatically',
  forcedStateThresholdInfo: 'Threshold defining the amount of production needed to finish the forced downtime automatically',
  downtimeThresholdInfo: `Threshold defining the amount of production needed to end the downtime.
    The ongoing downtime won't be changed to other downtime or work, until the specified amount of products has been registered`,
  calculateEnergyFromMachines: 'Calculate energy from machines',
  expectations: {
    expectedOee: 'Expected OEE',
    expectedPerformance: 'Expected Performance',
    maxExpectedFailures: 'Expected maximum number of failures',
    validatedPerformance: 'Validated performance',
    designPerformance: 'Design performance',
  },
  automation: {
    automation: 'Automation',
    add: 'Add automation',
    type: {
      CreateQuestOnWorkEvent: {
        title: 'Create task',
        description: 'Create task when this type of problem occurs on the machine',
      },
      CreateAndCloseQuestOnWorkEvent: {
        title: 'Create and close task',
        description: 'Create task when this type of problem occurs on the machine, and automatically close it when the problem stops',
      },
    },
  },
  taxonomy: {
    taxonomy: 'Taxonomy',
  },
  unit: {
    usedInPackageStructure: 'Removing the unit impossible, it is used in a package structure definition',
    fieldsNotValid: 'Incorrect field format',
  },
  sku: {
    expectedEffort: 'Expected effort (mh)',
    skuDescriptionTooltip: 'If left empty, SKU description will be used',
  },
  function: {
    sessionName: 'Session name',
    sessionInfo: 'Functions using the same session name will be executed sequentially (rather than concurrently)',
    run: 'Run',
    testValue: 'Test value',
    testResult: 'Test result',
    triggers: 'Triggers',
    trigger: 'Trigger',
    script: 'Script',
    isStateful: 'Stateful',
    sequenceProcessing: 'Process following tags sequentially',
    newFunction: 'Add function',
    editFunction: 'Edit function',
    storeParameters: 'Store parameters',
    executions: 'Executions history',
    logs: 'Logs',
    processed: 'Processed',
    sourceTimestamp: 'Source timestamp',
  },
  energy: 'Energy',
  setUrl: 'Save new URL',
  authUrl: 'Authentication URL address',
  loadedOnNextLogin: 'Will be loaded after next login',
  allTypes: 'All types',
  cameras: {
    location: 'Location',
    startDownload: 'Start download',
    endDownload: 'End download',
    before: 'before',
    after: 'after',
    startOfTheDowntime: 'Start of the downtime',
    addCamera: 'Add camera',
    editCamera: 'Edit camera',
    cameraName: 'Camera name',
    cameraDescription: 'Camera description',
    assignToMachine: 'Assign to line / machine',
    edgeDevice: 'Edge device',
    diskSize: 'Disk size',
    automaticallyDownloadVideoClip: 'Automatically download video clip',
    starting: 'Starting',
    ending: 'Ending',
  },
  edgeDevices: {
    edgeDevice: 'Edge device',
    addEdgeDevice: 'Add edge device',
    edgeDeviceName: 'Edge device name',
    enterName: 'Enter name',
    timeSource: 'Time source',
    chooseDeviceType: 'Choose device type',
    deleteThisEdgeDevice: 'Delete this edge device?',
    noEdgeDevices: 'No edge devices',
    recommendedName: 'Recommended name',
    readFromIoTHub: 'Read from IoT Hub',
    notRegisteredIoTHub: 'Device not registered in IoT Hub',
    registeredIoTHub: 'Registered in IoT Hub',
    createConnectionStrings: 'Create connection strings',
    createConnectionStringsInfo: 'A new \'Device\' will be registered in IoT Hub and connection keys will be generated allowing you to connect your LogiX Gate instance',
    showConnectionStrings: 'Show connection strings',
    connections: 'Connections',
    noConnectionRegistered: `This edge device has no connections defined!<br>
    Configure the connections in LogiX Gate config and restart the service to continue`,
    readReportedConnections: 'Refresh available connections',
    samplingInterval: 'Sampling interval',
    discoverTags: 'Discover tags',
    nonOpcConnectionEdit: 'You can only edit OPC UA connection',
    unableToRunNodeDiscovery: 'Unable to run node discovery',
    updateStatus: {
      released: 'Configuration in sync',
      dirty: 'Update in progress...',
      deleted: 'Connection no longer exist',
    },
    tagSource: 'Tag source'
  },
};
