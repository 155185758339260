<template>
  <div style="height: calc(100% - 70px)">
    <div
      class="d-flex justify-content-between align-items-center title-box"
    >
      <h5
        v-if="!groupByStatus"
        class="mb-0"
      >
        <i class="fas fa-info-circle" />
        {{ $t('orders.orderDetails') }}
      </h5>

      <h5
        v-else
        class="d-flex align-items-center"
      >
        <span
          class="font-weight-normal text-black-50 text-ellipsis d-inline-block mr-1"
          style="max-width: 190px;"
        >
          {{ elementName(lineId) }}
        </span>
        <span>
          / {{ $t('orders.orders') }}
        </span>
      </h5>

      <button
        v-if="groupByStatus"
        class="btn btn-primary icon-btn mr-auto ml-2 position-relative"
        style="width: 28px; height: 28px; top: -4px;"
        @click="showNewActivityModal = true"
      >
        <i class="fas fa-plus" />
      </button>

      <BModal
        v-model="showNewActivityModal"
        hide-header
        hide-footer
        modal-class="modal-fullscreen"
      >
        <ActivityFormModal
          :line="lineId"
          @close="showNewActivityModal = false"
          @saved="handleAddNewActivity"
        />
      </BModal>

      <div class="h5 mb-0 text-black-50">
        <i
          class="fas fa-times cursor-pointer p-1"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div class="mx-n4">
      <hr>
    </div>

    <div class="h-100 d-flex flex-column">
      <div
        ref="list"
        class="content-wrapper"
        :style="{
          maxHeight: contentWrapperHeight,
          minHeight: contentWrapperHeight,
        }"
      >
        <div v-if="activitiesList.length === 0">
          <h5 class="text-center font-weight-normal mt-5 pt-2 text-black-50">
            <i class="fas fa-info-circle" />
            {{ $t('activities.noActivities') }}
          </h5>
        </div>

        <OrderList
          v-if="!groupByStatus"
          :list="activitiesList"
          :selected-id="selected && selected.id"
          @update:selectedId="$emit('update:selectedId', $event)"
        />

        <div
          v-for="status in statusesInOrder"
          v-else
          :key="status"
          class="mb-4"
        >
          <h5
            :style="{
              color: statusInfo(status).color,
            }"
          >
            {{ statusInfo(status).desc }}
          </h5>

          <OrderList
            v-if="groupByStatus"
            :list="activitiesGroupedByStatus[status]"
            hoverable
            :selected-id="selected && selected.id"
            @update:selectedId="$emit('update:selectedId', $event)"
          />
        </div>
      </div>

      <div class="mx-n4">
        <hr>
      </div>

      <div
        class="actions-footer"
        :style="{
          maxHeight: footerHeight,
          minHeight: footerHeight,
        }"
      >
        <OrderActions
          v-if="selected"
          :order="selected"
          show-edit
          :can-manage-orders="canManageOrders"
          :can-confirm-production="canConfirmProduction"
          :can-edit-orders="canEditOrders"
          :can-edit-orders-completed="canEditOrdersCompleted"
          :can-edit-orders-inprogress="canEditOrdersInprogress"
          class="pt-1 pb-1"
          @edit="formModal = true"
          @settle="settle = true"
          @activated="handleOrderAction"
          @stopped="handleOrderAction"
          @started="handleOrderAction"
          @deleted="handleOrderAction"
          @audit="auditModal = true; auditActivityId = selectedId"
          @showProductionStats="$emit('showProductionStats', selectedId)"
          @showDetails="showDetails = $event"
        />

        <div
          v-if="showUpdateProductionButton || showReportResourcesButton"
          class="mx-n4"
        >
          <hr>
        </div>

        <div
          v-if="showUpdateProductionButton || showReportResourcesButton"
          class="d-flex justify-content-center"
        >
          <div
            v-if="showUpdateProductionButton"
            class="bottom-button w-50"
            @click="showProductionReconciliationModal()"
          >
            <i class="far fa-clipboard mr-1" />
            {{ $t('orders.updateProduction') }}
          </div>

          <div
            v-if="showReportResourcesButton"
            class="bottom-button w-50"
            @click="showResourceReportModal(true)"
          >
            <i class="far fa-file-lines mr-1" />
            {{ $t('activities.resources.manhours') }}
          </div>
        </div>
      </div>
    </div>

    <LModal
      v-if="settle"
      :show.sync="settle"
      size="xl"
    >
      <OrderSettlementForm
        :order="selected"
        @close="settle = false"
        @completed="handleOrderAction"
      />
    </LModal>

    <LModal
      v-if="selected"
      :show.sync="showDetails"
      size="xl"
    >
      <OrderDetails
        :order="selected"
        @settle="$emit('settle', selectedId)"
      />
    </LModal>

    <BModal
      v-model="formModal"
      hide-header
      hide-footer
      modal-class="modal-fullscreen"
    >
      <ActivityFormModal
        :activity="selected"
        :is-selected="true"
        is-edited
        @close="formModal = false"
        @saved="$emit('saved', $event); formModal = false"
      />
    </BModal>

    <LModal
      :show.sync="auditModal"
      size="xl"
    >
      <AuditLog
        :activity-id="auditActivityId"
      />
    </LModal>
  </div>
</template>

<script>
import OrderActions from '@/components/order/OrderActions.vue';
import {
  PACKOS_MANUAL_PRODUCTION,
  PACKOS_ORDER_COMPLETED_EDIT,
  PACKOS_ORDER_COMPLETED_MANUALPRODUCTION,
  PACKOS_ORDER_EDIT,
  PACKOS_ORDER_INPROGRESS_EDIT,
  PACKOS_ORDER_LIFECYCLE,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_ORDER_VIEW,
  PACKOS_PLANT_ADMIN,
} from '@core/dict/permissions';
import { mapActions, mapGetters } from 'vuex';
import ActivityFormModal from '@/components/activity/form/ActivityFormModal.vue';
import OrderSettlementForm from '@/components/order/OrderSettlementForm.vue';
import AuditLog from '@/components/audit/AuditLog.vue';
import OrderList from '@/components/order/OrderList.vue';
import OrderDetails from '@/components/order/OrderDetails.vue';
import { activityStatus, activityType, lineProperty } from '@/utils/dictionary';

export default {
  name: 'ActivitiesOffcanvas',
  props: {
    lineId: String,
    activities: Array,
    selected: Object,
    groupByStatus: Boolean,
  },
  data: () => ({
    formModal: false,
    showDetails: false,
    settle: false,
    auditModal: false,
    showNewActivityModal: false,
    auditActivityId: '',
    manualWayOfWorkingEnabledForThisLine: false,
  }),
  components: {
    OrderDetails,
    OrderList,
    AuditLog,
    OrderSettlementForm,
    ActivityFormModal,
    OrderActions,
  },
  computed: {
    ...mapGetters('core', ['canOnElement']),
    ...mapGetters([
      'plantId',
      'isMobile',
      'getActivityState',
    ]),
    ...mapGetters('core', ['can']),
    ...mapGetters('plant', [
      'flows',
      'displayChangeovers',
      'displayLabour',
      'displayManualWayOfWorking',
    ]),
    ...mapGetters('element', ['elementName']),
    isActivated() {
      return this.selected && this.selected.status === activityStatus.activated;
    },
    isInProgress() {
      return this.selected && this.selected.status === activityStatus.started;
    },
    isCompleted() {
      return this.selected && this.selected.status === activityStatus.completed;
    },
    isDraft() {
      return this.selected && this.selected.status === activityStatus.draft;
    },
    isOrder() {
      return this.selected && this.selected?.type === activityType.order;
    },
    isChangeover() {
      return this.selected && this.selected?.type === activityType.changeover;
    },
    isCleaning() {
      return this.selected && this.selected?.type === activityType.cleaning;
    },
    activitiesList() {
      return this.activities || [this.selected];
    },
    footerHeight() {
      if (this.isInProgress) return '110px';
      if (this.selected) return '56px';
      return '0';
    },
    contentWrapperHeight() {
      return `calc(100% - 60px - ${this.footerHeight})`;
    },
    statusesInOrder() {
      const order = [
        activityStatus.started,
        activityStatus.activated,
        activityStatus.released,
        activityStatus.draft,
        activityStatus.completed,
      ];
      return order.filter(status => this.activitiesStatuses.includes(status));
    },
    activitiesGroupedByStatus() {
      if (!this.groupByStatus) return [];
      return this.activitiesList.reduce((acc, activity) => {
        const { status } = activity;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(activity);
        return acc;
      }, {});
    },
    activitiesStatuses() {
      return Object.keys(this.activitiesGroupedByStatus);
    },
    selectedId() {
      if (!this.selected) return '';
      return this.selected.id;
    },
    showUpdateProductionButton() {
      return this.isInProgress && (this.canConfirmProduction
        && (this.displayManualWayOfWorking || this.manualWayOfWorkingEnabledForThisLine)
        && this.isOrder);
    },
    showReportResourcesButton() {
      return this.isInProgress && this.canConfirmProduction && this.displayLabour;
    },
    canConfirmProduction() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_MANUAL_PRODUCTION, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_VIEW, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_COMPLETED_MANUALPRODUCTION, this.selected.lineId);
    },
    canManageOrders() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_LIFECYCLE, this.selected.lineId);
    },
    canEditOrders() {
      if (!this.selected) return false;
      return this.canOnElement(PACKOS_ORDER_MANAGEMENT, this.selected.lineId)
        || this.canOnElement(PACKOS_ORDER_EDIT, this.selected.lineId);
    },
    canEditOrdersCompleted() {
      if (!this.selected) return false;
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
        || this.can(PACKOS_ORDER_MANAGEMENT, this.plantId)
        || this.can(PACKOS_ORDER_COMPLETED_EDIT, this.plantId);
    },
    canEditOrdersInprogress() {
      if (!this.selected) return false;
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
        || this.can(PACKOS_ORDER_MANAGEMENT, this.plantId)
        || this.can(PACKOS_ORDER_INPROGRESS_EDIT, this.plantId);
    },
    isEditBlocked() {
      return this.canEditOrdersCompleted || this.canEditOrdersInprogress;
    },
  },
  watch: {
    auditModal(v) {
      if (!v) {
        this.auditActivityId = '';
      }
    },
    selectedId() {
      setTimeout(() => {
        this.scrollToSelectedItem();
      }, 10);
    },
  },
  methods: {
    ...mapActions('element', ['getElementProperties']),
    showProductionReconciliationModal() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          productionReconciliation: true,
          activityId: this.selected.id,
        },
      });
    },
    showResourceReportModal(resources) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          reportResources: true,
          activityId: this.selected.id,
          humanResources: resources,
        },
      });
    },
    handleOrderAction() {
      this.formModal = false;
      this.showDetails = false;
      this.settle = false;
      this.$emit('request');
    },
    confirmCurrentProduction() {
      this.reportResources = false;
      this.currentProductionStatsModal = true;
    },
    checkManualWayOfWorkingForThisLine() {
      if (!this.selectedId) return;
      this.getElementProperties({
        params: {
          plantId: this.plantId,
          elementId: this.selected.lineId,
        },
      })
        .then(({ data }) => {
          const property = data
            .find(x => x.name.toLowerCase() === lineProperty.manualWayOfWorkingEnabled.toLowerCase());

          this.manualWayOfWorkingEnabledForThisLine = property?.value === 'true' || property?.value === 1;
        });
    },
    statusInfo(status) {
      return this.getActivityState({ status });
    },
    handleAddNewActivity() {
      this.showNewActivityModal = false;
      this.$emit('refresh');
    },
    scrollToSelectedItem() {
      if (!this.selectedId) return;
      if (this.$refs.list) {
        const scrollToElement = this.$refs.list.querySelector(`#activities-${this.selectedId}`);
        if (!scrollToElement) return;

        this.$refs.list.scrollTo({
          top: scrollToElement.offsetTop - scrollToElement.clientHeight / 2,
        });
      }
    },
  },
  created() {
    this.checkManualWayOfWorkingForThisLine();
  },
};
</script>

<style scoped lang="scss">
  .content-wrapper {
    overflow-y: auto;
    margin-bottom: auto;
  }

  .bottom-button {
    font-size: 15px;
    font-weight: 500;
    line-height: 17.58px;
    text-align: center;
    color: #5886C1;
    cursor: pointer;
    user-select: none;
  }
</style>
