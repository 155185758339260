<template>
  <div>
    <div class="pb-3">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="px-2">
          <span class="title-text">
            <i class="icon-sku pr-1" />
            {{ $t('items.items') }}
          </span>
        </div>
        <div
          v-if="canEditSkus && page != 'components'"
          class="mb-2 mb-md-0 row no-gutters flex-grow-1"
          style="max-width: 600px"
        >
          <div class="col-4 px-2">
            <button
              class="btn btn-primary btn-block btn-sm w-100"
              @click="create"
            >
              <i class="fas fa-plus pr-1" />
              {{ page === 'items' ? $t('items.add') : $t('items.addGroup') }}
            </button>
          </div>
          <div class="col-4 px-2">
            <button
              class="btn btn-primary btn-block btn-sm"
              @click="showFlexibleImporter"
            >
              <i class="fas fa-file-upload pr-1" />
              {{ $t('import.import') }}
            </button>
          </div>
          <div class="col-4 px-2">
            <button
              class="btn btn-primary btn-block btn-sm"
              @click="exportItemsClicked"
            >
              <i class="fas fa-file-download pr-1" />
              {{ $t('items.export') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="items-container">
      <SubPageSelector
        :list="subpages"
        :selected="page"
        @selected="changeSubpage"
      />

      <div
        v-if="page === 'items'"
        class="container mt-4"
      >
        <Items
          v-if="!loadingItems"
          :items="items"
          :can-edit-skus="canEditSkus"
          :groups="groups"
          @selected="edit"
          @edit-many-groups="editManyGroups"
          @delete-bulk="bulkDeleteSkus"
        />
        <Loader v-else />
      </div>
      <div
        v-else-if="page === 'itemGroups'"
        class="container mt-4"
      >
        <ItemGroups
          :groups="groups"
          :can-edit-skus="canEditSkus"
          @edit="edited = $event; itemGroupForm = true;"
        />
      </div>

      <LModal
        :show.sync="manyGroupsModal"
      >
        <div class="font-weight-bold text-left mb-2">
          {{ $t('items.itemGroups') }}:
        </div>
        <ItemBatchGroupAssignment
          ref="groupAssignment"
          :item-ids="selectedIds"
          @cancelled="manyGroupsModal = false"
          @saved="saved(); manyGroupsModal = false"
        />
        <div
          v-if="manyGroupsSaveError"
          class="text-center text-danger"
        >
          {{ $t('general.saveFailed') }}
        </div>
      </LModal>

      <LModal
        :show.sync="bulkDeleteModal"
      >
        <BOverlay
          :show="bulkDeleteLoading"
          spinner-variant="primary"
          class="p-2"
        >
          <div class="font-weight-bold text-center my-4">
            {{ $t('items.bulkDeleteWarning') }}
          </div>
          <BRow>
            <BCol
              cols="6"
              class="pr-2"
            >
              <BButton
                variant="secondary"
                size="sm"
                block
                :disabled="bulkDeleteLoading"
                @click="bulkDeleteModal = false"
              >
                {{ $t('general.cancel') }}
              </BButton>
            </BCol>
            <BCol
              cols="6"
              class="pl-2"
            >
              <BButton
                variant="danger"
                size="sm"
                block
                class="text-white"
                @click="handleBulkSkuDelete"
              >
                {{ $t('items.bulkDeleteConfirm') }}
              </BButton>
            </BCol>
          </BRow>
        </BOverlay>
      </LModal>

      <LModal
        :show.sync="itemForm"
        :lazy="true"
        class="col-md-5"
        size="xl"
      >
        <button
          type="button"
          class="close modal-close position-absolute"
          @click="itemForm = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row px-4">
          <ItemForm
            :plant-id="plantId"
            :sku="selected"
            style="width: 100%"
            @cancelled="formCancelled"
            @created="created"
          />
        </div>
      </LModal>

      <LModal
        :show.sync="itemGroupForm"
        class="col-md-5"
        size="xl"
      >
        <button
          type="button"
          class="close modal-close position-absolute"
          @click="itemGroupForm = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <ItemGroupForm
          :sku-group="edited"
          style="width: 100%; margin-right: 15px"
          @deleteSkuGroup="requestItems()"
          @close="itemGroupForm = false"
          @created="edited = $event"
        />
      </LModal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import {
  PACKOS_MATERIAL_MANAGEMENT,
  PACKOS_PLANT_ADMIN,
  PACKOS_SKU_EDIT,
} from '@core/dict/permissions';
import ItemForm from '@/components/item/ItemForm';
import Items from '@/components/item/Items';
import ItemGroups from '@/components/item/ItemGroups';
import ItemGroupForm from '@/components/item/ItemGroupForm';
import SubPageSelector from '@/layout/dashboard/selectors/SubPageSelector';
import ItemBatchGroupAssignment from '@/components/item/ItemBatchGroupAssignment';

export default {
  props: {
    plantId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    page: 'items',
    itemForm: false,
    itemGroupForm: false,
    selectedId: null,
    loadingItems: false,
    edited: null,
    selectedIds: [],
    manyGroupsModal: false,
    bulkDeleteLoading: false,
    bulkDeleteModal: false,
    assignmentPending: false,
    manyGroupsSaveError: false,
  }),
  components: {
    Items,
    ItemGroups,
    ItemGroupForm,
    ItemForm,
    SubPageSelector,
    ItemBatchGroupAssignment,
  },
  computed: {
    ...mapGetters('core', ['can']),
    ...mapState({
      groups: state => state.item.skuGroups || [],
      items: state => state.item.items || [],
    }),
    canEditSkus() {
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
          || this.can(PACKOS_SKU_EDIT, this.plantId)
          || this.can(PACKOS_MATERIAL_MANAGEMENT, this.plantId);
    },
    selected() {
      if (!this.items || !this.selectedId) return null;
      return this.items.find(x => x.id === this.selectedId);
    },
    subpages() {
      return [
        {
          key: 'items',
          name: this.$t('items.items'),
          route: 'items',
          icon: 'icon-sku',
        },
        {
          key: 'itemGroups',
          name: this.$t('items.itemGroups'),
          route: 'itemGroups',
          icon: 'fas fa-object-group',
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'getSkus',
      'getPlantSkuGroups',
      'deleteSkuBulk',
      'exportItems',
    ]),
    editManyGroups(list) {
      this.selectedIds = list;
      this.manyGroupsModal = true;
    },
    bulkDeleteSkus(list) {
      this.selectedIds = list;
      this.bulkDeleteModal = true;
    },
    changeSubpage(r) {
      this.page = r.key;
    },
    create() {
      if (this.page !== 'items') {
        this.edited = null;
        this.itemGroupForm = true;
      } else {
        this.selectedId = null;
        this.itemForm = true;
      }
    },
    edit(id) {
      this.selectedId = id;
      this.itemForm = true;
    },
    formCancelled() {
      this.selectedId = null;
      this.itemForm = false;
    },
    showFlexibleImporter() {
      this.$router.push(`/${this.plantId}/importer/items`);
    },
    exportItemsClicked() {
      this.exportItems({ params: { plantId: this.plantId } })
        .then(response => {
          const blob = response.data;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `items_${moment().format('DD-MM-YYYY_HH-mm')}.xlsx`;
          document.body.appendChild(link);
          link.click();
        });
    },
    async requestItems() {
      this.loadingItems = true;

      const p1 = this.getSkus({
        params: {
          plantId: this.plantId,
        },
      });

      const p2 = this.getPlantSkuGroups({
        params: {
          plantId: this.plantId,
        },
      });

      return Promise.all([p1, p2])
        .finally(() => {
          this.loadingItems = false;
        });
    },
    created(id) {
      if (!this.selectedId) {
        this.selectedId = id;
      }
    },
    clearSelection() {
      this.itemForm = false;
      this.selectedId = null;
    },
    async handleBulkSkuDelete() {
      this.bulkDeleteLoading = true;

      const data = this.selectedIds;
      await this.deleteSkuBulk({
        params: {
          plantId: this.plantId,
        },
        data,
      });

      await this.requestItems();

      this.bulkDeleteLoading = false;
      this.bulkDeleteModal = false;
      this.selectedIds = null;
    },
  },
  created() {
    this.$emit('pageChange', 'items');
    this.requestItems();
  },
};
</script>

<style>
.items-container {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  overflow: auto;
}

.title-text {
  font-size: 1.75em;
  font-weight: 500;
  opacity: 0.9;
}
</style>
