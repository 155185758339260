<template>
  <div v-if="line">
    <Aggregate
      v-if="line"
      :element="line"
      :is-header="true"
      :editing-downtime="!!currentStateEdit"
      @currentStateEdit="handleCurrentStateEdit"
      @currentStateEditRefresh="handleCurrentStateEditRefresh"
    />

    <Offcanvas
      :show.sync="showOffcanvas"
      no-bottom-padding
    >
      <DowntimeSummaryOffcanvas
        v-if="currentStateEdit"
        :line-id="lineId"
        :parsed-downtime="currentStateEdit"
        :worklogs="[currentStateEdit]"
        :line-flow="lineFlow"
        @hide-summary="hideEdit"
      />
    </Offcanvas>
  </div>

  <div v-else>
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Aggregate from '@/components/element/Aggregate';
import loopRequest from '@/mixins/loopRequest';
import { elementType } from '@/utils/dictionary';
import DowntimeSummaryOffcanvas from '@/components/offcanvases/DowntimeSummaryOffcanvas';
import offcanvasDowntimeEditMixin from '@/mixins/offcanvasDowntimeEditMixin';

export default {
  props: {
    plantId: String,
    lineId: String,
  },
  data: () => ({
    elementTypeEnum: elementType,
    showOffcanvas: false,
    currentStateEdit: null,
  }),
  components: {
    DowntimeSummaryOffcanvas,
    Aggregate,
  },
  mixins: [
    loopRequest('getLineAdvices', 20000),
    offcanvasDowntimeEditMixin,
  ],
  computed: {
    ...mapGetters(['startDate', 'endDate', 'timezone']),
    ...mapGetters('plant', ['structure']),
    ...mapGetters('element', ['elementType']),
    pending() {
      return !('currentState' in this.line);
    },
    line() {
      return this.$store.getters['element/element'](this.lineId);
    },
    lineFlow() {
      const fs = this.$store.getters['plant/flows'](this.lineId);

      if (fs.length > 0) {
        return fs[0];
      }
      return null;
    },
    currentStateEditComments() {
      if (!this.currentStateEdit) return null;
      return this.getCommentsToDowntime(this.currentStateEdit);
    },
  },
  watch: {
    startDate() {
      this.getLineAdvices();
    },
    currentStateEditComments(v) {
      if (!this.currentStateEdit) return;
      this.currentStateEdit.comments = v;
    },
    showOffcanvas(v) {
      if (!v) {
        this.currentStateEdit = null;
      }
    },
  },
  methods: {
    ...mapActions('work', ['getWorsts']),
    ...mapActions('notify', ['getElementNotifications']),
    getLineAdvices() {
      const utcOffset = -(moment().utcOffset());
      const ids = this.structure.allLowerChildren(this.lineId)
        .filter(id => this.elementType(id) === this.elementTypeEnum.machine);

      return this.getElementNotifications({
        params: {
          plantId: this.plantId,
          utcOffset,
          current: true,
          query: {
            id: ids.concat([this.lineId]),
            startDate: this.startDate,
          },
        },
      });
    },
    requestWorsts() {
      this.getWorsts({
        params: {
          plantId: this.plantId,
          startDate: this.startDate,
          endDate: this.endDate,
          count: 3,
          query: {
            id: this.lineId,
          },
        },
      });
    },
    updateSelectedWorkEventReason(reasonId, reasonDescription) {
      this.currentStateEdit.reasonId = reasonId;
      this.currentStateEdit.reasonDescription = reasonDescription;
    },
    updateSelectedWorkEventCorrectiveAction(correctiveActionId, correctiveActionDescription) {
      this.currentStateEdit.correctiveActionId = correctiveActionId;
      this.currentStateEdit.correctiveActionDescription = correctiveActionDescription;
    },
    updateSelectedWorkEvent(data) {
      this.currentStateEdit.workEvent = { ...data };
    },
    updateSelectedRootWorkEvent(data) {
      this.currentStateEdit.rootWorkLog.workEvent = { ...data };
    },
    async handleCurrentStateEditRefresh(worklog) {
      await this.getComments(
        worklog.elementId,
        worklog.startDateTime,
        worklog.endDateTime,
      );

      this.currentStateEdit = { ...worklog };
    },
    async handleCurrentStateEdit(worklog) {
      await this.getComments(
        worklog.elementId,
        worklog.startDateTime,
        worklog.endDateTime,
      );

      this.currentStateEdit = worklog;
      this.showOffcanvas = true;
    },
    hideEdit() {
      this.showOffcanvas = false;
    },
  },
  created() {
    this.requestWorsts();
  },
};
</script>

<style>

</style>
