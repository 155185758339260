<template>
  <div style="position: relative; overflow: hidden">
    <div
      class="row header-row align-items-center"
      :class="{ disabled }"
    >
      <div
        v-if="splitByOrder && pendingOrder"
        class="w-100 d-flex"
      >
        <div class="shift-box">
          <span class="shift-time text-nowrap">
            <span class="font-weight-bold">{{
              pendingOrder.description || pendingOrder.externalId
            }}</span>:
            {{ $t('general.from') }} {{
              formatTime(
                (pendingOrder.actualExecution && pendingOrder.actualExecution.begin)
                  || (pendingOrder.actualActivation && pendingOrder.actualActivation.begin)
              )
            }}
          </span>
        </div>
      </div>
      <div class="col-md-4 col-lg-2 text-center position-relative order-0 align-self-stretch">
        <div class="d-flex flex-column justify-content-around h-100">
          <h2 class="line-name mb-0 text-fancy">
            {{ name }}
          </h2>

          <LineStateInfo
            v-if="showDowntimesSections"
            class="flex-grow-1 py-2"
            :plant-id="plantId"
            :editing-downtime="editingDowntime"
            :line-id="id"
            :active-orders="(activities && activities.startedOrder) ? [activities.startedOrder] : []"
            :current-state="currentState"
            @currentStateEdit="$emit('currentStateEdit', $event)"
            @currentStateEditRefresh="$emit('currentStateEditRefresh', $event)"
          />
        </div>
      </div>

      <div class="col-md-8 col-lg-4 text-center order-2 order-lg-3">
        <div class="picture-container position-relative">
          <CurrentPower
            v-if="displayEnergy && energy"
            v-bind="energy"
            :production="production ? production.done : null"
            :unit="production ? production.unit : null"
            class="current-power"
            style="width: 100px;"
          />

          <img
            :src="`${image(id)}`"
            class="line-picture"
            :style="{ opacity: loading ? 0.3 : 1 }"
          >
          <Healthcheck
            :element-id="id"
            class="healthcheck"
          />
        </div>

        <div
          class="line-charts"
        >
          <ProductionWithNumbers
            v-if="production && production.isUnitConversionComplete && showProductionSections"
            :production="production"
            :performance="performance"
            :production-from-orders="productionFromOrders"
            class="mb-1"
          />

          <StateParetoChart
            :clickable="false"
            :height="17"
            :disabled="!showDowntimesSections"
            :pareto-states="paretoStates"
            class="chart"
          />
          <LineRoutes
            class="d-md-none d-lg-flex"
            style="margin-top: 8px"
            :element-id="id"
            :oees="oees"
            :shift="currentShift"
            :pending-order="pendingOrder"
            :production="production ? production : {}"
            :performance="performance ? performance : {}"
            :target-oees="targetOees"
          />
        </div>
        <div
          v-if="loading"
          class="text-center w-100"
          style="position: absolute; left: 0; top: 50%"
        >
          <Loader />
        </div>
        <div
          v-if="disabled"
          class="text-center w-100 mt-2 text-uppercase text-secondary"
          style="position: absolute; left: 0; top: 50%"
        >
          {{ splitByOrder ? $t('work.noOngoingOrder') : $t('work.noOngoingShift') }}
        </div>
      </div>

      <div
        class="col-md-4 col-lg-2 order-md-3 order-lg-2 align-self-stretch"
        style="position: relative"
      >
        <LineActivity
          v-if="showLineActivity"
          :line-id="id"
          :activities="activities"
        />
      </div>

      <div
        class="col-md-4 col-lg-3 order-3 d-none d-md-block"
        style="margin-bottom: -5px"
      >
        <TriangularOEEs
          v-if="screenWidth >= 992 && !disabled && showOeeSections"
          v-bind="oees"
          :target="targetOees"
        />
        <VerticalOEEs
          v-else-if="!disabled && showOeeSections"
          v-bind="oees"
          :target="targetOees"
          size="xs"
          style="font-size: 10px;"
        />
      </div>

      <div
        class="col-md-4 col-lg-1 order-4 align-self-center"
        style="padding: 0"
      >
        <div
          class="w-100"
        >
          <div
            v-if="currentShift"
            class="shift-box px-2"
          >
            <span
              class="shift-time"
            >
              <span class="font-weight-bold">{{ currentShift.name }}</span>:
              {{ formatUnix(currentShift.startDate) }} - {{ formatUnix(currentShift.endDate) }}
            </span>
            <TimeProgress
              :start-date="currentShift.startDate"
              :end-date="currentShift.endDate"
            />
          </div>
          <div
            v-else
            class="shift-box px-2"
          >
            {{ $t('noShift') }}
          </div>
        </div>
        <LineActions
          :line-id="id"
          :current-state="currentState"
          @openProblems="$emit('openProblems')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import colors from '@/utils/colors';
import StateParetoChart from '@/components/charts/state-pareto/StateParetoChart';
import ProductionWithNumbers from '@/components/charts/production/ProductionWithNumbers';
import TriangularOEEs from '@/components/charts/oees/TriangularOEEs';
import VerticalOEEs from '@/components/charts/oees/VerticalOEEs';
import TimeProgress from '@/components/schedule/TimeProgress';
import { activityStatus, elementCapabilities } from '@/utils/dictionary';
import CalcDuration from '../../utils/calcDuration';
import Healthcheck from '../utils/Healthcheck';
import LineActions from './modules/LineActions';
import CurrentPower from './modules/CurrentPower';
import LineActivity from './modules/LineActivity';
import LineStateInfo from './modules/LineStateInfo';
import LineRoutes from './modules/LineRoutes';

export default {
  props: {
    id: String,
    name: String,
    oees: Object,
    targetOees: Object,
    paretoStates: Array,
    currentState: Object,
    production: Object,
    healthcheck: Object,
    performance: Object,
    activities: Object,
    energy: Object,
    shift: Object,
    disabled: Boolean,
    editingDowntime: [Object, Boolean],
    pending: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ProductionWithNumbers,
    StateParetoChart,
    LineStateInfo,
    TriangularOEEs,
    VerticalOEEs,
    LineActions,
    LineActivity,
    Healthcheck,
    CurrentPower,
    TimeProgress,
    LineRoutes,
  },
  computed: {
    ...mapGetters(['plantId', 'screenWidth', 'isMobile', 'splitByOrder', 'splitByShift']),
    ...mapGetters('plant', ['displayEnergy', 'displayOrders', 'productionFromOrders']),
    ...mapGetters('element', [
      'image',
      'hasCapabilities',
    ]),
    ...mapGetters(['shiftInstances']),
    showLineActivity() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.ACTIVITIES,
      ]);
    },
    showDowntimesSections() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.DOWNTIMES,
      ]);
    },
    showProductionSections() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.PRODUCTION,
      ]);
    },
    showOeeSections() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.OEE,
      ]);
    },
    loading() {
      return !this.healthcheck && this.pending && !this.disabled;
    },
    pendingOrder() {
      return this.activities?.activeOrder?.status === activityStatus.started ? this.activities?.activeOrder : undefined;
    },
    currentShift() {
      const now = moment().unix();
      return this.shiftInstances?.find(x => x.elementId === this.id && x.startDate <= now
        && x.endDate > now);
    },
  },
  methods: {
    colors,
    CalcDuration,
    formatUnix(date) {
      return moment.unix(date).format('HH:mm');
    },
    formatTime(date) {
      return moment(date).format('Do MMM LT');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .healthcheck {
    position: absolute;
    top: 0;
    right: 0;
  }

  .picture-container {
    width: 100%;
    height: 70px;
    display: inline-block;
    position: relative;
    text-align: center;
  }

  .picture-container {
    .current-power {
      margin-left: -10px;
    }
  }

  .line-picture {
    max-height: 70px;
    max-width: 100px;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .line-name {
    margin: 10px -5px auto -5px;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
  }

  .shift-box {
    padding: 5px;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -5px;
    border-radius: 5px;
    background-color: #f6f6f6;
  }

  .header-row {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 7px;
    border-top: 5px solid rgba(180, 180, 180, 0.1);

    &.disabled {
      opacity: 0.8;
    }
  }

  @media (min-width: 1000px) {
    .line-picture {
      max-width: 120px;
    }
    .picture-container {
      .current-power {
        margin-left: 0px;
      }
    }

  }

</style>
