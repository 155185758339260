import lang from '@/lang';
import { elementCapabilities, elementType, plantProperty } from '@/utils/dictionary';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const report = new Vapi({
  axios,
  state: {
    startDate: null,
    endDate: null,
    elementIds: [],
    reportConfigurations: [{}],
    types: [],
    skuWithOrders: null,
    selectedTaxonomy: '',
    selectedWorkEventsTaxonomy: '',
    selectedTrendScope: '',
    selectedTargetTrendScope: '',
    selectedShiftId: '',
    selectedOrderId: '',
    selectedSkuId: '',
  },
})
  .get({
    action: 'getOeeDetailsReport',
    path: ({ plantId }) => `/v2/plant/${plantId}/report/oee-details`,
  })
  .getStore({
    namespaced: true,
  });

report.mutations = {
  ...report.mutations,
  setStartDate(s, timestamp) {
    s.startDate = timestamp;
  },
  setEndDate(s, timestamp) {
    s.endDate = timestamp;
  },
  setReportElementIds(s, ids) {
    s.elementIds = ids || [];
  },
  setReportConfigurations(s, configurations) {
    s.reportConfigurations = configurations;
  },
  setSku(s, skuWithOrders) {
    s.skuWithOrders = skuWithOrders;
  },
  setSelectedTaxonomy(s, selectedTaxonomy) {
    s.selectedTaxonomy = selectedTaxonomy;
  },
  setSelectedWorkEventsTaxonomy(s, selectedTaxonomy) {
    s.selectedWorkEventsTaxonomy = selectedTaxonomy;
  },
  setSelectedTrendScope(s, scope) {
    s.selectedTrendScope = scope;
  },
  setSelectedTargetTrendScope(s, scope) {
    s.selectedTargetTrendScope = scope;
  },
  setSelectedShiftId(s, id) {
    s.selectedShiftId = id;
  },
  setSelectedOrderId(s, id) {
    s.selectedOrderId = id;
  },
  setSelectedSkuId(s, id) {
    s.selectedSkuId = id;
  },
  resetSelectedShiftOrderSkuParams(s) {
    s.selectedShiftId = '';
    s.selectedOrderId = '';
    s.selectedSkuId = '';
  },
};

report.actions = {
  ...report.actions,
  selected(ctx, elementId) {
    if (!elementId) {
      ctx.commit('setReportElementIds', []);
    } else {
      ctx.commit('setReportElementIds', [elementId]);
    }
  },
};

report.getters = {
  ...report.getters,
  startDate: s => s.startDate,
  endDate: s => s.endDate,
  elementIds: s => s.elementIds,
  reportConfigurations: s => s.reportConfigurations,
  skuId: s => s.skuWithOrders?.id,
  skuDateScopes: s => s.skuWithOrders?.orders,
  selectedTaxonomy: s => s.selectedTaxonomy,
  selectedWorkEventsTaxonomy: s => s.selectedWorkEventsTaxonomy,
  selectedTrendScope: s => s.selectedTrendScope,
  selectedTargetTrendScope: s => s.selectedTargetTrendScope,
  selectedShiftId: s => s.selectedShiftId,
  selectedOrderId: s => s.selectedOrderId,
  selectedSkuId: s => s.selectedSkuId,
  reportHasMachines: (s, g, rs, rg) => s.elementIds.some(elementId =>
    rg['element/elementType'](elementId) === elementType.machine),
  childrenLines: (s, g, rs, rg) => elementId => {
    if (!elementId || rg['element/elementType'](elementId) === elementType.plant) {
      return rg['plant/lines'].map(x => x.id);
    }
    if (!elementId || rg['element/elementType'](elementId) === elementType.area) {
      return rg['plant/lines']
        .filter(x => rg['plant/structure'].parent(x.id) === elementId)
        .map(x => x.id);
    }

    return [elementId];
  },
  types: (s, g, rs, rg) => {
    const res = [
      {
        key: 'custom',
        name: lang.t('reports.types.custom'),
        icon: 'ion ion-ios-easel',
        custom: true,
        reports: [
          {
            key: 'custom',
            name: lang.t('reports.new'),
            icon: 'ion ion-md-add',
            noElement: true,
            noDate: true,
          },
        ],
      },
      {
        key: 'availability',
        name: lang.t('reports.types.availability'),
        icon: 'fas fa-heartbeat',
        reports: [
          {
            key: 'worsts',
            name: lang.t('reports.types.downtimes'),
            icon: 'icon-downtime',
            noWhiteBackground: true,
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
          {
            key: 'worsts-columns',
            name: `${lang.t('reports.types.downtimes')} ${lang.t('reports.report')} ${lang.t('reports.types.column')}`,
            icon: 'icon-downtime',
            hidden: true,
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
          {
            key: 'comments-from-losses',
            name: lang.t('reports.types.commentsFromLosses'),
            icon: 'icon-downtime',
            hidden: true,
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
          {
            key: 'workstates',
            name: `${lang.t('work.states')} & ${lang.t('work.events')}`,
            icon: 'icon-work-event',
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
          {
            key: 'downtimeDuration',
            name: lang.t('reports.types.downtimeDurations'),
            icon: 'fas fa-chart-column',
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
          {
            key: 'comments',
            name: `${lang.t('reports.types.comments')}`,
            icon: 'fas fa-comments',
            noWhiteBackground: true,
            selectorElementCapabilities: [
              elementCapabilities.DOWNTIMES,
            ],
          },
        ],
      },
      {
        key: 'oee',
        name: lang.t('reports.types.oee'),
        icon: 'fas fa-stopwatch',
        reports: [
          {
            key: 'oee',
            name: lang.t('reports.types.oee'),
            icon: 'fas fa-stopwatch',
            selectorElementCapabilities: [
              elementCapabilities.OEE,
              elementCapabilities.PRODUCTION,
            ],
          },
          {
            key: 'oee-losses',
            name: lang.t('reports.types.oeeLosses'),
            icon: 'fas fa-chart-line',
            noItem: true,
            selectorElementCapabilities: [
              elementCapabilities.OEE,
              elementCapabilities.PRODUCTION,
            ],
          },
          {
            key: 'oee-details',
            name: lang.t('reports.types.oeeDetails'),
            icon: 'far fa-file-alt',
            noItem: true,
            selectorElementCapabilities: [
              elementCapabilities.OEE,
              elementCapabilities.PRODUCTION,
            ],
          },
          {
            key: 'oee-overview',
            name: lang.t('menu.overview'),
            icon: 'fas fa-stopwatch',
            hidden: true,
            selectorElementCapabilities: [
              elementCapabilities.OEE,
            ],
          },
          {
            key: 'notifications',
            name: lang.t('notify.type.hints'),
            icon: 'fas fa-bell',
            hidden: true,
            selectorElementCapabilities: [
              elementCapabilities.OEE,
              elementCapabilities.PRODUCTION,
            ],
          },
        ],
      },
      {
        key: 'production',
        name: lang.t('reports.types.production'),
        icon: 'ion ion-md-clipboard',
        reports: [
          {
            key: 'production',
            name: lang.t('reports.types.production'),
            icon: 'ion ion-md-clipboard',
          },
          {
            key: 'productionMap',
            name: lang.t('reports.types.productionMap'),
            icon: 'fas fa-map',
            noDate: true,
            noItem: true,
          },
        ],
      },
      {
        key: 'shifts',
        name: lang.t('shifts'),
        icon: 'ion ion-ios-people',
        reports: [
          {
            key: 'shifts',
            name: lang.t('shifts'),
            icon: 'ion ion-ios-people',
            noElement: true,
            noDate: true,
          },
        ],
      },
      {
        key: 'orders',
        name: lang.t('orders.orders'),
        icon: 'icon-orders',
        reports: [
          {
            key: 'orders',
            name: lang.t('orders.orders'),
            icon: 'icon-orders',
            noItem: true,
            noAreas: true,
            selectorElementCapabilities: [
              elementCapabilities.ACTIVITIES,
            ],
          },
        ],
      },
    ];

    if (rg['plant/plantProperty'](plantProperty.energy)) {
      res.push({
        key: 'energy',
        name: lang.t('reports.types.energy'),
        icon: 'ion ion-ios-flash',
        reports: [
          {
            key: 'energy',
            name: lang.t('reports.types.energyHistory'),
            icon: 'ion ion-ios-flash',
          },
          {
            key: 'energysankey',
            name: lang.t('reports.types.energySankey'),
            icon: 'ion ion-ios-flash',
          },
          {
            key: 'energy-trends',
            name: lang.t('reports.types.energyTrends'),
            icon: 'ion ion-ios-trending-up',
            noDate: true,
          },
        ],
      });
    }

    res.push({
      key: 'reports-download',
      name: lang.t('reports.types.reportsDownload'),
      icon: 'ion ion-ios-download',
      reports: [
        {
          key: 'reports-download',
          name: lang.t('reports.types.reportsDownload'),
          icon: 'ion ion-ios-download',
        },
      ],
    });

    return res;
  },
};

export default report;
