import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

export const CUSTOM_LAYOUT_STRING = 'CUSTOM_LAYOUT_STRING';
export const CUSTOM_LAYOUT = 'CUSTOM_LAYOUT';

const customLayout = new Vapi({
  axios,
  state: {
    customLayoutElementProperties: [],
    currentTags: [],
  },
})
  .get({
    action: 'getCustomLayoutElementProperties',
    path: ({ plantId }) =>
      `/v2/plant/${plantId}/elements/properties?name=${CUSTOM_LAYOUT}&name=${CUSTOM_LAYOUT_STRING}&name=EdgeDevice`,
    onSuccess: (s, { data }) => {
      s.customLayoutElementProperties = data;
    },
  })
  .get({
    action: 'getCurrentTags',
    property: 'currentTags',
    path: 'v2/tags/current',
  })
  .getStore({
    namespaced: true,
  });

customLayout.getters = {
  ...customLayout.getters,

  doesElementHaveCustomLayout: s => elementId => {
    const properties = s.customLayoutElementProperties.find(p => p.elementId === elementId);
    return properties
      ? (properties.properties.find(p => p.name === CUSTOM_LAYOUT)?.value || 'false') === 'true'
      : false;
  },
  elementCustomLayoutString: s => elementId => {
    const properties = s.customLayoutElementProperties.find(p => p.elementId === elementId);
    return properties
      ? properties.properties.find(p => p.name === CUSTOM_LAYOUT_STRING)?.value || ''
      : '';
  },
};

export default customLayout;
