export default {
  report: 'รายงาน',
  new: 'ใหม่',
  sum: 'ผลรวม',
  time: 'เวลา',
  quantity: 'ปริมาณ',
  aggregated: 'รวม',
  detailed: 'ละเอียด',
  showNAlarms: 'แสดง <b>{0}</b> อลาร์มที่ยาวที่สุด',
  showNLongest: 'แสดง <b>{0}</b> ที่ยาวที่สุด',
  showOthers: 'แสดงรายการอื่น ๆ',
  hideOthers: 'ซ่อนรายการอื่น ๆ',
  sumOfSmaller: 'ผลรวมของรายการอื่น ๆ',
  dropHereToCreateNew: 'ลากและวางที่นี่เพื่อสร้างแผนภูมิใหม่',
  completedOrders: 'คำสั่งซื้อที่เสร็จสมบูรณ์',
  history: 'ประวัติ',
  usedEnergy: 'พลังงานที่ใช้',
  splitBy: 'แยกตาม',
  addPersonal: 'แดชบอร์ดส่วนตัว',
  addFactory: 'แดชบอร์ดโรงงาน',
  unitConversionNotComplete: 'ไม่สามารถแปลงหน่วยเสร็จสมบูรณ์',
  unitConversionComplete: 'แปลงหน่วยเสร็จสมบูรณ์',
  unableToFullyConvertUnitMessage: 'เราไม่สามารถแปลงการผลิตทั้งหมดเป็น \'{unit}\' ตรวจสอบโครงสร้างการบรรจุของ SKUs ที่ผลิตในช่วงเวลานี้ได้',
  work: 'งาน',
  plannedDowntime: 'วางแผน',
  unplannedDowntime: 'ไม่ได้วางแผน',
  expected: 'คาดว่า',
  rootElement: 'องค์ประกอบหลัก',
  performanceImpact: 'สูญเสียผลประสิทธิภาพ',
  availabilityImpact: 'สูญเสียการพร้อมใช้งาน',
  utilizationImpact: 'สูญเสียการใช้ประโยชน์',
  minorStops: 'หยุดชั่วคราว',
  viewMinorStops: 'แสดงหยุดชั่วคราว',
  minorStopsIncluded: 'หยุดชั่วคราว (รวมในระยะเวลาทั้งหมด)',
  detailedEventsReport: 'รายงานเหตุการณ์ละเอียด',
  siteOeeReport: 'รายงาน OEE ของเว็บไซต์',
  speedLossReport: 'รายงานสูญเสียความเร็ว',
  labourVarianceReport: 'รายงานความแปรผันของแรงงาน',
  siteWeeklyScorecard: 'การ์ดคะแนนสัปดาห์ของเว็บไซต์',
  siteMonthlyScorecard: 'การ์ดคะแนนเดือนของเว็บไซต์',
  exportReport: 'ส่งออกรายงาน',
  excludeUtilizationLoss: 'ยกเว้นการสูญเสียการใช้ประโยชน์',
  decrease: 'ลดลง',
  increase: 'เพิ่มขึ้น',
  oeeLoss: {
    oeeLoss: 'สูญเสีย OEE',
    productionLoss: 'สูญเสียการผลิต',
    undefinedIssue: 'ประเภทของปัญหาไม่ได้กำหนด',
    utilizationLosses: 'การสูญเสียการใช้ประโยชน์',
    availabilityLosses: 'การสูญเสียการพร้อมใช้งาน',
    performanceLosses: 'การสูญเสียผลประสิทธิภาพ',
    performanceStops: 'เวลาหยุดที่สูญเสียผลประสิทธิภาพ',
    qualityLosses: 'การสูญเสียคุณภาพ',
    qualityStops: 'เวลาหยุดที่สูญเสียคุณภาพ',
    speedLoss: 'การสูญเสียความเร็ว',
    qualityLoss: 'การสูญเสียคุณภาพ',
    speedIncrease: 'ความเร็วเพิ่มขึ้น',
    combined: 'รวมกัน',
    byMachine: 'แยกตามเส้นผลิต/เครื่อง',
    byProblem: 'แยกตามปัญหา',
    byState: 'แยกตามสถานะ',
    base: 'เวลาฐาน',
    time: {
      totalTime: 'เวลาทั้งหมด',
      scheduledTime: 'เวลาที่วางกำหนด',
      operatingTime: 'เวลาที่ทำงาน',
      productionTime: 'เวลาการผลิต',
      effectiveTime: 'เวลาที่มีประสิทธิผล',
    },
    calculatedBasedOnTotalAvailabilityLoss: 'คำนวณจากการสูญเสียการพร้อมใช้งานทั้งหมด',
  },
  select: {
    allLines: 'ทุกเส้นผลิต',
    allMachines: 'ทุกเครื่อง',
    selectMachine: 'เลือกเครื่อง',
    selectLine: 'เลือกเส้นผลิต',
    clear: 'ล้าง',
    selectLineAreaOrPlant: 'เลือกโรงงาน เส้นผลิต หรือพื้นที่',
  },
  alarms: {
    description: 'คำอธิบาย',
    numberOfOccurances: 'หมายเลข',
    duration: 'ระยะเวลา',
    sumOfSmallerAlarms: 'ผลรวมของอลาร์มที่เล็กกว่า',
    noDataInThisPeriod: 'ไม่มีข้อมูลในช่วงเวลานี้',
  },
  selectors: {
    amount: 'จำนวน',
    period: 'ระยะเวลา',
    moment: 'ช่วงเวลา',
    dateTo: 'วันที่ถึง',
    dateFrom: 'วันที่จาก',
    shift: 'กะ',
    day: 'วัน',
    week: 'สัปดาห์',
    month: 'เดือน',
    today: 'วันนี้',
    yesterday: 'เมื่อวานนี้',
    thisWeek: 'สัปดาห์นี้',
    previousWeek: 'สัปดาห์ก่อนหน้า',
    thisMonth: 'เดือนนี้',
    previousMonth: 'เดือนก่อนหน้า',
    selectLineAndMachine: 'เลือกเส้นผลิตและเครื่องจักร',
    selectLine: 'เลือกเส้นผลิต',
    selectLineOrMachine: 'เลือกเส้นผลิตหรือเครื่องจักร',
    timeFilter: 'ตัวกรองเวลา',
    lastThreeMonths: '3 เดือนที่ผ่านมา',
    selectExactDates: 'เลือกวันที่แน่นอน',
    selectDatesFromList: 'เลือกวันที่จากรายการ',
    addElement: 'เพิ่มองค์ประกอบโรงงานในรายงาน',
  },
  types: {
    custom: 'กำหนดเอง',
    availability: 'ความพร้อมใช้งาน',
    efficiency: 'ประสิทธิภาพ',
    oee: 'OEE (Overall Equipment Efficiency)',
    oeeLosses: 'ขาดทุนใน OEE',
    oeeDetails: 'รายละเอียด OEE',
    production: 'การผลิต',
    productionMap: 'แผนผลิต',
    downtimes: 'เวลาหยุดทำงาน',
    rootcauses: 'สาเหตุหยุดทำงาน',
    alarms: 'การเตือนภัย',
    column: 'คอลัมน์',
    pie: 'แผนภูมิวงกลม',
    operatorStops: 'หยุดของผู้ปฏิบัติงาน',
    downtimeDuration: 'ระยะเวลาหยุดทำงาน',
    downtimeDurations: 'ระยะเวลาหยุดทำงาน',
    reasons: 'เหตุผล',
    energy: 'พลังงาน',
    energySankey: 'แผนผัง Sankey',
    energyHistory: 'ประวัติการใช้งาน',
    reportsDownload: 'ดาวน์โหลดรายงาน',
    heatmap: 'แผนผังความร้อน',
    commentsFromLosses: 'ความคิดเห็นจากการสูญเสีย',
    typeOfLosses: 'ประเภทของการสูญเสีย',
    downtimeTracking: 'การติดตามเวลาหยุดทำงาน',
    energyTrends: 'แนวโน้ม',
  },
  operatorStop: {
    waiting: 'รอการดำเนินการ',
    fixing: 'ระยะเวลาการดำเนินการ',
    stopsBecauseOf: 'หยุดเพราะ',
    timeSum: 'ระยะเวลาทั้งหมด',
  },
  rootcause: {
    impact: {title: 'ผลกระทบ', desc: '(ระยะเวลาผลกระทบ / ระยะเวลาสาเหตุหลัก)'},
    effectDuration: 'ระยะเวลาผลกระทบ',
    rootcauseDuration: 'ระยะเวลาสาเหตุหลัก'
  },
  chart: {toggleFullView: 'เปิด/ปิดการแสดงรายละเอียดทั้งหมด'},
  chooseEvent: 'เลือกปัญหา',
  needToSelectEvent: 'คุณต้องเลือกปัญหา',
  downtimesPerProducedUnit: 'เวลาหยุดทำงานต่อหน่วยผลิต',
  trackerOptions: 'ตัวเลือกการติดตามเวลาหยุดทำงาน',
  expandCollapseAll: 'รอซวิน/ซวิน วิสซิสโก',
};
