import { render, staticRenderFns } from "./CustomLayoutGrid.vue?vue&type=template&id=4e0c0a58&scoped=true&"
import script from "./CustomLayoutGrid.vue?vue&type=script&lang=js&"
export * from "./CustomLayoutGrid.vue?vue&type=script&lang=js&"
import style0 from "./CustomLayoutGrid.vue?vue&type=style&index=0&id=4e0c0a58&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e0c0a58",
  null
  
)

export default component.exports