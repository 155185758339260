export default {
  'rules': 'Regras',
  'functions': 'Funções de pré-processamento',
  'addRule': 'Adicionar regra',
  'help': 'Ajuda',
  'countingOEE': 'Calculando o OEE',
  'excludeOEE': 'Excluir OEE',
  'thresholds': 'Limites',
  'saved': 'Salvo',
  'baseUnit': 'Unidade base',
  'baseOrderUnit': 'Unidade de ordem padrão',
  'notSelected': 'Não selecionado',
  'saveFailed': 'Falha ao salvar',
  'addMachine': 'Adicionar máquina aos filtros',
  'noCounter': 'A máquina não tem contador',
  'machineBaseUnitSetInCounter': 'A unidade da máquina é determinada pela unidade do contador',
  'shortPerformancePeriod': 'Período',
  'setupIssues': 'Problemas de configuração',
  'noIssuesDetected': 'Nenhum problema de configuração detectado!',
  'test': 'Teste',
  'isDummy': 'Equipamento não monitorado',
  'isDummyInfo': 'Máquinas em teste não são monitoradas e não têm sinais. Elas podem ser marcadas manualmente como a causa raiz de uma parada.',
  'maxTimeBufferOffset': 'Parar o atraso após a máquina anterior',
  'maxTimeBufferOffsetInfo': 'Se a máquina anterior parou de funcionar, quanto tempo é necessário para esta máquina parar?',
  'rootCauseDelay': 'Atraso para causa raiz',
  'rootCauseDelayInfo': 'Quão tarde esta máquina pode quebrar, para outras máquinas ainda apontarem para ela como a causa raiz?',
  'signalNameError': 'O nome do sinal não pode conter "(" and ")" caracteres',
  'rule': {
    'rule': 'Regra',
    'condition': 'Condição',
    'addAndCondition': 'E',
    'showAdvanced': 'Usar editor avançado',
    'info': 'O estado da máquina é determinado com base nas regras abaixo. Quando há uma mudança no valor do sinal,\\n o mecanismo de regras analisará as regras abaixo, começando pela prioridade mais alta.\\n A primeira regra que obtiver \'verdadeiro\' determinará o novo estado',
    'ruleType': 'Tipo de regra',
    'eventType': 'Condições simples',
    'cSharpType': 'Função C#',
    'timeBasedRule': 'Regra baseada em tempo',
    'script': 'Roteiro'
  },
  'events': {'forcedOnly': 'Somente forçados', 'showSystem': 'Mostrar eventos do sistema'},
  'tooltipState': {
    'lack': 'Este estado aparece quando sua máquina não recebe os componentes necessários\\n suficientes para seu trabalho contínuo. O problema pode ser um transportador com defeito, os operadores que não reabastecem os componentes a tempo ou o armazenamento do componente estar muito longe da linha',
    'work': 'Este estado aparece quando sua máquina está funcionando corretamente e o processo de produção está em andamento',
    'inactive': 'Este é um estado neutro que o PackOS usa para sua máquina\\n quando não há falha, mas a máquina não está funcionando',
    'stop': 'Este estado indica que sua máquina foi parada propositalmente por um operador porque eles\\n desejavam, por exemplo, configurá-la ou limpá-la. Quando sua máquina está neste estado, o PackOS mostra o problema\\n que ocorreu antes do estado Interrompido pelo Operador entrar em vigor',
    'failure': 'Este estado indica que sua máquina parou ou não está funcionando corretamente devido a,\\n por exemplo, desgaste mecânico ou erro do operador. Neste caso, a origem do problema é o problema\\n que ocorreu antes de o estado Falha aparecer',
    'off': 'Este estado aparece quando sua máquina é desconectada porque, por exemplo, a energia foi cortada ou a\\n máquina perdeu a conexão com o driver',
    'idle': 'Este estado mostra quando sua máquina não está funcionando, embora não esteja quebrada e não haja nenhum problema \\ n com ela. Neste caso, o PackOS procura a origem do problema verificando a máquina upstream',
    'nolivedata': 'Este é um estado temporário que aparece quando o PackOS perde temporariamente a conexão com a fábrica,\\n por exemplo, devido a problemas de conexão com a Internet. O PackOS mostra este estado apenas temporariamente e rapidamente\\n o altera para outros estados “operacionais”',
    'holdup': 'Este estado aparece quando sua máquina não está funcionando porque sua saída está bloqueada, geralmente por\\n máquinas posteriores. Nesse caso, o PackOS procura a origem do problema verificando a máquina posterior. A origem\\n do problema na máquina posterior é copiada como o estado e o problema para a máquina com o estado Em espera',
    'changeover': 'Este estado aparece quando sua máquina está parada porque a linha de produção ou a máquina está\\n ajustada para um novo processo de produção (set up)'
  },
  'shortPerformance': 'Desempenho instantâneo',
  'shortPerformanceInfo': 'Desempenho instantâneo calculado como um aumento de produção médio recente',
  'value': 'Valor',
  'priority': 'Prioridade',
  'dayOfWork': 'Dia de trabalho',
  'plantConfiguration': 'Configuração da planta',
  'machineSignals': 'Indicadores da máquina',
  'signals': 'Indicadores',
  'signalsExport': 'Exportar Indicadores',
  'logixGateConfigExport': 'Exportar config. LogixGate',
  'signalsImport': 'Importar Indicadores',
  'workEventsImport': 'Importar problemas',
  'csvImport': 'Importar para CSV',
  'csvExport': 'Exportar para CSV',
  'properties': 'Propriedades',
  'signalsAndProperties': 'Indicadores e propriedades',
  'machineLicenses': 'Licenças da máquina',
  'addSignal': 'Adicionar sinal',
  'addProperty': 'Adicionar propriedade',
  'electricity': 'Eletricidade',
  'electricityBaseline': 'Linha de base de eletricidade',
  'electricityMax': 'Média máx. de eletricidade',
  'shiftDefinitions': 'Definições de turno',
  'info': 'Informações',
  'deleteInfo': 'Pressione o botão \'Salvar\' para excluir',
  'timezone': 'Fuso horário',
  'yourCurrent': 'Seu atual',
  'differentTimezoneInfo': 'Você está em um fuso horário diferente da fábrica!',
  'differentTimezoneSettingsInfo': 'As configurações abaixo serão aplicadas ao fuso horário de fábrica',
  'differentTimezoneSelectorInfo': 'Exibiremos a hora conforme o seu fuso horário',
  'wasteBaseDiffCounter': 'Máquina da base para calcular o resíduo diferencial',
  'disableBufferResetBetweenOrders': 'Desabilitar buffer setado entre ordens',
  'workingHours': 'Jornada de trabalho',
  'addNewShift': 'Adicionar novo turno',
  'productionExpectations': 'Alvos da produção',
  'doYouWantToDelete': 'Você deseja excluir?',
  'newShift': 'Novo turno',
  'editShift': 'Editar turno',
  'removeShift': 'Remover turno',
  'users': 'Usuários',
  'nothingToAdd': 'Nada para adicionar',
  'attachedLinesAndMachines': 'Linhas e máquinas anexadas',
  'addLineOrMachine': 'Adicionar linha / máquina',
  'layout': 'Layout',
  'saveLayout': 'Salvar layout',
  'elementsOrder': 'Ordem dos elementos',
  'advanced': 'Avançado',
  'default': 'Padrão',
  'noLayoutLine': 'A linha não está definida no layout da planta',
  'units': 'Unidades',
  'unitCode': 'Código da unidade',
  'isBaseUnit': 'Base Is',
  'fromUnit': 'Child UoM',
  'toUnit': 'Parent UoM',
  'multiplier': 'Multiplicador',
  'shortName': 'Abreviação',
  'blockCard': 'Bloquear cartão',
  'activeSince': 'Ativo desde',
  'production': 'Produção',
  'card': 'Cartão',
  'cardId': 'Identificador do cartão',
  'cardPin': 'PIN do cartão',
  'oldPin': 'PIN anterior',
  'newPin': 'Novo PIN',
  'pinChange': 'Alterar o PIN',
  'pinLengthError': 'O PIN deve ter exatamente 4 números',
  'cardIdLengthError': 'O identificador do cartão deve ter no mínimo 4 caracteres',
  'connections': 'Fluxos',
  'displaySettings': 'Mostrar',
  'adminPlantSettings': 'Configurações da planta do administrador',
  'orderAutostartThreshold': 'Início automático do pedido',
  'forcedStateThreshold': 'Término do tempo de inatividade forçado',
  'downtimeThreshold': 'Término do tempo de inatividade em curso',
  'minorStopThreshold': 'Limite de uma pequena parada',
  'designPerformanceInfo': 'O desempenho da máquina que pode ser alcançado\\n de acordo com o fabricante',
  'validatedPerformanceInfo': 'Desempenho da máquina que foi\\n medido e validado em condições reais',
  'orderAutostartThresholdInfo': 'Limite que define a quantidade de\\n produção necessária para automaticamente iniciar um pedido',
  'forcedStateThresholdInfo': 'Limite que define a quantidade de\\n produção necessária para automaticamente finalizar o tempo de inatividade forçado',
  'downtimeThresholdInfo': 'Limite que define a quantidade de\\n produção necessária para automaticamente finalizar o tempo de inatividade em andamento automaticamente',
  'expectations': {
    'expectedOee': 'OEE esperado',
    'expectedPerformance': 'Desempenho esperado',
    'maxExpectedFailures': 'Número máximo de falhas esperado',
    'validatedPerformance': 'Desempenho validado',
    'designPerformance': 'Desempenho nominal'
  },
  'automation': {
    'automation': 'Automação',
    'add': 'Adicionar automação',
    'type': {
      'CreateQuestOnWorkEvent': {
        'title': 'Criar tarefa',
        'description': 'Criar tarefa quando houver este tipo de problema na máquina'
      },
      'CreateAndCloseQuestOnWorkEvent': {
        'title': 'Criar e fechar tarefa',
        'description': 'Crie uma tarefa quando este tipo de problema ocorrer na máquina e feche-a automaticamente quando o problema parar'
      }
    }
  },
  'unit': {
    'usedInPackageStructure': 'A remoção da unidade é impossível, ela é usada em uma definição de estrutura de pacote',
    'fieldsNotValid': 'Formato de campo incorreto'
  },
  'function': {
    'sessionName': 'Nome da sessão',
    'sessionInfo': 'Funções que usam o mesmo nome de sessão serão executadas sequencialmente (ao invés de simultaneamente)',
    'run': 'Executar',
    'testValue': 'Valor de teste',
    'testResult': 'Resultado do teste',
    'triggers': 'Gatilhos',
    'script': 'Roteiro',
    'isStateful': 'Armazenar dados',
    'sequenceProcessing': 'Processe as seguintes tags sequencialmente',
    'newFunction': 'Adicionar função',
    'editFunction': 'Função de edição'
  },
  'setUrl': 'Salvar novo URL',
  'authUrl': 'Endereço de URL de autenticação',
  'loadedOnNextLogin': 'Será carregado após o próximo login',
  'operatorCockpit': 'Painel de controle operacional',
  'weekStart': 'Início da semana',
  'cockpitType': 'Tipo de painel de controle',
  'cockpitLayout': 'Layout do painel de controle',
  'maxPerformance': 'Desempenho instantâneo máximo',
  'maxPerformanceInfo': 'Usado na contra guarda, para validar se o novo valor do contador é viável (0 = guarda desabilitada)"?',
  'differentialWaste': 'Desperdício diferencial',
  'calculateEnergyFromMachines': 'Calcular energia dos equipamentos',
  'taxonomy': {'taxonomy': 'Taxonomia'},
  'sku': {
    'expectedEffort': 'Esforço esperado (HH)',
    'skuDescriptionTooltip': 'Caso seja deixado em branco, a descrição do SKU será usada'
  },
  'energy': 'Energia',
  'allTypes': 'Todos os tipos'
};
