var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-widget",class:{
    'small': !_vm.wide,
  }},[_c('div',{staticClass:"px-3 w-100"},[_c('div',{staticClass:"progress w-100"},[_c('div',{staticClass:"progress-bar",style:({
          width: `${_vm.progressPercentage}%`,
        }),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progressPercentage)+"% ")])])]),_c('div',{staticClass:"row align-items-center w-100 mt-2 no-gutters pt-1"},[_c('div',{class:{
        'col-6': _vm.wide,
        'col-5': !_vm.wide,
      }},[_c('div',{staticClass:"title text-ellipsis w-100 pl-3 pr-1 text-left"},[_vm._v(" "+_vm._s(_vm.rawDetails.title || '-')+" ")])]),_c('div',{staticClass:"text-right",class:{
        'col-6': _vm.wide,
        'col-7': !_vm.wide,
      }},[_c('div',{staticClass:"value text-ellipsis w-100 pr-3 pl-1 text-right"},[_c('span',[(_vm.value.type === _vm.customLayoutWidgetFieldType.static)?_c('span',[_vm._v(" "+_vm._s(_vm.value.value || '-')+" ")]):_vm._e(),(_vm.value.type === _vm.customLayoutWidgetFieldType.tag)?_c('span',[_vm._v(" "+_vm._s(_vm.getTagValueForTag(_vm.value.value))+" ")]):_vm._e()]),_c('span',{staticClass:"max-value"},[_c('span',[(_vm.maxValue.type === _vm.customLayoutWidgetFieldType.static)?_c('span',[_vm._v("/"+_vm._s(_vm.maxValue.value || '-')+" ")]):_vm._e(),(_vm.maxValue.type === _vm.customLayoutWidgetFieldType.tag)?_c('span',[_vm._v("/"+_vm._s(_vm.getTagValueForTag(_vm.maxValue.value))+" ")]):_vm._e()]),_c('span',{staticClass:"unit"},[(_vm.unit.type === _vm.customLayoutWidgetFieldType.static)?_c('span',[_vm._v(" "+_vm._s(_vm.unit.value || '-')+" ")]):_vm._e(),(_vm.unit.type === _vm.customLayoutWidgetFieldType.tag)?_c('span',[_vm._v(" "+_vm._s(_vm.getTagValueForTag(_vm.unit.value))+" ")]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }