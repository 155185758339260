<template>
  <div
    class="progress-bar-widget"
    :class="{
      'small': !wide,
    }"
  >
    <div class="px-3 w-100">
      <div class="progress w-100">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{
            width: `${progressPercentage}%`,
          }"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ progressPercentage }}%
        </div>
      </div>
    </div>

    <div class="row align-items-center w-100 mt-2 no-gutters pt-1">
      <div
        :class="{
          'col-6': wide,
          'col-5': !wide,
        }"
      >
        <div class="title text-ellipsis w-100 pl-3 pr-1 text-left">
          {{ rawDetails.title || '-' }}
        </div>
      </div>
      <div
        class="text-right"
        :class="{
          'col-6': wide,
          'col-7': !wide,
        }"
      >
        <div class="value text-ellipsis w-100 pr-3 pl-1 text-right">
          <span>
            <span v-if="value.type === customLayoutWidgetFieldType.static">
              {{ value.value || '-' }}
            </span>
            <span v-if="value.type === customLayoutWidgetFieldType.tag">
              {{ getTagValueForTag(value.value) }}
            </span>
          </span>

          <span class="max-value">
            <span>
              <span
                v-if="maxValue.type === customLayoutWidgetFieldType.static"
              >/{{ maxValue.value || '-' }}
              </span>
              <span
                v-if="maxValue.type === customLayoutWidgetFieldType.tag"
              >/{{ getTagValueForTag(maxValue.value) }}
              </span>
            </span>
            <span class="unit">
              <span v-if="unit.type === customLayoutWidgetFieldType.static">
                {{ unit.value || '-' }}
              </span>
              <span v-if="unit.type === customLayoutWidgetFieldType.tag">
                {{ getTagValueForTag(unit.value) }}
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { customLayoutWidgetFieldType } from '@/components/customLayout/customLayoutDictionary';

export default {
  name: 'CustomLayoutProgressBarWidget',
  props: {
    value: Object,
    unit: Object,
    maxValue: Object,
    rawDetails: Object,

    currentTags: Array,
  },
  components: {},
  computed: {
    customLayoutWidgetFieldType() {
      return customLayoutWidgetFieldType;
    },
    progressPercentageMax() {
      return this.maxValue.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.maxValue.value)
        : this.maxValue.value;
    },
    progressPercentage() {
      const top = Number(this.progressPercentageMax) || 0;
      const value = this.value.type === customLayoutWidgetFieldType.tag
        ? this.getTagValueForTag(this.value.value)
        : this.value.value;
      const v = Number(value) || 0;
      return Math.round(((v / top) * 100) * 10) / 10;
    },
    wide() {
      return this.rawDetails.size.w > 2;
    },
  },
  methods: {
    getTagValueForTag(tag) {
      return this.currentTags.find(t => t.nodeId === tag)?.value || '-';
    },
  },
};
</script>

<style scoped lang="scss">
.progress-bar-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.small {
    .title {
      font-size: 11px;
    }

    .value {
      font-size: 14px;
      font-weight: 600;
    }

    .max-value, .unit {
      font-size: 11px;
      font-weight: 400;
      color: #A7A7A7;
    }
  }

  .title {
    font-size: 12px;
  }

  .value {
    font-size: 18px;
    font-weight: 600;
  }

  .max-value {
    margin-left: -4px;
  }

  .max-value, .unit {
    font-size: 12px;
    font-weight: 400;
    color: #A7A7A7;
  }
}
</style>
