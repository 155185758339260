<template>
  <div
    class="d-flex flex-column"
    style="min-height: 100%; max-width: 100%"
  >
    <div class="row justify-content-between">
      <div class="col-lg-12 col-xl-3">
        <DateScopeDropdown />
      </div>

      <div class="col-lg-12 col-xl-6">
        <PlantBreadcrumb
          breadcrumb="plant"
          class="mt-2"
        />
        <SubPageSelector
          :list="routes"
          :selected="selected"
          class="mt-3"
          @selected="changePage"
        />
      </div>

      <div class="col-lg-12 col-xl-3" />
    </div>

    <RouterView name="header" />

    <RouterView
      class="flex-grow-1"
      style="margin-bottom: 50px"
      @pageChange="selected = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlantBreadcrumb from '@/layout/dashboard/PlantBreadcrumb';
import DateScopeDropdown from '@/layout/DateScopeDropdown';
import SubPageSelector from '@/layout/dashboard/selectors/SubPageSelector';

export default {
  data: () => ({
    selected: null,
  }),
  components: {
    SubPageSelector,
    DateScopeDropdown,
    PlantBreadcrumb,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', ['plantGraphs']),
    routes() {
      const { plantId } = this;
      return [
        {
          key: 'lines',
          name: this.$t('lines'),
          route: `/${plantId}/dashboard/lines`,
          icon: 'ion ion-ios-git-commit',
        },
        {
          key: 'overview',
          name: this.$t('menu.overview'),
          route: `/${plantId}/dashboard/overview`,
          icon: 'ion ion-md-apps',
        },
        {
          key: 'customLayout',
          name: this.$t('menu.customLayout'),
          route: `/${plantId}/dashboard/custom-layout`,
          icon: 'fas fa-brush',
        },
        {
          key: 'map',
          name: this.$t('map'),
          route: `/${plantId}/dashboard/map`,
          icon: 'ion ion-ios-map',
          hide: !this.hasMapGraph,
        },
        {
          key: 'flows',
          name: this.$t('graph.flows'),
          route: `/${plantId}/dashboard/flows`,
          icon: 'ion ion-ios-git-pull-request',
          hide: !this.hasSupplyingFlows,
        },
      ].filter(x => !x.hide);
    },
    hasSupplyingFlows() {
      if (!this.plantGraphs) return false;
      return this.plantGraphs.filter(({ type }) => type === 'Supplying').length > 0;
    },
    hasMapGraph() {
      if (!this.plantGraphs) return false;
      return this.plantGraphs.filter(({ type }) => type === 'Physical').length > 0;
    },
  },
  methods: {
    changePage({ key, route }) {
      if (key === this.selected) return;

      this.$router.push(route);
      this.selected = key;
    },
  },
  created() {
    this.$emit('page', 'monitoring');
  },
};
</script>

<style scoped>

</style>
