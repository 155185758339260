export default {
  'originalDates': 'Ursprüngliche Daten',
  'originalDuration': 'Ursprüngliche Dauer',
  'newDates': 'Neue Daten',
  'newDuration': 'Neue Dauer',
  'firstDowntime': '1. Ausfallzeit',
  'secondDowntime': '2. Ausfallzeit',
  'shorten': 'kürzen',
  'split': 'aufteilen',
  'thisMachine': 'diese Anlage',
  'addComment': 'Füge Kommentar hinzu',
  'noComments': 'Keine Kommentare',
  'downtimeSummary': 'Stilstandzeit Zusammenfassung',
  'step': 'Schritt {Schritt}',
  'selectRootLineMachine': 'Ort einer Grundursache auswählen',
  'selectLineMachine': 'Linie/Anlage auswählen',
  'selectState': 'Status auswählen',
  'selectProblem': 'Problem auswählen',
  'specifyAReason': 'Ursache spezifizieren',
  'correctiveActionTaken': 'Korrekturmaßnahmen ergriffen',
  'downtimeDuration': 'Stillstandzeit Dauer',
  'vision': 'Vision',
  'oeeLossType': 'OEE Verlust Typ',
  'table': {
    'downtime': 'Stillstandzeit',
    'details': 'Details',
    'time': 'Zeit',
    'duration': 'Dauer'
  },
  'noSharedComments': 'Keine geteilten Kommentare'
};
