<template>
  <div>
    <div v-if="!showResources">
      <div
        v-if="!hideHeader"
        class="mt-2 mx-auto"
        style="max-width: 600px;"
      >
        <Order
          v-bind="{ ...order }"
          :hoverable="false"
          :show-button="true"
        />
      </div>
      <hr>
      <div class="p-2">
        <div class="d-flex justify-content-center pb-2">
          <HorizontalMenu
            :list="orderReports"
            :selected="selectedReport"
            :small="true"
            @update:selected="selectedReport = $event"
          />
        </div>
        <div
          v-if="selectedReport === 'production'"
          style="overflow: hidden; position: relative"
        >
          <ProductionModal
            :element-id="order.lineId"
            :check="{
              wasted: true,
              planned: true,
              produced: true,
            }"
            :start="startDateUnix"
            :end="endDateUnix"
            :height="180"
          />
          <OrderCounterSummary
            :summary="summary"
            :order-id="order.id"
          />
          <ManualResourceEntries
            v-if="!productionConfirmation"
            :history-pending="pending"
            :activity-id="order.id"
            :activity-start="startDate"
            :entries="entries"
            :resource-type="selectedReport"
            class="my-4"
            @removed="refresh"
          />

          <div
            v-tippy="{
              arrow: true,
              trigger: orderFullyConfirmed ? 'mouseenter' : 'manual'
            }"
            style="width: 20%"
            :content="$t('orders.removeLastEntryFirst')"
          >
            <button
              class="px-2 btn btn-block btn-outline-secondary btn-sm"
              type="button"
              :disabled="orderFullyConfirmed"
              @click="productionConfirmation = true"
            >
              <i class="far fa-clipboard pr-2" />
              {{ $t('activities.actions.productionReconciliation') }}
            </button>
          </div>
          <div v-if="productionConfirmation">
            <Error
              v-if="productionError"
              class="my-2"
              :message="productionError"
            />
            <Loader v-if="productionPending" />
            <ActivityResources
              :order="order"
              :activity-id="order.id"
              :manual-summary="true"
              :scope-start="order.actualExecution.begin"
              :line-id="order.lineId"
              :activity-type="order.type"
              :order-unit="order.orderedQuantity.unit"
              :action-name="$t('general.confirm')"
              :disabled="productionPending"
              :order-production="summary ? summary.orderGoodCount : 0"
              :order-waste="summary ? summary.orderWasteCount : 0"
              :order-finish="productionConfirmation && order.status !== 'Completed'"
              @cancel="productionConfirmation = false"
              @hide="productionConfirmation = false"
              @complete="updateTotalProduction"
              @prodDone="done = $event"
              @prodWasted="wasted = $event"
              @refresh="refresh"
            />
          </div>
        </div>
        <WorklogModal
          v-else-if="selectedReport === 'worklog'"
          :element-id="order.lineId"
          :hide-time-selector="true"
          :hide-actions="true"
          :start="startDateUnix"
          :end="endDateUnix"
          :order-id="order.id"
        />
        <div v-else-if="selectedReport === 'manhours'">
          <ManhoursSummary
            :activity-id="order.id"
            :scope-start="startDate"
            class="pt-4 my-4"
            @cancel="showResources = false"
          />
          <div style="width: 20%">
            <button
              class="px-2 btn btn-block btn-outline-secondary btn-sm"
              type="button"
              @click="openResourcesForm(true)"
            >
              <i class="far fa-file-alt pr-2" />
              {{ $t('orders.reportResources') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <LModal
      :show.sync="showResources"
      size="xl"
    >
      <ActivityResources
        :order="order"
        :line-id="order.lineId"
        :activity-id="order.id"
        :scope-start="startDate"
        :scope-end="endDate"
        :activity-type="order.type"
        :order-unit="order.orderedQuantity ? order.orderedQuantity.unit : ''"
        :order-production="orderProduction"
        :order-waste="orderWaste"
        :human-resources="humanResources"
        class="pt-4"
        @cancel="showResources = false"
        @refresh="refresh()"
      />
    </LModal>
  </div>
</template>

<script>
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import { mapActions, mapGetters } from 'vuex';
import {
  PACKOS_ORDER_COMPLETED_MANUALPRODUCTION,
  PACKOS_ORDER_MANAGEMENT,
  PACKOS_PLANT_ADMIN,
} from '@core/dict/permissions';
import moment from 'moment';
import Order from '@/components/order/single/Order';
import ProductionModal from '@/components/modals/ProductionModal';
import WorklogModal from '@/components/modals/WorklogModal';
import ManualResourceEntries from '@/components/activity/resources/ManualResourceEntries';
import ActivityResources from '@/components/activity/ActivityResources';
import ManhoursSummary from '@/components/activity/resources/ManhoursSummary';
import OrderCounterSummary from '@/components/order/OrderCounterSummary';
import { manualResourceType } from '@/utils/dictionary';

export default {
  props: {
    order: Object,
    hideHeader: Boolean,
  },
  data() {
    return {
      orderReports: [],
      selectedReport: 'production',
      entries: [],
      showResources: false,
      pending: false,
      humanResources: false,
      summary: null,
      productionConfirmation: false,
      productionPending: false,
      productionError: null,
      done: 0,
      wasted: 0,
    };
  },
  components: {
    ProductionModal,
    WorklogModal,
    Order,
    HorizontalMenu,
    ManualResourceEntries,
    ActivityResources,
    ManhoursSummary,
    OrderCounterSummary,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('core', ['can']),
    ...mapGetters('plant', ['displayLabour']),
    startDateUnix() {
      return moment(this.startDate).unix();
    },
    endDateUnix() {
      return moment(this.endDate).unix();
    },
    orderFullyConfirmed() {
      if (!this.order.actualExecution?.end) return false;

      const orderEnd = moment(this.order.actualExecution.end);
      const prodEntries = this.entries
        .filter(x => x.isActive && x.type === manualResourceType.production)
        .filter(x => moment(x.to).add(1, 'minute').isAfter(orderEnd));

      return prodEntries.length > 0;
    },
    startDate() {
      return this.order.actualExecution?.begin;
    },
    endDate() {
      return this.order.actualExecution?.end;
    },
    orderProduction() {
      return this.order.producedQuantity?.value;
    },
    orderWaste() {
      return this.order.wastedQuantity?.value;
    },
    enableProductionUpdatesDelete() {
      return this.can(PACKOS_PLANT_ADMIN, this.plantId)
        || this.can(PACKOS_ORDER_MANAGEMENT, this.plantId)
        || this.can(PACKOS_ORDER_COMPLETED_MANUALPRODUCTION, this.plantId);
    },
  },
  watch: {
    showResources() {
      this.refresh();
    },
  },
  methods: {
    ...mapActions([
      'getActivityManualProduction',
      'getOrderCountersSummary',
      'addOrderManualProduction',
    ]),
    refresh() {
      this.pending = true;
      this.requestOrderSummary();

      this.getActivityManualProduction({
        params: {
          plantId: this.plantId,
          activityId: this.order.id,
        },
      })
        .then(({ data }) => {
          this.entries = data.filter(ent => ent.isActive === true);
        })
        .catch(({ response: { data } }) => {
          this.error = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    requestOrderSummary() {
      return this.getOrderCountersSummary({
        params: {
          plantId: this.plantId,
          query: {
            orderId: this.order.id,
          },
        },
      })
        .then(({ data }) => {
          this.summary = data;
        });
    },
    openResourcesForm(humanResources) {
      this.humanResources = humanResources;
      this.showResources = true;
    },
    updateTotalProduction() {
      this.productionPending = true;
      this.productionError = null;

      this.addOrderManualProduction({
        params: {
          plantId: this.plantId,
          orderId: this.order.id,
        },
        data: {
          done: this.done,
          wasted: this.wasted,
          to: moment(this.order.actualExecution.end).toISOString(),
          unit: this.order.orderedQuantity?.unit || '',
          incremental: false,
        },
      })
        .then(() => {
          this.productionConfirmation = false;
          this.productionPending = false;
          this.refresh();
        })
        .catch(({ response: { data } }) => {
          this.productionError = data?.detail || this.$t('error.error');
          this.productionPending = false;
        });
    },
    fillMenuItems() {
      let menuItems = [{
        key: 'production',
        name: this.$t('monitoring.production'),
        icon: 'ion ion-md-clipboard',
      }];
      if (this.displayLabour) {
        menuItems = [
          ...menuItems,
          { key: 'manhours', name: this.$t('general.manHours'), icon: 'fas fa-male' },
        ];
      }
      menuItems = [
        ...menuItems,
        { key: 'worklog', name: this.$t('monitoring.workLog'), icon: 'icon-downtime' },
        { key: 'components', name: this.$t('components.title'), icon: 'icon-components' },
      ];

      this.orderReports = [...menuItems];
    },
  },
  created() {
    this.fillMenuItems();
    this.refresh();
  },
};
</script>

<style>

</style>
