export default {
  'orders': 'Pedidos',
  'order': 'Pedido',
  'import': 'Importar pedidos',
  'completedOrders': 'Pedidos concluídos',
  'inProgressOrders': 'Pedidos em andamento',
  'start': 'Iniciar',
  'saveAndActivate': 'Salvar e ativar',
  'orderDetails': 'Detalhes do pedido',
  'activate': 'Ativar',
  'stop': 'Parar',
  'end': 'Término',
  'show': 'Mostrar pedido',
  'startOrder': 'Iniciar pedido',
  'reportResources': 'Recursos do relatório',
  'activateOrder': 'Ativar pedido',
  'abortOrderActivate': 'Abortar ativação',
  'selectOrder': 'Selecionar pedido',
  'selectLineAndSku': 'Selecionar linha e SKU',
  'selectSku': 'Selecione SKU',
  'skuPerformance': 'Desempenho do SKU',
  'abort': 'Abortar',
  'status': 'Status',
  'number': 'Número',
  'orderNumber': 'Número do pedido',
  'skuId': 'Identificador do SKU',
  'orderId': 'Identificador do pedido',
  'item': 'Item',
  'sku': 'SKU',
  'itemNo': 'Número do item',
  'schedule': 'Agendar',
  'scheduled': 'Agendado',
  'plannedProduction': 'Produção planejada',
  'realisation': 'Realização',
  'expectedStart': 'Início esperado',
  'expectedEnd': 'Fim esperado',
  'toPlannedEnd': 'para o fim planejado',
  'toPlannedStart': 'para início planejado',
  'afterPlannedEnd': 'após o fim planejado',
  'afterPlannedStart': 'após o início planejado',
  'expectedPerformance': 'Desempenho esperado',
  'description': 'Descrição',
  'quantity': 'Produzido',
  'limitExceeded': 'Limite de pedidos excedido, pare ou conclua todos os pedidos em execução',
  'production': 'Resumo da produção',
  'wasted': 'Desperdiçado',
  'updateProduction': 'Produção Atualizada',
  'totalProduction': 'Produção total',
  'incrementalProduction': 'Produção incremental',
  'productionUpdates': 'Atualizações da produção',
  'complete': 'Concluir',
  'hold': 'Espera',
  'completed': 'Concluído',
  'planned': 'Planejado',
  'lineConfig': 'Configuração da linha',
  'config': 'Configuração',
  'additional': 'Adicional',
  'machinesForOrder': 'Selecionar máquinas para este trabalho',
  'requiredComponentsNotScanned': 'Alguns componentes precisam ser digitalizados primeiro',
  'firstActivate': 'Alguns componentes são necessários antes de começar. Ative o pedido e digitalize os componentes primeiro',
  'productionStats': 'Produção',
  'comment': {
    'startOrder': 'O pedido iniciará imediatamente. Todos os pacotes digitalizados serão ativados',
    'activateOrder': 'O pedido será preparado para o início, o fluxo ainda pode ser alterado e alguns pacotes precisarão ser escaneados antes do início',
    'completeOrder': 'O pedido será concluído e não poderá ser iniciado novamente',
    'stopOrder': 'O pedido será interrompido e poderá ser reiniciado mais tarde',
    'abortOrderActivate': 'A ativação será abortada, o status do pedido retornará para \'Pronto\''
  },
  'states': {
    'inProgress': 'Em andamento',
    'ready': 'Esperando',
    'completed': 'Concluído',
    'other': 'Outro',
    'activated': 'Ativado',
    'onHold': 'Em espera'
  },
  'activities': {
    'addActivity': 'Planejar nova atividade',
    'editActivity': 'Editar atividade',
    'changeover': 'Setup',
    'stop': 'Interrompido pelo operador',
    'failure': 'Falha',
    'lack': 'Falta de componentes ou produto',
    'order': 'Pedido',
    'idle': 'Ocioso',
    'holdup': 'Em espera',
    'inactive': 'Inativo',
    'cip': 'CIP',
    'completedJob': 'Trabalho concluído',
    'inProgressJob': 'Trabalho em andamento'
  },
  'add': 'Adicionar pedido',
  'create': 'Criar pedido',
  'new': 'Novo pedido',
  'edit': 'Editar pedido',
  'orderedQuantity': 'Quantidade planejada',
  'typeCorrectQuantity': 'A quantidade planejada deve ser maior que zero',
  'quantityUnit': 'Unidade de quantidade',
  'type': 'Tipo',
  'loadingItems': 'Carregando itens ..',
  'manage': 'Gerenciar pedidos',
  'noActiveOrPlannedOrders': 'Nenhum pedido ativo ou planejado',
  'noOrders': 'Nenhum pedido',
  'orderStarted': 'Pedido iniciado',
  'productionEnteredManually': 'Produção e desperdício confirmados manualmente',
  'manualProductionAdded': 'Produção manual adicionada',
  'manualProductionOverwritten': 'Produção manual substituída',
  'orderHistory': 'Histórico de ordens',
  'removeLastEntryFirst': 'Remover a última declaração de produção antes de confirmar a ordem de produção novamente',
  'confirmCurrentProduction': 'Confirmar produção',
  'skuDescription': 'Descrição do SKU',
  'skuNumber': 'Número do SKU',
  'settlement': 'Consolidado',
  'noProducts': 'Sem SKU',
  'release': 'Liberar',
  'finishOrder': 'Finalizar pedido',
  'product': 'Produto',
  'plannedQuantity': 'Quantidade planejada',
  'defaultConfiguration': 'Configuração padrão'
};
