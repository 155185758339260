import datatables from './datatables';
import error from './error';
import orders from './orders';
import reports from './reports';
import notify from './notify';
import downtimeSelector from './downtimeSelector';
import settings from './settings';
import activities from './activities';
import downtimes from './downtimes';

export default {
  datatables,
  error,
  orders,
  downtimeSelector,
  reports,
  notify,
  settings,
  activities,
  downtimes,

  'world': 'Mundo',
  'plant': 'Fábrica',
  'area': 'Área',
  'areas': 'Áreas',
  'line': 'Linha',
  'lines': 'Linhas',
  'delete': 'Excluir',
  'segment': 'Segmento',
  'machine': 'Máquina',
  'machines': 'Máquinas',
  'element': 'Elemento',
  'map': 'Mapa',
  'issue': 'Problema',
  'typeOfIssue': 'Tipo de problema',
  'currentShift': 'Turno atual',
  'noShift': 'Nenhum turno',
  'currentOrder': 'Pedido atual',
  'currentWeek': 'Esta semana',
  'today': 'Hoje',
  'shift': 'Turno',
  'shifts': 'Turnos',
  'noShifts': 'Nenhum turno atribuído',
  'asset': {
    'globalCounter': 'Contador global',
    'localCounter': 'Contador local',
    'waterCounter': 'Contador de água',
    'valvePosition': 'Posição da válvula',
    'waterLevel': 'Nível de água',
    'waterFlow': 'Fluxo de água',
    'pumpBalance': 'Equilíbrio da bomba'
  },
  'wall': {
    'comment': 'Comentar',
    'comments': 'Comentários',
    'vision': 'Visão',
    'downtimeEditorSettings': 'Configurações do editor de inatividade'
  },
  'menu': {
    'monitoring': 'Monitoramento',
    'reports': 'Relatórios',
    'overview': 'Visão geral',
    'andon': 'Andon',
    'weeklyAnalysis': 'Análise semanal',
    'scoreAnalysis': 'Análise de pontuação',
    'settings': 'Configurações',
    'userSettings': 'Configurações da conta',
    'notifications': 'Notificações',
    'language': 'Idioma',
    'user': 'Usuário',
    'setAsHome': 'Definir como página inicial',
    'setFullScreen': 'Tela inteira',
    'logout': 'Sair',
    'loadMore': 'Carregar mais',
    'selectPlant': 'Selecionar planta',
    'activities': 'Atividades',
    'login': 'Conecte-se',
    'preview': 'Visualização',
    'lineGraph': 'Gráfico de linha',
    'homePageDevice': {
      'title': 'Página inicial para este dispositivo',
      'description': 'Esta tela será exibida sempre após o seu login neste dispositivo'
    },
    'homePageUser': {
      'title': 'Página inicial da sua conta',
      'description': 'Caso o dispositivo não tenha página inicial, esta tela será exibida após o seu login'
    },
    'machineGraph': 'Gráfico da máquina',
    'version': 'Versão'
  },
  'import': {
    'upload': 'Solte os arquivos aqui para enviar',
    'notSaved': 'Não salvo',
    'saved': 'Salvo',
    'dataSaved': 'Dado salvo corretamente',
    'actionRequired': 'Ação requerida',
    'checked': 'Verificado',
    'fillAndAdd': 'Preencher e adicionar',
    'chooseImage': 'Escolher a imagem',
    'nothingImported': 'Nenhum dado válido para importar',
    'previousImports': 'Importações anteriores',
    'fileIsBeingProcessed': 'O arquivo está sendo processado. Enviaremos uma notificação quando estiver pronto',
    'groupSuccessfullyAssign': 'Atribuído ao grupo com êxito',
    'groupFailedToAssign': 'Falha ao atribuir ao grupo',
    'groupSuccessfullyRemoved': 'Removido do grupo com êxito',
    'groupFailedToRemove': 'Falha ao remover do grupo',
    'noChanges': 'Sem alterações',
    'multiplierIsInvalid': 'O multiplicador está em conflito com a conversão já definida',
    'multiplierIsLessOrEqualZero': 'Multiplicador não pode ser menor ou igual a zero',
    'seeTemplates': 'Ver Modelos',
    'fileReview': 'Revisão de arquivo',
    'templates': 'Modelos',
    'addTemplate': 'Adicionar modelo',
    'editTemplate': 'Editar modelo',
    'import': 'Importar',
    'uploadFile': 'Carregar arquivo',
    'template': 'Modelo',
    'toUploadFilePickTemplate': 'Para carregar o arquivo, escolha o modelo',
    'result': 'Resultado',
    'testFile': 'Arquivo de teste',
    'toSeeResultUploadTestFile': 'Para ver o resultado, carregue um arquivo de teste',
    'toTestFileSaveChanges': 'Para testar o arquivo, salve as alterações',
    'visible': 'Visível',
    'saveFunction': 'Função Salvar',
    'key': 'Chave',
    'value': 'Valor',
    'oldValue': 'Valor Antigo',
    'newValue': 'Novo valor',
    'unchanged': 'Inalterado',
    'status': {
      'applied': 'Êxito',
      'appliedWithErrors': 'Aplicado com erros',
      'scheduled': 'Agendado',
      'faulted': 'Com defeito',
      'processing': 'Processamento'
    }
  },
  'work': {
    'event': 'Problema',
    'events': 'Problemas',
    'state': 'Estado',
    'states': 'Estados',
    'systemStates': 'Estados do sistema',
    'reason': 'Motivo',
    'reasons': 'Motivos',
    'correctiveAction': 'Ação corretiva',
    'correctiveActions': 'Ações corretivas',
    'downtime': 'Tempo de inatividade',
    'downtimes': 'Tempos de inatividade',
    'wiki': 'Artigos wiki',
    'disconnected': 'Desconectado',
    'rootCause': 'Causa raiz',
    'detachRootCause': 'Desanexar a causa raiz',
    'rootMachine': 'Máquina da causa raiz',
    'spectrum': 'Espectro de trabalho',
    'downtimeList': 'Lista de inatividade',
    'default': 'Padrão',
    'tags': 'Tags',
    'addTags': 'Adicionar tags',
    'addSelected': 'Adicionar selecionado',
    'plannedDowntimes': 'Tempos de inatividade planejados',
    'unplannedDowntimes': 'Tempos de inatividade não planejados',
    'workTime': 'Expediente',
    'actionTaken': 'Ação corretiva tomada',
    'noOngoingOrder': 'Nenhum pedido em andamento',
    'noOngoingShift': 'Nenhum turno em andamento',
    'updateQueued': 'Atualização de inatividade em andamento. Isso pode demorar alguns segundos',
    'overrideQueued': 'Inserção de tempo de inatividade na fila. Isso pode demorar alguns segundos',
    'noForcedState': 'Sem problemas definidos com a tag \'Forced\'. Adicione uma definição de tempo de inatividade com esta tag, para parar a máquina ou linha',
    'downtimeSince': 'Tempo de inatividade desde',
    'lasting': 'durando'
  },
  'general': {
    'name': 'Nome',
    'from': 'A partir',
    'search': 'Pesquisar',
    'max': 'Máx.',
    'min': 'Min',
    'noResults': 'Nenhum resultado',
    'title': 'Título',
    'description': 'Descrição',
    'fromStart': 'do começo',
    'confirm': 'Confirmar',
    'tryAgain': 'Tente novamente',
    'to': 'Para',
    'toEnd': 'para término',
    'finish': 'Finalizar',
    'add': 'Adicionar',
    'all': 'Tudo',
    'clear': 'Apagar',
    'hidden': 'oculto',
    'remove': 'Remover',
    'detach': 'Desanexar',
    'attach': 'Anexar',
    'close': 'Fechar',
    'cancel': 'Cancelar',
    'refresh': 'Atualizar',
    'reset': 'Redefinir',
    'edit': 'Editar',
    'event': 'Evento',
    'details': 'Detalhes',
    'summary': 'Resumo',
    'save': 'Salvar',
    'change': 'Alterar',
    'noData': 'Nenhum dado',
    'nothingDefined': 'Nada definido',
    'others': 'Outros',
    'back': 'Voltar',
    'results': 'resultados',
    'selected': 'Selecionado',
    'selectAll': 'Selecionar tudo',
    'createNew': 'Criar novo',
    'useExisting': 'Usar existente',
    'select': 'Selecionar',
    'saveSuccessed': 'Salvo com êxito',
    'saveFailed': 'Falha ao salvar',
    'shouldArchive': 'Deve arquivar',
    'areYouSure': 'Tem certeza?',
    'view': 'Visualizar',
    'yes': 'Sim',
    'no': 'nº',
    'reason': 'Motivo',
    'actions': 'Ações',
    'reasonUnknown': 'Motivo desconhecido',
    'forcedByOperator': 'Forçado pelo Operador',
    'type': 'Tipo',
    'valueType': 'Tipo de valor',
    'value': 'Valor',
    'unit': 'Unidade',
    'disable': 'Desabilitar',
    'disabled': 'Desabilitado',
    'disabledShe': 'Desabilitado',
    'inactive': 'Inativo',
    'enable': 'Habilitar',
    'enabled': 'Habilitado',
    'enabledShe': 'Habilitado',
    'ignore': 'Ignorar',
    'cancelled': 'Cancelado',
    'nominal': 'Nominal',
    'unsavedChanges': 'Você realmente deseja sair? Há alterações não salvas!',
    'now': 'Agora',
    'showAll': 'Mostrar tudo',
    'showMore': 'Mostrar mais',
    'continue': 'Continuar',
    'group': 'Grupo',
    'download': 'Download',
    'progress': 'Progresso',
    'dates': 'Datas',
    'dir': {'top': 'Topo', 'bottom': 'Fim da página', 'left': 'Esquerda', 'right': 'Direita'},
    'sourceURI': 'URI de origem',
    'dimensions': 'Dimensões',
    'status': 'Status',
    'code': 'Código',
    'start': 'Iniciar',
    'end': 'Término',
    'update': 'Atualizar',
    'deviceId': 'Identificador do dispositivo',
    'time': 'Tempo',
    'show': 'Mostrar',
    'hide': 'Ocultar',
    'last': 'Último',
    'requestReceived': 'pedido recebido',
    'deleted': 'Excluída',
    'filters': 'Filtros',
    'restoreDefaults': 'Restaurar padrões',
    'setDate': 'Definir data',
    'setTime': 'Definir tempo',
    'errors': 'Erros',
    'inherited': 'Automático',
    'lastRefresh': 'Última atualização: há {0} atrás',
    'collapseDetails': 'Ocultar detalhes',
    'next': 'Próximo',
    'allSelected': 'Todos selecionados',
    'unchecked': 'Desmarcada',
    'manHours': 'Horas Homem',
    'pickDate': 'Escolha uma data',
    'total': 'Total',
    'line': 'Linha',
    'option': 'Opção',
    'default': 'Padrão',
    'set': 'Definir',
    'apiUrls': 'Endereços de API',
    'done': 'Feito',
    'filterBy': 'Filtrar por',
    'apply': 'Aplicar',
    'clearAll': 'Apagar tudo'
  },
  'machineControl': {
    'turnOn': 'Ligar',
    'turnOff': 'Desligar',
    'current': 'Atual',
    'liveStream': 'Transmissão ao vivo',
    'disconnectedWarning': 'AVISO! A máquina está desconectada - o seu desligamento causará perda de dados em buffer',
    'startMachine': 'Iniciar máquina <b> {machine} </b>?'
  },
  'lineControl': {'startLine': 'Iniciar linha<b>{line}</b> ?', 'stop': 'Parar', 'start': 'Iniciar'},
  'time': {
    'week': 'Semana',
    'minutes': 'Minutos',
    'date': 'Data',
    'day': 'Dia',
    'hour': 'hora',
    'hours': 'horas',
    'hoursPlural': 'horas',
    'days': 'dias',
    'month': 'Mês',
    'year': 'Ano',
    'quarter': 'Trimestre',
    'resolution': 'Resolução',
    'duration': 'Duração',
    'seconds': 'segundos',
    'startDate': 'Data de início',
    'endDate': 'Data do término',
    'startTime': 'Hora de início',
    'endTime': 'Hora do término',
    'selectDay': 'Selecionar dia',
    'longerThan': 'Maior que',
    'shorterThan': 'Menor que',
    'scope': 'Escopo de tempo',
    'currentOrder': 'Pedido atual',
    'showMinorStops': 'Mostrar pequenas paradas',
    'plannedOnly': 'Mostrar apenas o planejado',
    'unplannedOnly': 'Mostrar apenas o não planejado',
    'performanceOnly': 'Perdas de desempenho',
    'availabilityOnly': 'Perdas de disponibilidade',
    'utilizationOnly': 'Perdas de utilização',
    'excludeDefaults': 'Ocultar padrões',
    'monday': 'Segunda-feira',
    'tuesday': 'Terça-feira',
    'wednesday': 'Quarta-feira',
    'thursday': 'Quinta-feira',
    'friday': 'Sexta-feira',
    'saturday': 'Sábado',
    'sunday': 'Domingo',
    'plannedDate': 'Data planejada',
    'custom': 'Personalizado'
  },
  'graph': {
    'flow': 'Fluxo',
    'flows': 'Fluxos',
    'production': 'Fluxo de produção',
    'physical': 'Físico',
    'supplying': 'Abastecimento',
    'archiveChanges': 'Alterações ao arquivo',
    'isActive': 'Ativo',
    'addElement': 'Adicionar elemento',
    'detached': 'Desanexado',
    'andon': 'Andon',
    'noActiveFlow': 'Nenhum fluxo ativo'
  },
  'production': {'rawCounterIncreases': 'Valores brutos do contador neste período'},
  'monitoring': {
    'ofThePlan': 'Você atingiu% {value}% deste valor',
    'noPlan': 'Nenhum plano',
    'averageTargetPerformance': 'Desempenho médio alvo',
    'reportParam': 'Parametrização de relatórios',
    'performanceType': 'Tipo de desempenho',
    'designSpeed': 'Desempenho nominal',
    'validatedSpeed': 'Desempenho validado',
    'targetProduction': 'Alvo da produção',
    'maxPerfProduction': 'Plano baseado em desempenho',
    'plannedFromOrders': 'Plano baseado do pedido',
    'planned': 'Planejado',
    'notInFlow': 'Não está no fluxo de produção',
    'produced': 'Produzido',
    'wasted': 'Desperdiçado',
    'lastMessage': 'Última mensagem',
    'noMessages': 'Nenhuma mensagem',
    'noAlarms': 'Nenhum alarme',
    'noConnectionInfo': 'Nenhuma informação sobre a conexão',
    'noConnection': 'Sem conexão com o servidor',
    'processing': 'Em processamento',
    'dataProcessingFromLast': 'Processando dados do último',
    'lineStates': 'Status da Linha',
    'disconnected': 'desconectado',
    'serverDisconnected': 'Servidor desconectado',
    'working': 'trabalhando',
    'ready': 'pronto',
    'notWorking': 'em tempo de inatividade',
    'productionProgress': 'Avanço da produção',
    'production': 'Produção',
    'productionMap': 'Mapa da produção',
    'workStates': 'Estados de trabalho',
    'workLog': 'Histórico de trabalho',
    'event': 'Evento',
    'events': 'Eventos',
    'cause': 'Causa',
    'causes': 'Causas',
    'reasons': 'Motivos',
    'warnings': 'Avisos',
    'correctiveAction': 'Ação corretiva',
    'stopRealTime': 'Parar em tempo real',
    'stoppedRealTime': 'Tempo real parado',
    'realTime': 'Tempo real',
    'toRealTime': 'Em tempo real',
    'downtimes': 'Tempos de inatividade',
    'changeCurrentDowntime': 'Alterar o tempo de inatividade',
    'doubleClickToEdit': 'Clique duas vezes para editar o tempo de inatividade',
    'percentShare': 'Percentual',
    'timeShare': 'Compartilhamento de tempo',
    'minorStops': 'incluindo pequenas paradas',
    'remainingPlannedProduction': 'Possível produção restante com base na velocidade nominal',
    'remainingEstimatedProduction': 'Possível produção restante com base na velocidade efetiva',
    'remainingTime': 'Tempo restante',
    'performance': {
      'short': 'Desempenho nos últimos {0}',
      'effective': 'Desempenho efetivo',
      'nominal': 'Desempenho nominal'
    },
    'segment': {
      'baseQuantity': 'Quantidade da base',
      'baseQuality': 'Qualidade da base',
      'baseAvailability': 'Disponibilidade da base',
      'basePerformance': 'Desempenho da base'
    },
    'machine': {
      'counter': 'Contador',
      'parameters': 'Parâmetros',
      'alarms': 'Alarmes',
      'processParameters': 'Parâmetros do processo',
      'currentParameters': 'Parâmetros atuais',
      'activeAlarms': 'Alarmes ativos',
      'counters': 'Contadores',
      'alarmsHoldups': 'Alarmes e interrupções',
      'performanceInfo': 'Desempenho médio dos últimos {0}',
      'others': 'Outros',
      'lifespan': 'Vida útil',
      'environmentCondition': 'Condição do ambiente',
      'condition': 'Condição do ativo',
      'cost': 'Custo',
      'failurePreditcion': 'Previsão de falha',
      'probabilityFailure': 'Probabilidade de falha em 10 dias',
      'failureType': 'Tipo de falha',
      'timeToFailure': 'Tempo previsto para a falha',
      'predictedFailure': 'Falha prevista',
      'maintenanceHistory': 'Histórico de manutenção'
    },
    'lineInfo': {
      'advices': 'Sugestões',
      'forService': 'Tarefas',
      'jobs': 'Tarefas',
      'completed': 'Tarefas realizadas',
      'problems': 'Problemas',
      'planned': 'Tarefas na fila',
      'toAchieveTarget': 'Volume de produção esperado para este momento:',
      'confirmLinePause': 'Confirmar pausa da linha'
    },
    'worstAlarms': 'Piores problemas',
    'problems': 'sem problemas | um problema | {0} problemas',
    'performanceSettingHistory': 'Histórico de configurações de desempenho',
    'unitConversionsHistory': 'Histórico de conversões de unidades',
    'goodCount': 'Contagem de peças boas',
    'wasteCount': 'Contagem de perda ou de rejeitos',
    'overdue': '{0} atrasado',
    'left': '{0} restante'
  },
  'components': {
    'title': 'Componentes',
    'one': 'Componente',
    'usagePerUnit': 'Uso por unidade',
    'required': 'Requeridos',
    'convertUnit': 'Converter unidade',
    'validation': 'Validação necessária',
    'batchTracking': 'Rastreamento de lote',
    'scanTrackingId': 'Número de rastreamento de digitalização',
    'batchTrackingHelp': 'Quando o rastreamento estiver habilitado, é necessário fornecer\\n o número de identificação do lote para cada pacote digitalizado',
    'defaultPackageQuantity': 'No pacote',
    'warningLevelPercentage': 'Porcentagem de advertência',
    'warningLevel': 'Nível de alerta',
    'warningLevelHelp': '\\n% - aviso quando alguma porcentagem do pacote é consumida\\n\\n min - aviso quando o número estimado de minutos restantes\\n',
    'scannerCode': 'Código da digitalização',
    'scannerCodeType': 'tipo de código',
    'noTrackingId': 'Nenhuma identificação de rastreamento',
    'scan': 'Digitalizar',
    'packages': 'Pacote',
    'scanPackages': 'Verificar pacotes',
    'noComponents': 'Nenhum componente atribuído',
    'invalidCode': 'Componente não encontsrado',
    'addComponent': 'Adicionar componente',
    'closed': 'Fechado',
    'scanned': 'Digitalizado',
    'closeConfirm': 'Fechar',
    'eta': 'ETA (hora prevista de chegada)',
    'active': 'Ativo',
    'clickToActivate': 'Clicar para ativar',
    'used': 'Consumidos',
    'estimatedTotalQuantity': 'Quantidade estimada necessária',
    'packageScannerCode': 'Código do pacote',
    'defaultPackageQuantitySum': 'Quantidade total em pacotes',
    'registeredDate': 'Data de digitalização',
    'trackingId': 'Identificador de rastreamento',
    'consumptionType': {
      'title': 'Tipo de consumo',
      'help': '\\n \'MULTIPLE\' permite que diversos pacotes estejam abertos de uma vez, o primeiro é consumido.\\n\\n \'SINGLE\' fecha automaticamente o pacote anterior quando um novo é ativado, o último é consumido\\n'
    }
  },
  'oee': {
    'oee': 'OEE',
    'oeeLoss': 'Perda de OEE',
    'availability': 'Disponibilidade',
    'performance': 'Desempenho',
    'quality': 'Qualidade',
    'oeePercentage': 'OEE [%]',
    'availabilityPercentage': 'Disponibilidade [%]',
    'performancePercentage': 'Desempenho [%]',
    'teep': 'TEEP',
  },
  'metrics': {
    'mtbf': 'Tempo médio entre falhas (MTBF)',
    'mttr': 'Tempo médio de reparo (MTTR)',
    'mtbm': 'Tempo médio entre manutenção (MTBM)',
    'reliabilityMetrics': 'Métricas de confiabilidade'
  },
  'energy': {
    'usedEnergy': 'Energia usada',
    'currentLeastEfficient': 'Máquina atualmente menos eficiente',
    'currentPower': 'Potência atual',
    'baseline': 'Linha de base',
    'max': 'Média máx.'
  },
  'items': {
    'items': 'Itens',
    'import': 'Importar itens',
    'itemGroups': 'Grupos dos itens',
    'itemGroup': 'Grupo do item',
    'inheritedFromGroup': 'Herdado do grupo',
    'lineConfiguration': 'Configuração da linha',
    'noLineConfigured': 'Nenhum produto configurado para esta linha',
    'groups': 'Grupos',
    'add': 'Adicionar item',
    'addGroup': 'Adicionar grupo',
    'new': 'Novo item',
    'edit': 'Editar item',
    'number': 'Número',
    'description': 'Descrição',
    'quantity': 'Quantidade/palete',
    'unit': 'Unidade de quantidade',
    'performance': 'Desempenho',
    'expectedPerformance': 'Desempenho esperado',
    'expectedChangeover': 'Desempenho esperado',
    'packingStructure': 'Estrutura do pacote',
    'groupPackingStructure': 'Estrutura de pacote do grupo',
    'itemRoutingSpeed': 'Velocidade de roteamento do item',
    'groupRoutingSpeed': 'Velocidade de roteamento do grupo',
    'components': 'Componentes',
    'item': 'Item'
  },
  'pageNotFoundExplanation': 'Não foi possível encontrar esta página,\\n desculpas pelo transtorno',
  'processParams': {
    'compositionName': 'Nome da composição',
    'compositions': 'Composições',
    'saveComposition': 'Salvar composição',
    'signals': 'Indicadores',
    'signalName': 'Nome do sinal',
    'signalValue': 'Valor do sinal',
    'ongoingState': 'Estado contínuo',
    'ongoingProblem': 'Problema contínuo',
    'ongoingOrder': 'Pedido em andamento',
    'problemStart': 'Início do problema',
    'problemEnd': 'Término do problema',
    'orderStart': 'Início do pedido',
    'orderEnd': 'Término do pedido',
    'orderProduction': 'Produção do pedido'
  },
  'healthcheck': {'noDriverConnection': 'Sem conexão com o driver'},
  'vision': {
    'vision': 'Visão',
    'showAllClips': 'Mostrar todos os clipes',
    'selectCamera': 'Selecionar câmera',
    'getClip': 'Obter clipe',
    'getTheTwoPreviousMinutes': 'Obter 2 minutos antes e 2 minutos depois do tempo de inatividade de início',
    'noClips': 'Nenhum clipe encontrado',
    'noRecords': 'Não há registros daquele dia na câmera para download',
    'currentVideoTime': 'Hora atual do vídeo',
    'startVideoTime': 'Hora de início do vídeo',
    'startDowntime': 'Iniciar tempo de inatividade',
    'endDowntime': 'Término do tempo de inatividade',
    'showLiveVideo': 'Mostrar vídeo ao vivo da câmera',
    'selectStartClip': 'Selecionar vídeo inicial',
    'selectEndClip': 'Selecionar vídeo final',
    'videoDurationIs': 'A duração do vídeo é',
    'invalidTimeRange': 'Intervalo de tempo inválido.',
    'toMoreBefore': 'Escolher data de início anterior para o vídeo.',
    'toMoreAfter': 'Escolher data de término posterior para o vídeo.',
    'maxVideoIs': 'A duração máxima do vídeo é',
    'auto': 'A',
    'autoLong': 'Vídeo solicitado automaticamente pelo sistema',
    'manual': 'M',
    'manualLong': 'Vídeo solicitado manualmente pelo usuário',
    'videoTrigger': 'Ativar upload automático de vídeo',
    'autoDownload': 'Upload automático de vídeo',
    'watchVideo': 'Assista ao vídeo'
  },
  'downloadStatus': {
    'failed': 'Falha',
    'inProgress': 'Em andamento',
    'success': 'Pronto',
    'failedOnEdge': 'Houve falha na câmera',
    'noArchiveRecordings': 'Não há gravações na câmera',
    'deleted': 'Excluída'
  },
  'quests': 'Tarefas',
  'quest': {
    'quest': 'Tarefa',
    'questTitle': 'Título da tarefa',
    'questDescription': 'Descrição da tarefa',
    'project': 'Projeto',
    'type': 'Tipo de tarefa',
    'state': 'Estado da tarefa',
    'closeToState': 'Fechar para estado',
    'assignToGroups': 'Atribuir a grupos',
    'taskDetails': 'Detalhes da tarefa',
    'files': 'arquivos',
    'comments': 'Comentários',
    'noFiles': 'sem arquivos'
  },
  'health': {'health': 'Saúde', 'healthHistory': 'Histórico de saúde'},
  'panel': {
    'number': 'Número',
    'sku': 'Sku',
    'selectState': 'Selecionar estado',
    'selectMachine': 'Selecionar máquina',
    'comment': 'Comentar',
    'downtimeFrom': 'Tempo de inatividade a partir de',
    'ongoing': 'em andamento',
    'averagePerformance': 'Desempenho médio',
    'currentPerformance': 'Desempenho atual',
    'currentLineStatus': 'Estado atual da linha',
    'provideReasonAndComment': 'Adicionar motivo e comentário',
    'commentPlaceholder': 'Escreva seu comentário',
    'confirmData': 'Confirmar dados',
    'inputPlaceholder': 'Escreva aqui'
  },
  'workCalendar': 'Calendário de trabalho',
  'schedule': 'Agendar',
  'importSchedule': 'Importar cronograma',
  'addSchedule': 'Adicionar cronograma',
  'repeatEvery': 'Repetir a cada',
  'repeatOn': 'Repetir ativado',
  'ends': 'Terminar',
  'starts': 'Inicia',
  'never': 'Nunca',
  'on': 'Em',
  'calendar': {'period': 'Período'},
  'cycle': 'Ciclo',
  'deleteRecurringInstance': 'Excluir instância recorrente',
  'editRecurringInstance': 'Editar instância recorrente',
  'thisInstance': 'Esta instância',
  'thisAndFollowingInstances': 'Esta e as seguintes instâncias',
  'allInstances': 'Todas as instâncias',
  'order': {
    'addNewProperty': 'Adicionar nova propriedade',
    'sortByDate': 'Classificar por data',
    'sortBy': 'Ordenar por',
    'ascending': 'ascendente',
    'descending': 'descendente',
    'quantityProduced': 'Quantidade produzida',
    'plannedProduction': 'Produção planejada',
    'endDate': 'Data final',
    'activatedDate': 'Data de ativação',
    'totalTime': 'Duração do pedido',
    'plannedDate': 'Data de início',
    'eta': 'ETA',
    'delete': 'Limpar filtro'
  },
  'noCycle': 'Nenhuma definição de ciclo',
  'users': {'firstName': 'Primeiro nome', 'lastName': 'Último nome'},
  'teams': {'teamName': 'Nome da equipe'},
  'schedules': {'addLine': 'Adicionar linha aos filtros', 'clearLines': 'Apagar todas as linhas'},
  'chart': {'weekSymbol': 'S'},
  'factoryStructure': 'Estrutura da fábrica',
  'taxonomies': {
    'taxonomy': 'Taxonomia',
    'classification': 'Classificação',
    'selectClassification': 'Selecionar classificação',
    'selectClassificationLevel': 'Selecionar nível de classificação',
    'selectClassificationNodes': 'Selecionar níveis de classificação',
    'defaultTaxonomy': 'Padrão',
    'pickCategory': 'Escolher categoria',
    'selectTaxonomy': 'Selecionar taxonomia',
    'selectTaxonomyLevel': 'Selecione o nível de taxonomia'
  },
  'menu_logoutQuestion': 'Deseja sair?'
};
