<template>
  <div
    v-if="pending"
    class="text-center py-5"
  >
    <Loader />
  </div>
  <div
    v-else
    class="validation-errors-list"
  >
    <div
      v-for="errorGroup in errorGroups"
      :key="`error-group-${errorGroup.severity}`"
    >
      <h6
        style="color: #E2734C"
        class="my-3"
      >
        <i
          :class="errorGroup.icon"
          class="mr-1"
        />
        {{ $t(`items.severity.${errorGroup.severity.toLowerCase()}`) }}
      </h6>

      <div
        v-for="(error, index) in errorGroup.errors"
        :key="`error-${index}`"
        class="validation-errors-list-item warning"
      >
        <p class="element">
          {{ elementName(error.elementId) }}
        </p>

        <p class="text">
          {{ error.message }}
        </p>
      </div>
    </div>

    <div
      v-if="errors.length === 0"
      class="validation-errors-list-item info"
    >
      <div class="icon">
        <i class="fas fa-check-circle" />
      </div>

      <p class="text">
        {{ $t('items.noValidationErrors') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    skuId: String,
    errors: Array,
  },
  data: () => ({
    pending: false,
  }),
  computed: {
    ...mapGetters([
      'plantId',
    ]),
    ...mapGetters('element', [
      'elementName',
    ]),
    errorGroups() {
      const groupsObj = this.errors.reduce((acc, error) => {
        const severity = error.severity.toLowerCase();
        if (!acc[severity]) {
          acc[severity] = [];
        }
        acc[severity].push(error);
        return acc;
      }, {});

      const icons = {
        critical: 'fas fa-radiation',
        error: 'fas fa-exclamation-circle',
        warning: 'fas fa-exclamation-triangle',
      };

      return Object.keys(groupsObj).map(key => ({
        errors: groupsObj[key],
        severity: key,
        icon: icons[key],
      })).sort((a, b) => {
        const severityOrder = ['critical', 'error', 'warning'];
        return severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity);
      });
    },
  },
};
</script>

<style lang="scss">
  .validation-errors-list {
    max-width: 600px;
    margin: auto;

    &-item {
      border-radius: 7px;
      padding: 10px;
      margin-bottom: 8px;
      display: flex;
      font-size: 13px;

      p {
        margin-bottom: 0;
      }

      &.warning {
        background-color: #FFF4F0;
        color: #E2734C;
      }

      &.info {
        color: #5886C1;
        background-color: rgba(203, 220, 240, 0.2);
      }

      .icon {
        min-width: 32px;
        width: 32px;
      }

      .element {
        min-width: 150px;
        width: 150px;
        font-size: 15px;
        font-weight: 500;
      }

      .text {
        flex-grow: 1;
      }
    }
  }
</style>
