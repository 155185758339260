<template>
  <div
    :class="{'d-flex justify-content-center': !asInput}"
    :style="asDropdown ? '' : 'min-height: 50px'"
  >
    <Tippy
      ref="popup"
      interactive
      :placement="placement"
      theme="light"
      distance="10"
      trigger="click"
      arrow
      :max-width="showTaxonomies ? 750 : 350"
      :on-show="() => { shown = true; }"
      :on-hide="() => { shown = false; }"
    >
      <template #trigger>
        <slot>
          <div
            v-if="asDropdown"
            class="pro-select"
          >
            <div class="pro-select-label">
              {{ $t('line') }} / {{ $t('machine') }}
            </div>
            <div
              class="pro-select-inner"
              style="height: 32px; min-width: 100px; justify-content: flex-start"
            >
              <div
                v-if="!elementId || elementId === plantId"
                class="pro-select-text"
              >
                {{ $t('general.showAll') }}
              </div>
              <div v-else>
                <ElementLocation
                  :element-id="elementId"
                  hide-area
                />
              </div>
              <div class="pro-select-caret ml-1">
                <i class="fas fa-caret-down" />
              </div>
            </div>
          </div>
          <div
            v-else-if="asInput"
            class="element-location-input position-relative"
          >
            <BInput class="w-100" />
            <ElementLocation
              v-if="elementId"
              style="top: 7px; left: 1rem"
              class="position-absolute w-75 text-ellipsis"
              :element-id="elementId"
            />
            <i
              class="fas fa-sort position-absolute text-black-50"
              style="top: 10px; right: 1rem"
            />
            <span
              v-if="!elementId"
              style="top: 7px; left: 1rem"
              class="position-absolute text-black-50"
            >
              {{ $t('general.pickAMachine') }}
            </span>
          </div>

          <h5
            v-else
            class="d-inline underscore-select"
            :class="{'text-fancy': !regularFont}"
          >
            <ElementPicture
              :element-id="elementId"
              :class="!showImage ? 'hidden-image' : ''"
            />
            <i
              v-if="showTaxonomyNodeImage"
              class="fas fa-sitemap mr-1"
            />
            <span
              class="name element-name"
              :class="{'font-weight-normal': regularFont}"
            >
              {{ selectedName }}
            </span>
            <i class="fas fa-chevron-down" />
          </h5>
        </slot>
      </template>

      <div class="d-flex py-2">
        <BOverlay
          v-if="showTaxonomies"
          :show="loadingTaxonomies"
          class="border-right mr-4 pr-4"
        >
          <ListTaxonomySelector v-model="selectedTaxonomy" />
        </BOverlay>
        <div>
          <slot name="above-tree" />
          <ElementsTree
            v-if="shown"
            :opened="true"
            :show-expanded="showExpanded"
            :show-plant="showPlant"
            :show-areas="showAreas"
            :no-machines="linesOnly"
            :selected="elementId || plantId"
            :max-height="400"
            :filter="innerFilter"
            :transform="transform"
            :selected-taxonomy="selectedTaxonomy"
            class="pr-3"
            @select="select"
          />
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import ElementsTree from '@/components/settings/ElementsTree';
import ListTaxonomySelector from '@/pages/reports/ListTaxonomySelector';
import ElementLocation from '@/pages/settings/cameras/ElementLocation';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    showPlant: Boolean,
    showAreas: Boolean,
    elementId: String,
    asInput: Boolean,
    linesOnly: Boolean,
    hideImage: Boolean,
    regularFont: Boolean,
    filter: Function,
    asDropdown: Boolean,
    transform: Function,
    showExpanded: Boolean,
    showTaxonomies: Boolean,
    initSelectedTaxonomy: String,
    placement: {
      type: String,
      default: 'bottom',
    },
    elementsCapabilities: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    shown: false,
    selectedTaxonomy: '',
    loadingTaxonomies: false,
  }),
  components: {
    ElementLocation,
    ListTaxonomySelector,
    ElementsTree,
  },
  computed: {
    ...mapGetters('element', ['elementName']),
    ...mapGetters(['plantId']),
    ...mapState({
      elementTaxonomies: state => state.taxonomies.elementTaxonomies,
    }),
    selectedName() {
      if (this.showTaxonomies && this.selectedTaxonomy) {
        const taxonomy = this.elementTaxonomies.find(t => t.id === this.selectedTaxonomy);
        if (this.elementId === this.selectedTaxonomy) {
          return taxonomy?.description || taxonomy?.code || '-';
        }
        const node = taxonomy.nodes.find(n => n.id === this.elementId);
        return node?.description || node?.code || this.elementName(this.elementId) || '-';
      }
      return this.elementName(this.elementId);
    },
    showImage() {
      if (this.hideImage) return false;
      if (this.elementId === this.plantId) return false;
      if (this.showTaxonomyNodeImage) return false;
      return true;
    },
    showTaxonomyNodeImage() {
      if (this.showTaxonomies && this.selectedTaxonomy) {
        const taxonomy = this.elementTaxonomies?.find(t => t.id === this.selectedTaxonomy);
        if (this.elementId === this.selectedTaxonomy) return true;
        const node = (taxonomy?.nodes || []).find(n => n.id === this.elementId);
        if (node) return true;
      }
      return false;
    },
  },
  watch: {
    selectedTaxonomy(v) {
      this.$emit('update:selectedTaxonomy', v);
      this.$emit('select', v || this.plantId);
      this.$emit('update:elementId', v || this.plantId);
    },
    initSelectedTaxonomy(v) {
      this.selectedTaxonomy = v || '';
    },
  },
  methods: {
    innerFilter(v) {
      return (this.elementsCapabilities.every(c => v.capabilities[c])
        ? this.elementsCapabilities.every(c => v.capabilities[c] === 'true')
        : true)
        && (this.filter
          ? this.filter(v)
          : true);
    },
    select({ id }) {
      const selectedId = id || this.selectedTaxonomy || this.plantId;
      this.$refs.popup.tippy().hide();
      this.$emit('select', selectedId);
      this.$emit('update:elementId', selectedId);
    },
  },
  created() {
    this.selectedTaxonomy = this.initSelectedTaxonomy || '';
  },
};
</script>

<style lang="scss" scoped>
  @import "@core/components/proselect.scss";

  .machine-picture {
    max-height: 40px;
    padding: 5px;
  }

  .element-location-input {
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }

  .hidden-image {
    opacity: 0;
    width: 0px;
    padding: 0;
    height: 40px;
  }

  .underscore-select {
    min-height: 50px;

    .name {
      font-weight: bold;
    }
  }

  .element-name {
    display: inline-block;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(6px);
  }

</style>
