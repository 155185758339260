<template>
  <div class="worsts-container">
    <Tippy
      ref="popup"
      interactive
      placement="right"
      :trigger="isMobile ? 'manual' : 'mouseenter'"
      distance="7"
      theme="light"
      max-width="600"
      arrow
    >
      <template #trigger>
        <div
          @click.stop="disabled ? null : modalShown = true"
        >
          <slot />
        </div>
      </template>

      <div
        v-if="!disabled"
        style="cursor: initial; min-width: 250px;"
      >
        <ReliabilityMetrics
          v-if="reliabilityMetrics"
          v-bind="reliabilityMetrics"
          style="max-width: 400px"
        />
        <hr
          v-if="reliabilityMetrics"
          class="mt-1 mb-2"
        >
        <h5
          class="text-left mb-1"
          style="font-size: 16px"
        >
          {{ $t('monitoring.worstAlarms') }}
        </h5>
        <div
          v-for="(event, index) in parsed"
          :key="index"
        >
          <hr
            v-if="index !== 0"
            class="my-2"
          >
          <div class="row justify-content-between mx-2 text-center">
            <div
              class="col-4 pr-2 align-self-center"
              style="line-height: 1.1"
            >
              <div :style="{ color: event.color }">
                {{ CalcDuration(1, event.durationInSeconds+1) }}
              </div>
              <div>
                <StateBadge
                  :state="event.state"
                  class="text-wrap"
                  style="font-size: 10px; line-height: 9px"
                />
              </div>
            </div>
            <div
              class="col-8 px-2 align-self-center"
              style="line-height: 1.1; font-size: 12px;"
            >
              <span
                v-if="event.elementName"
                class="font-weight-bold"
              >
                [ {{ event.elementName }} ]
              </span>
              <span class="event-desc">{{ event.description }}</span>
            </div>
          </div>
        </div>
        <div class="w-100 py-1" />
      </div>
    </Tippy>

    <LModal
      :show.sync="modalShown"
      size="lg"
    >
      <h5
        v-if="parsed && parsed.length > 0"
        class="text-left"
      >
        {{ $t('monitoring.worstAlarms') }}:
      </h5>
      <div class="row mt-4">
        <div class="col-12 col-md-12 col-lg-5">
          <ColumnPareto
            v-if="columnData"
            :columns="columnData || []"
            :quantity="false"
            :height="500"
            :no-toolbar="true"
          />
          <div v-else>
            <Loader style="margin: auto;" />
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-7">
          <div class="mb-2">
            <button
              class="btn btn-block btn-sm btn-outline-dark"
              @click="showReport"
            >
              <i class="fas fa-chart-column mr-2 details-icon" />
              {{ $t('reports.report') }}
            </button>
          </div>
          <div
            v-for="(event, index) in parsed"
            :key="index"
          >
            <hr
              v-if="index !== 0"
              class="my-2"
            >
            <div class="row justify-content-center align-items-center text-center px-3">
              <div
                class="col pr-1 align-self-center"
                style="line-height: 1.1"
              >
                <div :style="{ color: event.color }">
                  {{ CalcDuration(1, event.durationInSeconds+1) }}
                </div>
              </div>
              <div
                class="col pr-1 align-self-center"
                style="line-height: 1.1"
              >
                <div :style="{ color: event.color }">
                  {{ event.quantity }}
                </div>
              </div>
              <div
                :style="{ color: event.color }"
                class="col-6 px-1 py-2 align-self-center"
                style="line-height: 1.1; font-size: 12px; font-weight: normal"
              >
                <StateBadge
                  :state="event.state"
                  class="mb-1"
                  style="font-size: 10px; line-height: 9px"
                />
                <div>
                  <span
                    v-if="event.elementName"
                    class="font-weight-bold text-nowrap"
                  >
                    [ {{ event.elementName }} ]
                  </span>
                  {{ event.lineName }}
                  <span class="event-desc">{{ event.description }}</span>
                </div>
              </div>
              <div>
                <div class="position-relative">
                  <CorrectiveActionPopover :event-id="event.workEventId">
                    <div class="wand">
                      <i class="icon-corrective-action" />
                    </div>
                  </CorrectiveActionPopover>
                </div>
              </div>
            </div>
            <div
              v-if="event.reasons && event.reasons.length > 0"
              style="max-width: 400px; margin: auto"
            >
              <hr style="max-width: 40%; margin: 10px auto 0">
              <div class="text-center font-weight-bold">
                {{ $t('work.reasons') }}:
              </div>
              <div
                v-for="r in event.reasons"
                :key="r.id"
                class="d-flex justify-content-between"
              >
                <div> {{ r.description }} </div>
                <div>{{ CalcDuration(1, event.durationInSeconds+1) }}</div>
              </div>
            </div>
          </div>
          <hr>
          <ReliabilityMetrics
            v-if="reliabilityMetrics"
            v-bind="reliabilityMetrics"
            style="max-width: 400px"
          />
        </div>
      </div>
    </LModal>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2';
import { mapGetters, mapState } from 'vuex';
import CalcDuration from '@/utils/calcDuration';
import colors from '@/utils/colors';
import ReliabilityMetrics from '@/components/reports/worsts/ReliabilityMetrics';
import CorrectiveActionPopover from '@/components/popovers/CorrectiveActions';
import ColumnPareto from '@/components/charts/reports/ColumnPareto';

export default {
  props: {
    plantId: String,
    elementId: String,
    popoverPlacement: String,
    disabled: Boolean,
  },
  data: () => ({
    modalShown: false,
  }),
  components: {
    ColumnPareto,
    CorrectiveActionPopover,
    ReliabilityMetrics,
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'startDate',
      'endDate',
    ]),
    ...mapGetters('element', [
      'elementName',
    ]),
    ...mapGetters('work', [
      'workEvent',
      'workState',
    ]),
    ...mapState('work', {
      pending: state => state.worklogs.pending.worsts,
      allWorsts: state => state.worklogs.worsts,
    }),
    columnData() {
      return this.parsed.map(x => ({
        label: x.description,
        duration: x.durationInSeconds,
        quantity: x.quantity,
        durationRatio: x.durationRatio,
        quantityRatio: x.quantityRatio,
      }));
    },
    worsts() {
      if (!this.allWorsts) return [];
      const el = this.allWorsts.find(({ elementId }) => elementId === this.elementId);
      return el ? el.worsts : [];
    },
    reliabilityMetrics() {
      const el = this.allWorsts?.find(({ elementId }) => elementId === this.elementId);
      return el ? el.reliabilityMetrics : null;
    },
    number() {
      return this.parsed ? this.parsed.length : 0;
    },
    parsed() {
      if (!this.worsts) return null;

      const three = this.worsts
        .slice()
        .filter(a => a.durationInSeconds > 0)
        .sort((a, b) => b.durationInSeconds - a.durationInSeconds)
        .slice(0, 3);

      return three
        .map((el, i) => {
          const workEvent = this.workEvent(el.workEventId);
          if (!workEvent) return null;
          const workState = this.workState(workEvent.workStateId);
          return {
            ...el,
            description: workEvent ? workEvent.name : null,
            elementName: this.elementName(el.rootElementId ?? el.elementId),
            state: workState,
            color: this.getColor(workState ? colors(workState.colorCode) : colors('red'), i),
            reasons: !el.reasons ? [] : el.reasons.map(r => ({
              durationInSeconds: r.durationInSeconds,
              ...workEvent.reasons.find(reason => reason.id === r.reasonId),
            })),
          };
        })
        .filter(x => x);
    },
  },
  watch: {
    modalShown(val) {
      this.$emit('modal', val);
      if (val) this.$emit('openProblems', val);
    },
    parsed(p) {
      this.$emit('worsts', p);
    },
  },
  methods: {
    CalcDuration,
    showReport() {
      const { elementId } = this;
      this.$store.dispatch('report/selected', elementId);
      this.$router.push(`/${this.plantId}/reports/worsts`);
    },
    getColor(color, index) {
      return tinycolor(color)
        .darken(index * 10)
        .setAlpha(1 - (index * 0.05)).toString();
    },
  },
  created() {
    if (this.worsts) {
      this.$emit('worsts', this.worsts);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .worsts-button {
    transition: border-color 500ms, background-color 500ms;
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 10px;
    margin: auto;
    padding: 5px;
    cursor: pointer;
  }

  .xs-worsts {
    margin-top: -3px;
    height: 30px;
    width: 30px;
  }

  .worsts-button:hover {
    border-color: $ilabo;
    background-color: rgba($ilabo, 0.1);
  }

  .event-desc {
    font-size: 13px;
  }

  .worsts-container :deep(.popover-body) {
    width: 500px;
  }

</style>
