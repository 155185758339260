export default {
  'originalDates': 'Datas originais',
  'originalDuration': 'Duração original',
  'newDates': 'Novas datas',
  'newDuration': 'Nova duração',
  'firstDowntime': '1º Parada:',
  'secondDowntime': '2º Parada:',
  'shorten': 'Abreviar',
  'split': 'Dividir',
  'thisMachine': 'esta máquina',
  'addComment': 'Adicionar um comentário',
  'noComments': 'Sem comentários',
  'downtimeSummary': 'Resumo de Paradas',
  'step': 'Etapa {step}',
  'selectRootLineMachine': 'Selecionar o local da causa raiz',
  'selectLineMachine': 'Selecione linha/máquina',
  'selectState': 'Selecionar estado',
  'selectProblem': 'Selecionar problema',
  'specifyAReason': 'Especificar um motivo',
  'correctiveActionTaken': 'Ação corretiva tomada',
  'downtimeDuration': 'Duração da parada',
  'vision': 'Visão',
  'oeeLossType': 'Tipo de perda OEE',
  'table': {'downtime': 'Parada', 'details': 'Detalhes', 'time': 'Tempo', 'duration': 'Duração'},
  'noSharedComments': 'Sem comentários compartilhados'
};
