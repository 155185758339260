<template>
  <div class="text-widget">
    <div class="value text-ellipsis w-100 px-1 text-center">
      <span v-if="value.type === customLayoutWidgetFieldType.static">
        {{ value.value || '-' }}
      </span>
      <span v-if="value.type === customLayoutWidgetFieldType.tag">
        {{ getTagValueForTag(value.value) || '-' }}
      </span>
    </div>

    <div class="unit text-ellipsis w-100 px-1 text-center">
      <span v-if="unit.type === customLayoutWidgetFieldType.static">
        {{ unit.value || '-' }}
      </span>
      <span v-if="unit.type === customLayoutWidgetFieldType.tag">
        {{ getTagValueForTag(unit.value) || '-' }}
      </span>
    </div>

    <div class="title text-ellipsis w-100 px-1 text-center">
      {{ rawDetails.title || '-' }}
    </div>
  </div>
</template>

<script>
import { customLayoutWidgetFieldType } from '@/components/customLayout/customLayoutDictionary';

export default {
  name: 'CustomLayoutTextWidget',
  props: {
    unit: Object,
    value: Object,
    rawDetails: Object,

    currentTags: Array,
  },
  computed: {
    customLayoutWidgetFieldType() {
      return customLayoutWidgetFieldType;
    },
  },
  methods: {
    getTagValueForTag(tag) {
      return this.currentTags.find(t => t.nodeId === tag)?.value || '-';
    },
  },
};
</script>

<style scoped lang="scss">
.text-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .value {
    font-size: 18px;
    font-weight: 600;
  }

  .unit {
    font-size: 11px;
    opacity: 0.7;
  }

  .title {
    font-size: 12px;
  }
}
</style>
