var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widgets-library"},[_c('div',{staticClass:"header-box d-flex align-items-center py-2"},[(_vm.selectedWidgetType)?_c('div',{staticClass:"back-button",on:{"click":function($event){_vm.selectedWidgetType = ''}}},[_c('i',{staticClass:"fas fa-chevron-left"})]):_vm._e(),(_vm.selectedWidgetType)?_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.selectWidgetSize'))+" ")]),_c('p',{staticClass:"text-black-50 mb-0 small"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.dragAndDropSelectedWidgetIntoTheGrid'))+" ")])]):_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.selectWidgetType'))+" ")])])]),(!_vm.selectedWidgetType)?_c('div',{staticClass:"widget-type-selector d-flex overflow-hidden overflow-x-auto mt-auto",style:({
      gap: '10px',
      minHeight: `${_vm.cellSize * 2.5 + 20}px`,
      height: `${_vm.cellSize * 2.5 + 20}px`,
    })},_vm._l((_vm.widgetTypes),function(widgetType){return _c('div',{key:widgetType.type,staticClass:"widget-wrapper type",on:{"click":function($event){_vm.selectedWidgetType = widgetType.type}}},[_c('div',{staticClass:"droppable-element",style:({
          minWidth: `${_vm.cellSize * 2}px`,
          width: `${_vm.cellSize * 2}px`,
          minHeight: `${_vm.cellSize}px`,
          height: `${_vm.cellSize}px`,
        })},[(_vm.customLayoutService.getPlaceholderComponentForWidgetType(widgetType.type))?_c(_vm.customLayoutService.getPlaceholderComponentForWidgetType(widgetType.type),_vm._b({tag:"Component"},'Component',_vm.customLayoutService.getPlaceholderComponentPropsForWidgetComponent({
            w: 2,
            h: 1,
          }),false)):_vm._e()],1),_c('div',{staticClass:"widget-type"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(widgetType.label)+" ")])])])}),0):_c('div',{staticClass:"widget-selector d-flex overflow-hidden overflow-x-auto mt-auto",style:({
      gap: '10px',
      minHeight: `${_vm.cellSize * 2.5 + 20}px`,
      height: `${_vm.cellSize * 2.5 + 20}px`,
    })},[_vm._l((_vm.draggableWidgetsInSelectedType),function(widget,index){return _c('div',{key:`widget-${index}`,staticClass:"widget-wrapper",style:({
        minWidth: `${_vm.cellSize * (Math.max(2, widget.w) + 0.5)}px`,
        width: `${_vm.cellSize * (Math.max(2, widget.w) + 0.5)}px`,
      })},[_c('div',{staticClass:"droppable-element",style:({
          minWidth: `${_vm.cellSize * widget.w}px`,
          width: `${_vm.cellSize * widget.w}px`,
          minHeight: `${_vm.cellSize * widget.h}px`,
          height: `${_vm.cellSize * widget.h}px`,
        }),attrs:{"draggable":"true","unselectable":"on"},on:{"drag":function($event){return _vm.drag($event, widget)},"dragend":function($event){return _vm.dragend($event, widget)}}},[(_vm.customLayoutService.getPlaceholderComponentForWidgetType(_vm.selectedWidgetType))?_c(_vm.customLayoutService.getPlaceholderComponentForWidgetType(_vm.selectedWidgetType),_vm._b({tag:"Component"},'Component',_vm.customLayoutService.getPlaceholderComponentPropsForWidgetComponent(widget),false)):_vm._e()],1),_c('div',{staticClass:"size-badge"},[_vm._v(" "+_vm._s(_vm.getSizeOptionValue(widget))+" ")])])}),(_vm.draggableWidgetsInSelectedType.length === 0)?_c('div',{staticClass:"text-center w-100 pt-5 text-black-50"},[_c('h4',{staticClass:"fas fa-info-circle mb-2"}),_c('p',{staticClass:"mb-0 small"},[_vm._v(" "+_vm._s(_vm.$t('customLayout.noWidgetsAvailable'))+" ")])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }