export default {
  'rules': 'Reglas',
  'functions': 'Funciones de pre procesamiento',
  'addRule': 'Agregar regla',
  'help': 'Ayuda',
  'cockpitType': 'Tipo de Cockpit',
  'countingOEE': 'Contando OEE',
  'excludeOEE': 'Excluir OEE',
  'thresholds': 'Umbrales',
  'saved': 'Salvado',
  'baseUnit': 'Unidad base',
  'baseOrderUnit': 'Unidad de orden predeterminada',
  'notSelected': 'No seleccionado',
  'saveFailed': 'Guardar fallido',
  'addMachine': 'Agregar máquina a los filtros',
  'noCounter': 'La máquina no tiene contador.',
  'machineBaseUnitSetInCounter': 'La unidad de la máquina está determinada por la unidad del contador',
  'shortPerformancePeriod': 'Período',
  'setupIssues': 'Problemas de configuración',
  'noIssuesDetected': '¡No se detectaron problemas de configuración!',
  'test': 'Prueba',
  'isDummy': 'Fictisio',
  'isDummyInfo': 'Las máquinas ficticias no están monitoreadas y no tienen señales. Se pueden marcar manualmente como causa raíz de un tiempo de inactividad.',
  'maxTimeBufferOffset': 'Retraso de parada despues de la máquina anterior',
  'maxTimeBufferOffsetInfo': 'Si la máquina anterior dejó de funcionar, ¿cuánto tiempo se necesita para que esta máquina se detenga?',
  'rootCauseDelay': 'Retraso por causa raiz',
  'rootCauseDelayInfo': '¿Qué tan tarde puede fallar esta máquina, para que otras máquinas aún la señalen como la causa principal?',
  'signalNameError': 'El nombre de la señal no puede contener los caracteres "(" y ")"',
  'rule': {
    'rule': 'Regla',
    'condition': 'Condición',
    'addAndCondition': 'AND',
    'showAdvanced': 'Usar editor avanzado',
    'info': 'El estado de la máquina se determina según las siguientes reglas. Cuando se observa un cambio en el valor de la señal,\\nel motor de reglas analizará las reglas siguientes a partir de la prioridad más alta.\\nLa primera regla que devuelve "verdadera" determinará el nuevo estado',
    'ruleType': 'Tipo de regla',
    'eventType': 'Condiciones simples',
    'cSharpType': 'Función C#',
    'timeBasedRule': 'Regla basada en el tiempo',
    'script': 'Texto'
  },
  'events': {'forcedOnly': 'Solo forzado', 'showSystem': 'Show system events'},
  'tooltipState': {
    'lack': '"Este estado aparece cuando su máquina no recibe suficientes materiales necesarios para su continua operación. El problema puede ser un transportador defectuoso, que los operadores no rellenen los componentes a tiempo, o el almacenamiento de los componentes esté demasiado lejos de la línea"',
    'work': 'Este estado aparece cuando su máquina funciona correctamente y el proceso de producción está en curso.',
    'inactive': 'Este es un estado neutral que PackOS usa para su máquina cuando no hay falla pero la máquina no funciona.',
    'stop': '"Este estado indica que su máquina ha sido detenida a propósito por un trabajador de la fábrica para realizar por ejemplo, cambios de formato o limpiezas. Cuando su máquina está en este estado, PackOS muestra el problema que ocurrió antes de que el estado Detenido por el operador entrara en vigor"',
    'failure': 'Este estado indica que su máquina se ha detenido o no funciona correctamente debido a, por ejemplo, desgaste mecánico o error del operador. En este caso, la fuente del problema es el problema que ocurrió antes de que apareciera el estado de falla',
    'off': 'Este estado aparece cuando su máquina está desconectada porque, por ejemplo, se cortó la energía o la máquina perdió conexión con el conductor',
    'idle': 'Este estado muestra cuando tu maquina no esta funcionando aunque no esté en falla y no hay ningun problema. En este caso, PackOS busca el origen del problema comprobando la máquina "Aguas arriba"',
    'nolivedata': 'Este es un estado temporal que aparece cuando PackOS pierde temporalmente la conexión con la fábrica, por ejemplo, por problemas de conexión a internet. PackOS muestra este estado solo de forma temporal y rápida lo cambia a otros estados operativos"',
    'holdup': '"Este estado aparece cuando su máquina no funciona porque su salida está bloqueada, generalmente "Aguas abajo" (Proceso siguiente). En este caso, PackOS busca el origen del problema comprobando la máquina descendente. La fuente del problema en la máquina descendente se copia como el estado y el problema de la máquina con el estado Atraco"',
    'changeover': 'Este estado aparece cuando su máquina se detiene porque la línea de producción o la máquina están ajustado para un nuevo proceso de producción (cambio de formato).'
  },
  'shortPerformance': 'Rendimiento instantáneo',
  'shortPerformanceInfo': 'Rendimiento instantáneo calculado como un aumento de producción promedio reciente',
  'value': 'Valor',
  'priority': 'Prioridad',
  'dayOfWork': 'Dia de trabajo',
  'plantConfiguration': 'Configuración de la planta',
  'machineSignals': 'Señales de la máquina',
  'signals': 'Señales',
  'signalsExport': 'Exportar señales',
  'logixGateConfigExport': 'Exportar configuración de LogixGate',
  'signalsImport': 'Importar señales',
  'workEventsImport': 'Importar problemas',
  'csvImport': 'Importar CSV',
  'csvExport': 'Exportar CSV',
  'properties': 'Propiedades',
  'signalsAndProperties': 'Señales y propiedades',
  'machineLicenses': 'Licencias de máquinas',
  'addSignal': 'Agregar señal',
  'addProperty': 'Agregar propiedad',
  'electricity': 'Electricidad',
  'electricityBaseline': 'Línea de base de electricidad',
  'electricityMax': 'Electricidad media máx.',
  'shiftDefinitions': 'Definiciones de turno',
  'info': 'Info',
  'deleteInfo': 'Presione el botón \'Guardar\' para eliminar',
  'timezone': 'Zona horaria',
  'yourCurrent': 'Actual',
  'differentTimezoneInfo': '¡Estás en una zona horaria diferente a la de fábrica!',
  'differentTimezoneSettingsInfo': 'La siguiente configuración se aplicará a la zona horaria de fábrica',
  'differentTimezoneSelectorInfo': 'Mostraremos la hora convertida a su zona horaria',
  'differentialWaste': 'Diferencia de desperdicio',
  'wasteBaseDiffCounter': 'Máquina base para el cálculo de residuos diferenciales',
  'disableBufferResetBetweenOrders': 'Deshabilitar el reinicio del búfer entre pedidos',
  'workingHours': 'Horas Laborales',
  'addNewShift': 'Agregar nuevo turno',
  'productionExpectations': 'Objetivos de producción',
  'doYouWantToDelete': '¿Desea borrar?',
  'newShift': 'Nuevo turno',
  'editShift': 'Editar turno',
  'removeShift': 'Quitar turno',
  'users': 'Usuarios',
  'nothingToAdd': 'Nada que añadir',
  'attachedLinesAndMachines': 'Líneas y máquinas adjuntas',
  'addLineOrMachine': 'Agregar línea / máquina',
  'layout': 'Diseño',
  'saveLayout': 'Guardar diseño',
  'elementsOrder': 'Orden de los elementos',
  'advanced': 'Avanzado',
  'default': 'Valor por defecto',
  'noLayoutLine': 'La línea no está definida en el diseño de la planta.',
  'units': 'Unidades',
  'unitCode': 'Codigo de unidad',
  'isBaseUnit': 'Es base',
  'fromUnit': 'Unidad de medida esclava',
  'toUnit': 'Unidad de medida maestra',
  'multiplier': 'Multiplicador',
  'shortName': 'Nombre corto',
  'blockCard': 'Bloquear tarjeta',
  'activeSince': 'Activo desde',
  'production': 'Producción',
  'card': 'Tarjeta',
  'cardId': 'Tarjeta de identificacion',
  'cardPin': 'PIN de la tarjeta',
  'oldPin': 'PIN anterior',
  'newPin': 'PIN nuevo',
  'pinChange': 'Cambiar PIN',
  'pinLengthError': 'El PIN debe tener exactamente 4 números',
  'cardIdLengthError': 'La identificación de la tarjeta debe tener al menos 4 caracteres',
  'connections': 'Flujos',
  'displaySettings': 'Monitor',
  'adminPlantSettings': 'Configuración de plantas de administración',
  'orderAutostartThreshold': 'Solicitar inicio automático',
  'forcedStateThreshold': 'Poner fin al tiempo de inactividad forzado',
  'downtimeThreshold': 'Poner fin al tiempo de inactividad continuo',
  'minorStopThreshold': 'Umbral de una parada menor',
  'designPerformanceInfo': 'El rendimiento de la máquina que se puede lograr \\nsegún el fabricante',
  'validatedPerformanceInfo': 'Rendimiento de la máquina\\nmedido y validado en condiciones reales',
  'orderAutostartThresholdInfo': 'Umbral que define la cantidad de\\nproducción necesaria para iniciar un orden automáticamente',
  'forcedStateThresholdInfo': 'Umbral que define la cantidad de\\nproducción necesaria para finalizar el tiempo de inactividad forzado automáticamente',
  'downtimeThresholdInfo': 'Umbral que define la cantidad de\\nproducción necesaria para finalizar el tiempo de inactividad en curso automáticamente',
  'calculateEnergyFromMachines': 'Calcular energía de las máquinas',
  'expectations': {
    'expectedOee': 'OEE esperado',
    'expectedPerformance': 'Rendimiento esperado',
    'maxExpectedFailures': 'Número máximo esperado de fallas',
    'validatedPerformance': 'Validated performance',
    'designPerformance': 'Rendimiento de diseño'
  },
  'automation': {
    'automation': 'Automatización',
    'add': 'Agregar automatización',
    'type': {
      'CreateQuestOnWorkEvent': {
        'title': 'Crear tarea',
        'description': 'Crear tarea cuando este tipo de problema ocurre en la máquina'
      },
      'CreateAndCloseQuestOnWorkEvent': {
        'title': 'Crear y cerrar tarea',
        'description': '"Crear tarea cuando este tipo de problema ocurra en la máquina y cerrarlo automáticamente cuando el problema se detenga"'
      }
    }
  },
  'unit': {
    'usedInPackageStructure': 'Es imposible quitar la unidad, se usa en una definición de estructura de paquete',
    'fieldsNotValid': 'Formato de campo incorrecto'
  },
  'sku': {
    'expectedEffort': 'horas de trabajo esperadas',
    'skuDescriptionTooltip': 'Sí deja en blanco, la descripción de SKU será usada'
  },
  'function': {
    'sessionName': 'Nombre de la sesión',
    'sessionInfo': 'Las funciones que usan el mismo nombre de sesión se ejecutarán secuencialmente (en lugar de simultáneamente)',
    'run': 'Run',
    'testValue': 'Valores de prueba',
    'testResult': 'Resultados de la prueba',
    'triggers': 'Disparadores',
    'script': 'Texto',
    'isStateful': 'Estado',
    'sequenceProcessing': 'Procesar las siguientes etiquetas secuencialmente',
    'newFunction': 'Agregar función',
    'editFunction': 'Editar función'
  },
  'energy': 'Energía',
  'setUrl': 'Guardar nueva URL',
  'authUrl': 'Autentificación de dirección URL',
  'loadedOnNextLogin': 'Será cargado despues del proximo inicio de sesión',
  'operatorCockpit': 'Vista de Aplicación',
  'weekStart': 'Inicio de Semana',
  'cockpitLayout': 'Vista de la aplicación',
  'maxPerformance': 'Performance máximo instantaneo',
  'maxPerformanceInfo': 'Usar en controlador "limite de velocidad" para validar que el valor es factible (Desborde de velocidad=0)',
  'taxonomy': {'taxonomy': 'Taxonomía'},
  'allTypes': 'Todas las clases'
};
