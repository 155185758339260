<template>
  <div>
    <WorkCalendarData
      :date-from="dateFrom"
      :date-to="dateTo"
    />

    <Tippy
      ref="dropdown"
      trigger="click"
      theme="light"
      max-width="none"
      arrow
      interactive
    >
      <template #trigger>
        <div class="scope-container">
          <div class="dropdown-container">
            <div class="date-scope-text">
              <span class="d-none d-md-inline">
                {{ currentScopeText }}
              </span>
              <span class="d-md-none">
                {{ simpleCurrentScopeText }}
              </span>
              <span
                v-if="inDifferentTimezone"
                :id="`diffTime${$uId}`"
                class="text-danger ml-1 clock-alert text-nowrap"
              >
                <i class="ion ion-md-time" />!
              </span>
            </div>
            <TimeProgress
              :start-date="startDate"
              :end-date="endDate"
            />
          </div>
          <div
            class="mx-auto mx-md-0"
            style="max-width: 300px"
          >
            <div
              class="mt-2 scope-details"
            >
              <div class="d-flex">
                <div class="scope-icon">
                  <i :class="productionFromOrders ? 'icon-orders' : 'far fa-clipboard'" />
                </div>
                <div class="text-nowrap">
                  {{ $t('monitoring.targetProduction') }}:&nbsp;
                </div>
                <div class="font-weight-bold">
                  {{
                    productionFromOrders ? $t('monitoring.plannedFromOrders') : $t('monitoring.maxPerfProduction')
                  }}
                </div>
              </div>
            </div>
            <div
              class="d-flex scope-details mt-2"
            >
              <div class="scope-icon">
                <i class="fas fa-tachometer-alt" />
              </div>
              {{ $t('monitoring.performanceType') }}:&nbsp;
              <div class="font-weight-bold">
                {{
                  useValidatedSpeed ? $t('monitoring.validatedSpeed') : $t('monitoring.designSpeed')
                }}
              </div>
            </div>
          </div>

          <BTooltip
            :target="`diffTime${$uId}`"
            placement="right"
          >
            <b>{{ $t('settings.differentTimezoneInfo') }}</b>
            {{ $t('settings.differentTimezoneSelectorInfo') }}:<br>
            <span class="font-weight-normal">{{ timezone }}</span>
          </BTooltip>
        </div>
      </template>

      <div class="row no-gutters text-left">
        <div
          class="col-12 col-md-auto"
          style="border-right: 2px solid #eee; width: 200px"
        >
          <div class="text-uppercase font-weight-bold select-title">
            {{ $t('time.scope') }}
          </div>
          <hr
            class="my-0 ml-0"
            style="max-width: 80px"
          >
          <a
            v-for="el in scopes"
            :key="el.name"
            class="dropdown-item"
            :class="{ selected: selectedDateScope && selectedDateScope.name === el.name }"
            @click="selectDate(el)"
          >
            {{ el.text }}
          </a>
        </div>
        <div class="pl-2 col-12 col-md-auto">
          <ProductionTargetSwitch
            :planned-from-orders="productionFromOrders"
            class="pt-2"
          />

          <PerformanceTypeSwitch
            :validated-speed.sync="useValidatedSpeed"
            class="pt-3"
          />
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import WorkCalendarData from '@/components/data/WorkCalendarData';
import TimeProgress from '@/components/schedule/TimeProgress';
import PerformanceTypeSwitch from '@/components/utils/PerformanceTypeSwitch';
import ProductionTargetSwitch from '@/components/utils/ProductionTargetSwitch';
import newFactoryDayReset from '@/mixins/newFactoryDayReset';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    useValidatedSpeed: false,
    dateFrom: moment().subtract(1, 'day').startOf('day').unix(),
    dateTo: moment().endOf('day').unix(),
  }),
  components: {
    WorkCalendarData,
    PerformanceTypeSwitch,
    ProductionTargetSwitch,
    TimeProgress,
  },
  mixins: [
    newFactoryDayReset(),
  ],
  computed: {
    ...mapGetters([
      'startDate',
      'endDate',
      'timezone',
      'inDifferentTimezone',
      'dateScopes',
      'currentScopeText',
      'splitByOrder',
      'selectedDateScope',
      'simpleCurrentScopeText',
      'productionFromOrders',
    ]),
    scopes() {
      if (!this.dateScopes) return [];
      return this.dateScopes;
    },
  },
  watch: {
    useValidatedSpeed(v) {
      this.$store.commit('useValidatedSpeed', v);
    },
  },
  methods: {
    selectDate(scope) {
      this.dropdownShown = false;
      this.$store.commit('setDateScope', scope.name);
      this.$refs.dropdown.tippy().hide();
    },
    hideDropdown() {
      this.dropdownShown = false;
    },
  },
  created() {
    this.useValidatedSpeed = this.$store.state.monitoring.validatedSpeed;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .dropdown-toggle {
    text-transform: uppercase;
    font-weight: 400;
    padding: 6px 10px;
    font-size: 10px;
    margin: 0;
    border-radius: 9px;
  }

  .clock-alert {
    position: relative;
    top: 2px;
    font-size: 18px;
  }

  .date-scope-text {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    padding-bottom: 5px;
  }

  .select-title {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .scope-details {
    font-size: 0.8rem;
    margin-top: 2px;
    border-radius: 8px;

    i {
      color: $blue;
    }
  }

  .enable-orders {
    cursor: pointer;

    &:hover .icon-orders {
      color: $blue;
    }
  }

  .scope-container {
    border-radius: 10px;
    padding: 10px;
    border: 2px solid #eee;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 3px rgb(100 100 100 / 40%);
    }
  }

  .scope-icon {
    width: 30px;
    text-align: center;;
  }

  .dropdown-item {
    padding: 8px 10px;

    &.selected {
      background-color: $blue;
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    .dropdown-container {
      margin: auto;
    }
  }
</style>
