export default {
  'rules': 'Các quy tắc',
  'addRule': 'Thêm quy tắc',
  'countingOEE': 'Đếm OEE',
  'excludeOEE': 'Loại trừ OEE',
  'thresholds': 'Ngưỡng',
  'saved': 'Đã lưu',
  'baseUnit': 'Đơn vị cơ sở',
  'notSelected': 'Không được chọn',
  'saveFailed': 'Lưu thất bại',
  'addMachine': 'Thêm máy vào bộ lọc',
  'rule': {
    'rule': 'Quy tắc',
    'condition': 'Điều kiện',
    'addAndCondition': 'Và',
    'showAdvanced': 'Sử dụng trình soạn thảo nâng cao',
    'info': 'Trạng thái máy được xác định dựa trên các quy tắc bên dưới. Khi quan sát thấy thay đổi giá trị tín hiệu, công cụ quy tắc sẽ phân tích các quy tắc bên dưới bắt đầu từ mức ưu tiên cao nhất. Quy tắc đầu tiên trả về \'true\' sẽ xác định trạng thái mới',
    'ruleType': 'loại quy tắc',
    'eventType': 'Các điều kiện đơn giản',
    'cSharpType': 'hàm C#',
    'timeBasedRule': 'Quy tắc dựa trên thời gian',
    'script': 'Bản thảo'
  },
  'events': {'forcedOnly': 'Chỉ bị ép buộc', 'showSystem': 'Hiện thị các sự kiện hệ thống'},
  'value': 'Giá trị',
  'priority': 'Ưu tiên',
  'dayOfWork': 'Ngày làm việc',
  'plantConfiguration': 'Cấu hình nhà máy',
  'machineSignals': 'Các tín hiệu máy',
  'signals': 'Các tín hiệu',
  'signalsExport': 'Xuất các tín hiệu',
  'logixGateConfigExport': 'Xuất cấu hình LogixGate',
  'signalsImport': 'Nhập các tín hiệu',
  'workEventsImport': 'Nhập các vấn đề',
  'csvImport': 'Nhập CSV',
  'csvExport': 'Xuất CSV',
  'properties': 'Đặc tính',
  'signalsAndProperties': 'Tín hiệu và đặc tính',
  'machineLicenses': 'Giấy phép máy móc',
  'addSignal': 'Thêm tín hiệu',
  'addProperty': 'Thêm đặc tính',
  'electricity': 'Điện',
  'electricityBaseline': 'Mức điện tại điểm khởi đầu',
  'electricityMax': 'Mức điện trung bình tối đa',
  'shiftDefinitions': 'Các định nghĩa ca',
  'info': 'Thông tin',
  'deleteInfo': 'Nhấn nút \'Lưu\' để xóa',
  'differentTimezoneInfo': 'Bạn đang ở một múi giờ khác so với nhà máy!',
  'differentTimezoneSettingsInfo': 'Cài đặt dưới đây sẽ áp dụng cho múi giờ nhà máy',
  'differentTimezoneSelectorInfo': 'Chúng tôi sẽ hiển thị thời gian đã chuyển đổi sang múi giờ của bạn',
  'wasteBaseDiffCounter': 'Máy cơ sở để tính toán phế liệu/ chất thải khác nhau',
  'workingHours': 'Giờ làm việc',
  'addNewShift': 'Thêm ca làm việc mới',
  'productionExpectations': 'Các mục tiêu sản xuất',
  'doYouWantToDelete': 'Bạn có muốn xóa không?',
  'newShift': 'Ca làm việc mới',
  'editShift': 'Chỉnh sửa ca làm việc',
  'removeShift': 'Xóa ca làm việc',
  'users': 'Người dùng',
  'nothingToAdd': 'Không có gì để thêm',
  'attachedLinesAndMachines': 'dây chuyền và máy móc kèm theo',
  'addLineOrMachine': 'Thêm dây chuyền/ máy',
  'layout': 'Sơ đồ',
  'saveLayout': 'Lưu sơ đồ',
  'elementsOrder': 'Chi tiết đơn hàng',
  'advanced': 'Nâng cao',
  'default': 'Mặc định',
  'noLayoutLine': 'dây chuyền không được xác định trong bố trí nhà máy',
  'units': 'Đơn vị',
  'unitCode': 'Mã đơn vị',
  'isBaseUnit': 'Là cơ sở ?',
  'fromUnit': 'Phụ UoM',
  'toUnit': 'Chính UoM',
  'multiplier': 'Hệ số nhân',
  'shortName': 'Tên ngắn',
  'blockCard': 'Thẻ khối',
  'activeSince': 'Kích hoạt từ trước',
  'production': 'Sản xuất',
  'card': 'Thẻ',
  'cardId': 'ID thẻ',
  'cardPin': 'Mã PIN thẻ',
  'oldPin': 'Mã PIN trước',
  'newPin': 'Mã PIN mới',
  'pinChange': 'Thay đổi mã PIN',
  'pinLengthError': 'Mã PIN phải chính xác 4 số',
  'cardIdLengthError': 'ID thẻ phải có ít nhất 4 ký tự',
  'connections': 'Tiến trình',
  'displaySettings': 'Hiển thị',
  'adminPlantSettings': 'Quản trị viên thiết lập',
  'orderAutostartThreshold': 'Đơn hàng bắt đầu tự động',
  'forcedStateThreshold': 'Kết thúc thời gian ngừng máy bắt buộc',
  'downtimeThreshold': 'Khóa bộ đếm thời gian ngừng máy',
  'minorStopThreshold': 'Ngưỡng của một dừng nhỏ',
  'designPerformanceInfo': 'Hiệu suất máy có thể đạt được theo nhà sản xuất',
  'validatedPerformanceInfo': 'Hiệu suất của máy đã được đo lường và xác nhận trong điều kiện thực tế',
  'orderAutostartThresholdInfo': 'Ngưỡng xác định số lượng sản xuất cần thiết để tự động bắt đầu đơn hàng',
  'forcedStateThresholdInfo': 'Ngưỡng xác định số lượng sản xuất cần thiết để tự động kết thúc thời gian ngừng hoạt động bắt buộc',
  'downtimeThresholdInfo': 'Ngưỡng xác định số lượng sản xuất cần thiết để kết thúc thời gian ngừng hoạt động. Thời gian chết đang diễn ra sẽ không được thay đổi thành thời gian ngừng hoạt động hoặc công việc khác, cho đến khi số lượng sản phẩm được chỉ định đã được đăng ký',
  'expectations': {
    'expectedOee': 'OEE dự kiến',
    'expectedPerformance': 'Hiệu suất mong đợi',
    'maxExpectedFailures': 'Số lần thất bại dự kiến tối đa',
    'validatedPerformance': 'Hiệu suất được xác minh',
    'designPerformance': 'Hiệu suất thiết kế'
  },
  'automation': {
    'automation': 'Tự động hóa',
    'add': 'Thêm tự động hóa',
    'type': {
      'CreateQuestOnWorkEvent': {
        'title': 'Tạo nhiệm vụ',
        'description': 'Tạo tác vụ khi loại sự cố này xảy ra trên máy'
      },
      'CreateAndCloseQuestOnWorkEvent': {
        'title': 'Tạo và đóng nhiệm vụ',
        'description': 'Tạo tác vụ khi loại sự cố này xảy ra trên máy và tự động đóng lại khi sự cố dừng'
      }
    }
  },
  'unit': {
    'usedInPackageStructure': 'Không thể loại bỏ đơn vị, nó được sử dụng trong định nghĩa cấu trúc gói',
    'fieldsNotValid': 'Định dạng trường không chính xác'
  },
  'functions': 'Chức năng tiền xử lý',
  'help': 'Giúp đỡ',
  'operatorCockpit': 'Buồng điều hành',
  'weekStart': 'Bắt đầu tuần',
  'cockpitType': 'Loại buồng lái',
  'cockpitLayout': 'Bố trí buồng lái',
  'maxPerformance': 'Hiệu suất tức thời tối đa',
  'maxPerformanceInfo': 'Được sử dụng trong bộ đếm bảo vệ, để xác thực xem giá trị bộ đếm mới có khả thi hay không (0 = bảo vệ bị vô hiệu hóa)',
  'baseOrderUnit': 'Đơn vị đặt hàng mặc định',
  'noCounter': 'Máy không có bộ đếm',
  'machineBaseUnitSetInCounter': 'Đơn vị máy được xác định bởi đơn vị của bộ đếm',
  'shortPerformancePeriod': 'Giai đoạn',
  'setupIssues': 'Sự cố thiết lập',
  'noIssuesDetected': 'Không phát hiện thấy sự cố cấu hình nào!',
  'test': 'Kiểm tra',
  'isDummy': 'là giả?',
  'isDummyInfo': 'Máy giả không được giám sát và không có tín hiệu. Chúng có thể được đánh dấu thủ công là nguyên nhân gốc rễ của thời gian chết.',
  'maxTimeBufferOffset': 'Ngưng trì hoãn sau máy trước đó',
  'maxTimeBufferOffsetInfo': 'Nếu máy trước ngừng hoạt động thì cần bao nhiêu thời gian để máy này dừng?',
  'rootCauseDelay': 'Nguyên nhân gốc rễ của việc chậm trễ',
  'rootCauseDelayInfo': 'Máy này có thể bị hỏng muộn đến mức nào mà các máy khác vẫn chỉ ra nó là nguyên nhân gốc rễ?',
  'signalNameError': 'Tên tín hiệu không được chứa ký tự "(" và ")"',
  'tooltipState': {
    'lack': 'Trạng thái này xuất hiện khi máy của bạn không nhận đủ các thành phần cần thiết để hoạt động liên tục. Vấn đề có thể là do băng chuyền bị lỗi, người vận hành không nạp nguyên vật liệu đúng hạn hoặc kho chứa nguyên vật liệu quá xa dây chuyền',
    'work': 'Trạng thái này xuất hiện khi máy của bạn đang hoạt động bình thường và đang trong quá trình sản xuất',
    'inactive': 'Đây là trạng thái trung tính mà PackOS sử dụng cho máy của bạn khi không có sự cố nhưng máy không hoạt động',
    'stop': 'Trạng thái này cho biết rằng máy của bạn đã bị công nhân nhà máy cố ý dừng vì họ muốn, chẳng hạn như định cấu hình hoặc làm sạch máy. Khi máy của bạn ở trạng thái này, PackOS hiển thị sự cố đã xảy ra trước khi trạng thái Dừng bởi Người vận hành có hiệu lực',
    'failure': 'Trạng thái này cho biết máy của bạn đã dừng hoặc không hoạt động bình thường do, ví dụ như do hao mòn cơ khí hoặc lỗi của người vận hành. Trong trường hợp này, nguồn gốc của sự cố là sự cố xảy ra trước khi trạng thái Sai hỏng xuất hiện',
    'off': 'Trạng thái này xuất hiện khi máy của bạn bị ngắt kết nối, chẳng hạn như bị cắt điện hoặc máy bị mất kết nối với trình điều khiển',
    'idle': 'Trạng thái này thể hiện khi máy của bạn không hoạt động mặc dù nó không bị hỏng và không có vấn đề gì với nó. Trong trường hợp này, PackOS tìm nguồn gốc của sự cố bằng cách kiểm tra máy ngược dòng',
    'nolivedata': 'Đây là trạng thái tạm thời xuất hiện khi PackOS tạm thời mất kết nối với nhà máy, chẳng hạn như do sự cố kết nối internet. PackOS chỉ hiển thị trạng thái này tạm thời và nhanh chóng thay đổi nó sang các trạng thái “hoạt động” khác',
    'holdup': 'Trạng thái này xuất hiện khi máy của bạn không hoạt động do đầu ra của nó bị chặn, thường là do các máy hạ nguồn. Trong trường hợp này, PackOS tìm nguồn gốc của sự cố bằng cách kiểm tra máy hạ nguồn. Nguồn gốc của sự cố ở máy xuôi dòng được sao chép dưới dạng trạng thái và sự cố đối với máy có trạng thái Holdup',
    'changeover': 'Trạng thái này xuất hiện khi máy của bạn bị dừng do dây chuyền sản xuất hoặc máy được điều chỉnh cho quy trình sản xuất mới (chuyển đổi)'
  },
  'shortPerformance': 'Hiệu suất tức thời',
  'shortPerformanceInfo': 'Hiệu suất tức thời được tính là mức tăng sản lượng trung bình gần đây',
  'timezone': 'Múi giờ',
  'yourCurrent': 'Hiện tại của bạn',
  'differentialWaste': 'Phế phẩm/ chất thải khác',
  'disableBufferResetBetweenOrders': 'Vô hiệu hóa thiết lập lại bộ đệm giữa các đơn đặt hàng',
  'calculateEnergyFromMachines': 'Tính toán năng lượng từ máy móc',
  'taxonomy': {'taxonomy': 'Phân loại'},
  'sku': {
    'expectedEffort': 'Nỗ lực dự kiến (mh)',
    'skuDescriptionTooltip': 'Nếu để trống, mô tả SKU sẽ được sử dụng'
  },
  'function': {
    'sessionName': 'Tên phiên',
    'sessionInfo': 'Các chức năng sử dụng cùng một tên phiên sẽ được thực hiện tuần tự (chứ không phải đồng thời)',
    'run': 'Run',
    'testValue': 'Giá trị kiểm tra',
    'testResult': 'Kết quả kiểm tra',
    'triggers': 'Triggers',
    'script': 'Bản thảo',
    'isStateful': 'Stateful',
    'sequenceProcessing': 'Xử lý các thẻ theo tuần tự',
    'newFunction': 'Thêm chức năng',
    'editFunction': 'Chỉnh sửa chức năng'
  },
  'energy': 'Năng lượng',
  'setUrl': 'Lưu URL mới',
  'authUrl': 'Địa chỉ URL xác thực',
  'loadedOnNextLogin': 'Sẽ được tải sau lần đăng nhập tiếp theo',
  'allTypes': 'Các loại'
};
