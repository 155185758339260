import {
  customLayoutWidgetFieldType,
  customLayoutWidgetType,
} from '@/components/customLayout/customLayoutDictionary';
import CustomLayoutTextWidget from '@/components/customLayout/widgets/CustomLayoutTextWidget';
import CustomLayoutGaugeWidget from '@/components/customLayout/widgets/CustomLayoutGaugeWidget';
import CustomLayoutPercentageWidget
  from '@/components/customLayout/widgets/CustomLayoutPercentageWidget';
import CustomLayoutProgressBarWidget
  from '@/components/customLayout/widgets/CustomLayoutProgressBarWidget';

export default {
  createCustomLayoutWidgetObject(
    w,
    h,
    type,
    title,
    availableSizes,
    fields,
  ) {
    return {
      w,
      h,
      details: {
        type,
        title,
        size: {
          w,
          h,
        },
        availableSizes,
        fields,
      },
    };
  },

  getAllAvailableSizesForType(type) {
    switch (type) {
      case customLayoutWidgetType.text:
        return [
          {
            w: 1,
            h: 1,
          },
          {
            w: 2,
            h: 1,
          },
        ];
      case customLayoutWidgetType.gaugeChart:
      case customLayoutWidgetType.percentageChart:
        return [
          {
            w: 1,
            h: 1,
          },
          {
            w: 2,
            h: 1,
          },
          {
            w: 2,
            h: 2,
          },
        ];
      case customLayoutWidgetType.progressBar:
        return [
          {
            w: 2,
            h: 1,
          },
          {
            w: 3,
            h: 1,
          },
          {
            w: 4,
            h: 1,
          },
        ];
      default:
        return [];
    }
  },

  getWidgetFieldsForType(type) {
    switch (type) {
      case customLayoutWidgetType.text:
        return [
          {
            key: 'value',
            name: 'Value',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'unit',
            name: 'Unit',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
        ];
      case customLayoutWidgetType.gaugeChart:
        return [
          {
            key: 'value',
            name: 'Value',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'unit',
            name: 'Unit',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'minValue',
            name: 'Min value',
            type: customLayoutWidgetFieldType.static,
            value: '0',
          },
          {
            key: 'maxValue',
            name: 'Max value',
            type: customLayoutWidgetFieldType.static,
            value: '100',
          },
        ];
      case customLayoutWidgetType.percentageChart:
        return [
          {
            key: 'value',
            name: 'Value',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'maxValue',
            name: 'Max value',
            type: customLayoutWidgetFieldType.static,
            value: '100',
          },
        ];
      case customLayoutWidgetType.progressBar:
        return [
          {
            key: 'value',
            name: 'Value',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'unit',
            name: 'Unit',
            type: customLayoutWidgetFieldType.static,
            value: '',
          },
          {
            key: 'maxValue',
            name: 'Max value',
            type: customLayoutWidgetFieldType.static,
            value: '100',
          },
        ];
      default:
        return [];
    }
  },

  getTextWidgets() {
    return [
      this.createCustomLayoutWidgetObject(
        1,
        1,
        customLayoutWidgetType.text,
        'Widget 1x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.text),
        this.getWidgetFieldsForType(customLayoutWidgetType.text),
      ),
      this.createCustomLayoutWidgetObject(
        2,
        1,
        customLayoutWidgetType.text,
        'Widget 2x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.text),
        this.getWidgetFieldsForType(customLayoutWidgetType.text),
      ),
    ];
  },

  getGaugeWidgets() {
    return [
      this.createCustomLayoutWidgetObject(
        1,
        1,
        customLayoutWidgetType.gaugeChart,
        'Widget 1x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.gaugeChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.gaugeChart),
      ),
      this.createCustomLayoutWidgetObject(
        2,
        1,
        customLayoutWidgetType.gaugeChart,
        'Widget 2x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.gaugeChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.gaugeChart),
      ),
      this.createCustomLayoutWidgetObject(
        2,
        2,
        customLayoutWidgetType.gaugeChart,
        'Widget 2x2',
        this.getAllAvailableSizesForType(customLayoutWidgetType.gaugeChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.gaugeChart),
      ),
    ];
  },

  getPercentageWidgets() {
    return [
      this.createCustomLayoutWidgetObject(
        1,
        1,
        customLayoutWidgetType.percentageChart,
        'Widget 1x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.percentageChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.percentageChart),
      ),
      this.createCustomLayoutWidgetObject(
        2,
        1,
        customLayoutWidgetType.percentageChart,
        'Widget 2x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.percentageChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.percentageChart),
      ),
      this.createCustomLayoutWidgetObject(
        2,
        2,
        customLayoutWidgetType.percentageChart,
        'Widget 2x2',
        this.getAllAvailableSizesForType(customLayoutWidgetType.percentageChart),
        this.getWidgetFieldsForType(customLayoutWidgetType.percentageChart),
      ),
    ];
  },

  getProgressBarWidgets() {
    return [
      this.createCustomLayoutWidgetObject(
        2,
        1,
        customLayoutWidgetType.progressBar,
        'Widget 2x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.progressBar),
        this.getWidgetFieldsForType(customLayoutWidgetType.progressBar),
      ),
      this.createCustomLayoutWidgetObject(
        3,
        1,
        customLayoutWidgetType.progressBar,
        'Widget 3x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.progressBar),
        this.getWidgetFieldsForType(customLayoutWidgetType.progressBar),
      ),
      this.createCustomLayoutWidgetObject(
        4,
        1,
        customLayoutWidgetType.progressBar,
        'Widget 4x1',
        this.getAllAvailableSizesForType(customLayoutWidgetType.progressBar),
        this.getWidgetFieldsForType(customLayoutWidgetType.progressBar),
      ),
    ];
  },

  getComponentForWidgetDetails(widgetDetails) {
    if (!widgetDetails) return null;

    switch (widgetDetails.type) {
      case customLayoutWidgetType.text:
        return CustomLayoutTextWidget;
      case customLayoutWidgetType.gaugeChart:
        return CustomLayoutGaugeWidget;
      case customLayoutWidgetType.percentageChart:
        return CustomLayoutPercentageWidget;
      case customLayoutWidgetType.progressBar:
        return CustomLayoutProgressBarWidget;
      default:
        return null;
    }
  },

  getComponentPropsForWidgetDetails(widgetDetails) {
    return {
      rawDetails: widgetDetails,
      ...widgetDetails.fields.reduce((acc, curr) => {
        acc[curr.key] = {
          ...curr,
        };
        return acc;
      }, {}),
    };
  },

  getPlaceholderComponentForWidgetType(type) {
    switch (type) {
      case customLayoutWidgetType.text:
        return CustomLayoutTextWidget;
      case customLayoutWidgetType.gaugeChart:
        return CustomLayoutGaugeWidget;
      case customLayoutWidgetType.percentageChart:
        return CustomLayoutPercentageWidget;
      case customLayoutWidgetType.progressBar:
        return CustomLayoutProgressBarWidget;
      default:
        return null;
    }
  },

  getPlaceholderComponentPropsForWidgetComponent(widget) {
    return {
      rawDetails: {
        title: 'Title',
        size: {
          w: widget.w,
          h: widget.h,
        },
      },
      unit: {
        value: 'unit',
        type: customLayoutWidgetFieldType.static,
      },
      value: {
        value: '200',
        type: customLayoutWidgetFieldType.static,
      },
      minValue: {
        value: '0',
        type: customLayoutWidgetFieldType.static,
      },
      maxValue: {
        value: '300',
        type: customLayoutWidgetFieldType.static,
      },
    };
  },

  getAllAvailableDraggableWidgets() {
    return [
      ...this.getTextWidgets(),
      ...this.getGaugeWidgets(),
      ...this.getPercentageWidgets(),
      ...this.getProgressBarWidgets(),
    ];
  },
};
