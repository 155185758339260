<template>
  <BModal
    id="additional-fields-edit-modal"
    hide-header
    hide-footer
  >
    <BTable
      :items="innerValue"
      :fields="fields"
    >
      <template
        #cell(name)="data"
      >
        <BFormInput
          v-model="innerValue[data.index][data.field.key]"
          class="form-control"
        />
      </template>
      <template
        #cell(value)="data"
      >
        <BFormInput
          v-model="innerValue[data.index][data.field.key]"
          class="form-control"
        />
      </template>
      <template
        #cell(delete)="data"
      >
        <BButton
          variant="link"
          class="text-black-50 px-0"
          @click="deleteRow(data.index)"
        >
          <h4 class="mb-0">
            <i class="far fa-times-circle" />
          </h4>
        </BButton>
      </template>
    </BTable>

    <div class="text-center mb-4 mt-n2">
      <BButton
        variant="primary"
        size="sm"
        @click="addRow"
      >
        <i class="fas fa-plus-circle mr-1" />
        {{ $t('general.add') }}
      </BButton>
    </div>

    <div class="d-flex align-items-center w-100">
      <BButton
        class="w-50 h-100 mr-1"
        block
        size="sm"
        @click="cancel"
      >
        {{ $t('general.cancel') }}
      </BButton>
      <BButton
        class="w-50 h-100 mt-0 ml-1"
        block
        size="sm"
        variant="primary"
        @click="save"
      >
        {{ $t('general.save') }}
      </BButton>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    originalValue: [],
    innerValue: [],
  }),
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('general.name'),
        },
        {
          key: 'value',
          label: this.$t('general.value'),
        },
        {
          key: 'delete',
          label: '',
        },
      ];
    },
  },
  watch: {
    value() {
      this.originalValue = JSON.parse(JSON.stringify(this.value));
      this.innerValue = JSON.parse(JSON.stringify(this.value));
    },
  },
  methods: {
    cancel() {
      this.innerValue = JSON.parse(JSON.stringify(this.originalValue));
      this.$nextTick(() => {
        this.hideModal();
      });
    },
    save() {
      const finalValue = this.innerValue.filter(row => row.name && row.value);
      this.$emit('input', JSON.parse(JSON.stringify(finalValue)));
      this.$nextTick(() => {
        this.hideModal();
      });
    },
    hideModal() {
      this.$bvModal.hide('additional-fields-edit-modal');
    },
    deleteRow(index) {
      this.innerValue = [...this.innerValue.filter((_, i) => i !== index)];
    },
    addRow() {
      this.innerValue = [...this.innerValue, {
        name: '',
        value: '',
      }];
    },
  },
  created() {
    this.originalValue = JSON.parse(JSON.stringify(this.value));
    this.innerValue = JSON.parse(JSON.stringify(this.value));
  },
};
</script>

<style scoped lang="scss">

</style>
