<template>
  <div class="importer-wrapper">
    <div>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Navigation
          :parent="parent"
          :import-id="importId"
          :import-file-name="fileName"
          page="import"
          class="pb-3"
        />
      </div>
    </div>
    <div class="importer-container">
      <Loader
        v-if="pending && !status"
        class="mt-3"
      />
      <div
        v-else-if="status && !status.haveResult && entities.length === 0"
        :style="{ color: status.backgroundColor }"
        class="text-center"
      >
        <Loader
          v-if="status.refresh && !status.icon"
          class="mt-3"
        />
        <i
          :class="status.icon"
          style="font-size: 25px"
        />
        <div class="mt-2">
          {{ status.text }}
        </div>
        <div class="small">
          {{ statusMessage }}
        </div>
      </div>
      <FileView
        v-else
        :error="error"
        :import-id="importId"
        :entities="entities"
        :simulation="false"
        :template-name="templateName"
        @back="showImporter"
      />
    </div>
  </div>
</template>

<script>
import FileView from '@/components/importer/FileView';
import Navigation from '@/components/importer/Navigation';
import { importStatus } from '@/components/importer/importStatus';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    parent: String,
    importId: String,
  },
  data: () => ({
    fileName: '',
    entities: [],
    templatesPending: false,
    pending: false,
    status: null,
    statusMessage: '',
    error: '',
    templateId: null,
    templates: [],
  }),
  components: {
    Navigation,
    FileView,
  },
  computed: {
    ...mapGetters(['plantId']),
    templateName() {
      if (!this.templates || !this.templateId) return null;
      return this.templates.find(t => t.id === this.templateId)?.name || '?';
    },
  },
  methods: {
    ...mapActions(['getImportResult']),
    ...mapActions(['getPlantUnits']),
    ...mapActions(['getTemplates']),
    importStatus,
    request() {
      if (this.loop) {
        clearTimeout(this.loop);
      }

      this.pending = true;
      this.getImportResult({
        params: {
          importId: this.importId,
          query: {
            plantId: this.plantId,
          },
        },
      })
        .then(({ data }) => {
          this.fileName = data.inputFileName;
          this.entities = data.items || [];
          this.status = importStatus(data.status || '');
          this.statusMessage = data.statusMessage || '';
          this.templateId = data.functionId;

          if (this.status.refresh || this.entities.some(x => x.status === 'Pending')) {
            this.loop = setTimeout(() => {
              this.request();
            }, 3000);
          }
        })
        .catch(({ response: { data } }) => {
          this.error = data;
          this.errorMessage(data.title);
        })
        .finally(() => {
          this.pending = false;
          this.getPlantUnits({ params: { plantId: this.plantId } });
        });
    },
    requestTemplates() {
      this.templatesPending = true;
      this.getTemplates({
        params: {
          query: {
            plantId: this.plantId,
            visibility: 'Visible',
          },
        },
      })
        .then(({ data }) => {
          this.templates = data;
          this.templatesPending = false;
        });
    },
    errorMessage(data) {
      this.$root.$bvToast.toast(data || `${this.$t('import.import')} ${this.$t('error.error')}`, {
        title: 'Error',
        autoHideDelay: 10000,
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
      });
    },
    showImporter() {
      this.$router.push(`/${this.plantId}/importer/${this.parent}`);
    },
  },
  destroyed() {
    if (this.loop) {
      clearTimeout(this.loop);
    }
  },
  async created() {
    await this.requestTemplates();
    await this.request();
  },
};
</script>

<style lang='scss' scoped>
@import "./style.scss";
</style>
