var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-widget"},[_c('div',{style:({
      width: `${_vm.percentageHeight}px`,
      height: `${_vm.percentageHeight}px`,
    })},[_c('OEEChart',{style:({
        height: `${_vm.percentageHeight}px`,
        width: `${_vm.percentageHeight}px`,
      }),attrs:{"value":_vm.percentageValue,"size":_vm.percentageSize}})],1),_c('div',{staticClass:"title text-ellipsis w-100 px-1 text-center",class:{
      'mt-2': _vm.doubleHeight,
    }},[_vm._v(" "+_vm._s(_vm.rawDetails.title || '-')+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }