<template>
  <div class="item-line-configuration">
    <hr>
    <div style="overflow-y: auto">
      <div
        v-for="area in sortedAreas"
        :key="area.id"
        class="mt-4"
      >
        <div class="d-flex align-items-center">
          <div
            :style="{
              width: displayLabour ? '306px' : '578px',
            }"
          >
            <CustomCheckbox
              :value="selectedAreas[area.id]"
              @update:value="toggleAllInArea(area.id, $event)"
            >
              <span class="font-weight-bold pl-2">
                {{ area.name }}
              </span>
            </CustomCheckbox>
          </div>
          <div
            class="column-header text-uppercase"
            style="width: 200px"
          >
            {{ $t('graph.flow') }}
          </div>
          <div
            class="column-header"
            style="width: 280px"
          >
            <PerformanceTypeSwitch
              :inline="true"
              :validated-speed.sync="showFromValidated"
            />
          </div>
          <div
            v-if="displayLabour"
            class="column-header text-uppercase"
            style="width: 280px"
          >
            {{ $t('settings.sku.expectedEffort') }}
          </div>
        </div>

        <div
          class="mt-2"
          style="margin-left: 30px"
        >
          <div
            v-for="line in getLinesForArea(area.id)"
            :key="line.id"
            class="line-configuration-row row-width d-flex align-items-center"
          >
            <div
              class="px-2"
              :style="{
                width: displayLabour ? '270px' : '550px',
              }"
            >
              <i
                v-if="inheritedSelectedLines[line.id]"
                v-tippy
                class="fas fa-object-group inherited-from-group"
                :content="$t('items.inheritedFromGroup')"
              />
              <CustomCheckbox
                :value="selectedLines[line.id] || inheritedSelectedLines[line.id]"
                :clickable="!inheritedSelectedLines[line.id]"
                :color="inheritedSelectedLines[line.id] ? 'grey' : undefined"
                @update:value="$set(selectedLines, line.id, $event)"
              >
                <span class="font-weight-bold pl-2">
                  {{ line.name }}
                </span>
                <div
                  v-if="lineConfigurationImportResult[line.id] && showImportResult"
                  class="ml-2"
                >
                  <ImportStatus
                    :success="lineConfigurationImportResult[line.id]"
                    :success-text="``"
                    :failure-text="``"
                  />
                </div>
              </CustomCheckbox>
            </div>
            <div
              class="column-cell"
              style="width: 200px"
            >
              <FlowInput
                v-if="selectedLines[line.id]"
                :value.sync="lineConfigs[line.id]"
                :inherited-value="inheritedLineConfigs[line.id]"
                :have-inherited-data="haveDataFromGroup(line.id)"
                :line-id="line.id"
                :pending="pending"
              />
            </div>
            <div
              v-if="!showFromValidated"
              key="design-performance"
              class="column-cell"
              style="width: 280px"
            >
              <div v-if="showPerformanceTypeColumn(line.id)">
                <PerformanceInput
                  v-if="selectedLines[line.id]"
                  :pending="pending"
                  :inherited-performance="inheritedDesignSpeed[line.id]"
                  :have-inherited-data="haveDataFromGroup(line.id)"
                  :value.sync="designSpeed[line.id]"
                />
              </div>
            </div>
            <div
              v-else
              key="validated-performance"
              class="column-cell"
              style="width: 280px"
            >
              <div v-if="showPerformanceTypeColumn(line.id)">
                <PerformanceInput
                  v-if="selectedLines[line.id]"
                  :pending="pending"
                  :inherited-performance="inheritedValidatedSpeed[line.id]"
                  :have-inherited-data="haveDataFromGroup(line.id)"
                  :value.sync="validatedSpeed[line.id]"
                />
              </div>
            </div>
            <div
              v-if="displayLabour"
              class="column-cell"
              style="width: 280px"
            >
              <ExpectedEffortInput
                v-if="selectedLines[line.id]"
                :pending="pending"
                :inherited-effort="inheritedExpectedEffort[line.id]"
                :have-inherited-data="haveDataFromGroup(line.id)"
                :value.sync="expectedEffort[line.id]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="text-center">
      <Error
        v-if="error"
        :message="error"
      />
      <SaveButton
        :disabled="pending"
        :pending="pending || initPending"
        size="lg"
        @cancel="$emit('cancelled')"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PerformanceTypeSwitch from '@/components/utils/PerformanceTypeSwitch';
import ImportStatus from '@/components/utils/ImportStatus';
import { elementCapabilities } from '@/utils/dictionary';
import FlowInput from './FlowInput';
import PerformanceInput from './PerformanceInput';
import ExpectedEffortInput from './ExpectedEffortInput';

export default {
  props: {
    skuId: String,
    skuGroupId: String,
    showImportResult: {
      type: Boolean,
      default: false,
    },
    importedLineConfigurations: Array,
  },
  data: () => ({
    designSpeed: {},
    validatedSpeed: {},
    expectedEffort: {},
    selectedAreas: {},
    selectedLines: {},
    lineConfigs: {},
    showFromValidated: false,
    inheritedLineConfigs: {},
    inheritedSelectedLines: {},
    inheritedDesignSpeed: {},
    inheritedValidatedSpeed: {},
    inheritedExpectedEffort: {},
    lineConfigurationImportResult: {},
    pending: false,
    initPending: false,
    linePending: {},
    unit: null,
    selectedTime: null,
    error: null,
  }),
  components: {
    FlowInput,
    PerformanceTypeSwitch,
    ExpectedEffortInput,
    PerformanceInput,
    ImportStatus,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('plant', [
      'lines',
      'linesWithCapabilities',
      'areas',
      'structure',
      'displayLabour',
      'elementsOrder',
    ]),
    ...mapGetters('element', [
      'elementProperties',
      'hasCapabilities',
      'hasSomeCapabilities',
    ]),
    sortedAreas() {
      return [...this.areas].sort((a, b) => {
        if (!this.elementsOrder[a.id]) return 1;
        if (!this.elementsOrder[b.id]) return -1;

        return this.elementsOrder[a.id] - this.elementsOrder[b.id];
      });
    },
  },
  watch: {
    selectedLines: {
      deep: true,
      handler() {
        this.areas.forEach(({ id }) => {
          const allLinesSelected = this.getLinesForArea(id).every(x => this.selectedLines[x.id]);
          if (allLinesSelected) {
            this.$set(this.selectedAreas, id, true);
          } else {
            this.$set(this.selectedAreas, id, false);
          }
        });
      },
    },
  },
  methods: {
    ...mapActions('element', ['getElementsProperties']),
    ...mapActions([
      'getSku',
      'getSkuGroup',
      'getSkuLinesPerformance',
      'setSkuPerformances',
      'setSkuGroupLinePerformance',
      'setSkuGroupLineConfiguration',
      'setSkuLineConfiguration',
    ]),
    showPerformanceTypeColumn(elementId) {
      return this.hasSomeCapabilities(elementId, [
        elementCapabilities.OEE,
        elementCapabilities.PRODUCTION,
      ]);
    },
    haveDataFromGroup(lineId) {
      return !!(this.inheritedSelectedLines[lineId]
        && (this.inheritedValidatedSpeed[lineId]
        || this.inheritedDesignSpeed[lineId]
        || this.inheritedLineConfigs[lineId]
        || this.inheritedExpectedEffort[lineId]));
    },
    getLinesForArea(areaId) {
      return (this.linesWithCapabilities([
        elementCapabilities.ACTIVITIES,
      ]) || [])
        .filter(x => this.structure.parent(x.id) === areaId)
        .sort((a, b) => {
          if (!this.elementsOrder[a.id]) return 1;
          if (!this.elementsOrder[b.id]) return -1;

          return this.elementsOrder[a.id] - this.elementsOrder[b.id];
        });
    },
    toggleAllInArea(areaId, value) {
      this.$set(this.selectedAreas, areaId, value);
      this.getLinesForArea(areaId).forEach(({ id }) => {
        this.$set(this.selectedLines, id, value || this.inheritedSelectedLines[id]);
      });
    },
    designSpeedProperty(lineId) {
      return this.elementProperties(lineId)?.find(
        x => x.name.toLowerCase() === 'DesignSpeed'.toLowerCase(),
      );
    },
    validatedSpeedProperty(lineId) {
      return this.elementProperties(lineId)?.find(
        x => x.name.toLowerCase() === 'ValidatedSpeed'.toLowerCase(),
      );
    },
    defaultUnitProperty(lineId) {
      return this.elementProperties(lineId)?.find(
        x => x.name.toLowerCase() === 'DefaultUnit'.toLowerCase(),
      );
    },
    getInheritedData(skuGroupId) {
      this.getSkuGroup({
        params: {
          plantId: this.plantId,
          skuGroupId,
        },
      })
        .then(({ data }) => {
          this.fillInheritedLineConfiguration(data.lines || []);
        });
    },
    async request() {
      if (!this.lines) return;

      this.initPending = true;
      const elementProps = await this.getElementsProperties({
        params: {
          plantId: this.plantId,
          query: {
            id: this.lines.map(l => l.id),
          },
        },
      });

      elementProps.data.forEach(({ elementId }) => {
        const designSpeed = this.designSpeedProperty(elementId);
        const validatedSpeed = this.validatedSpeedProperty(elementId);
        const defaultUnit = this.defaultUnitProperty(elementId);

        this.$set(this.designSpeed, elementId, {});
        this.$set(this.validatedSpeed, elementId, {});
        this.$set(this.expectedEffort, elementId, {});

        if (defaultUnit) {
          const defaultPerformance = `${(defaultUnit.value || '').toLowerCase()}/h`;
          // default unit as default
          this.$set(this.designSpeed[elementId], 'unit', defaultPerformance);
          this.$set(this.validatedSpeed[elementId], 'unit', defaultPerformance);
          this.$set(this.expectedEffort[elementId], 'volumeUnit', defaultUnit.value || '');
        }

        if (designSpeed && designSpeed.unit && designSpeed.unit.includes('/')) {
          this.$set(this.designSpeed[elementId], 'unit', designSpeed.unit);
        }

        if (validatedSpeed && validatedSpeed.unit && validatedSpeed.unit.includes('/')) {
          this.$set(this.validatedSpeed[elementId], 'unit', validatedSpeed.unit);
        }
      });

      if (this.skuId) {
        this.getSku({
          params: {
            plantId: this.plantId,
            skuId: this.skuId,
          },
        })
          .then(async ({ data }) => {
            if (data.skuGroupId) {
              this.getInheritedData(data.skuGroupId);
            }
            this.fillLineConfiguration(data.lines || []);
          })
          .then(() => {
            if (!this.showImportResult || !this.importedLineConfigurations) return;
            this.importedLineConfigurations.forEach(ip => {
              this.fillImportStatus(ip);
            });
          })
          .finally(() => {
            this.initPending = false;
          });
      } else if (this.skuGroupId) {
        this.getSkuGroup({
          params: {
            plantId: this.plantId,
            skuGroupId: this.skuGroupId,
          },
        })
          .then(({ data }) => {
            this.fillLineConfiguration(data.lines || []);
          })
          .then(() => {
            if (!this.showImportResult || !this.importedLineConfigurations) return;
            this.importedLineConfigurations.forEach(ip => {
              this.fillImportStatus(ip);
            });
          })
          .finally(() => {
            this.initPending = false;
          });
      }
    },
    fillLineConfiguration(list) {
      list.forEach(lp => {
        if (lp.designedPerformance > 0) {
          this.$set(this.designSpeed, lp.lineId, {
            value: lp.designedPerformance,
            unit: lp.designedPerformanceUnit,
          });
        }

        if (lp.validatedPerformance > 0) {
          this.$set(this.validatedSpeed, lp.lineId, {
            value: lp.validatedPerformance,
            unit: lp.validatedPerformanceUnit,
          });
        }

        if (lp.expectedManhours > 0) {
          this.$set(this.expectedEffort, lp.lineId, {
            manhours: lp.expectedManhours,
            volume: lp.expectedManhoursVolume,
            volumeUnit: lp.expectedManhoursVolumeUnit,
          });
        }

        this.$set(this.lineConfigs, lp.lineId, lp.flowId);
        this.$set(this.selectedLines, lp.lineId, true);
      });
    },
    fillInheritedLineConfiguration(list) {
      list.forEach(lp => {
        if (lp.designedPerformance > 0) {
          this.$set(this.inheritedDesignSpeed, lp.lineId, {
            value: lp.designedPerformance,
            unit: lp.designedPerformanceUnit,
          });
        }

        if (lp.validatedPerformance > 0) {
          this.$set(this.inheritedValidatedSpeed, lp.lineId, {
            value: lp.validatedPerformance,
            unit: lp.validatedPerformanceUnit,
          });
        }

        if (lp.expectedManhours > 0) {
          this.$set(this.inheritedExpectedEffort, lp.lineId, {
            manhours: lp.expectedManhours,
            volume: lp.expectedManhoursVolume,
            volumeUnit: lp.expectedManhoursVolumeUnit,
          });
        }

        this.$set(this.inheritedLineConfigs, lp.lineId, lp.flowId);
        this.$set(this.selectedLines, lp.lineId, true);
        this.$set(this.inheritedSelectedLines, lp.lineId, true);
      });
    },
    fillImportStatus(importResult) {
      const lc = importResult.lineConfigurationInputModel;

      if (lc.designedPerformance) {
        this.$set(this.designSpeed, lc.lineId, {
          value: lc.designedPerformance.value,
          unit: lc.designedPerformance.unit,
        });
      }

      if (lc.validatedPerformance) {
        this.$set(this.validatedSpeed, lc.lineId, {
          value: lc.validatedPerformance.value,
          unit: lc.validatedPerformance.unit,
        });
      }

      if (lc.expectedEffort) {
        this.$set(this.expectedEffort, lc.lineId, {
          manhours: lc.expectedEffort.manhours,
          volume: lc.expectedEffort.manhoursVolume,
          volumeUnit: lc.expectedEffort.manhoursVolumeUnit,
        });
      }

      this.$set(this.lineConfigs, lc.lineId, lc.flowId);
      this.$set(this.selectedLines, lc.lineId, true);
      this.$set(this.lineConfigurationImportResult, lc.lineId,
        importResult.importResult.resultStatus);
    },
    async save() {
      this.pending = true;
      try {
        if (this.skuId) {
          await this.setSkuLineConfiguration({
            params: {
              plantId: this.plantId,
              skuId: this.skuId,
            },
            data: this.lines.map(({ id }) => ({
              lineId: id,
              isEnabled: this.selectedLines[id] || false,
              flowId: this.lineConfigs[id],
              designedPerformance: !this.selectedLines[id] || !this.designSpeed[id] ? null : {
                value: this.designSpeed[id].value || 0,
                unit: this.designSpeed[id].unit || '',
              },
              validatedPerformance: !this.selectedLines[id] || !this.validatedSpeed[id] ? null : {
                value: this.validatedSpeed[id].value || 0,
                unit: this.validatedSpeed[id].unit || '',
              },
              expectedEffort: !this.selectedLines[id] || !this.expectedEffort[id] ? null : {
                manhours: this.expectedEffort[id]?.manhours,
                manhoursVolume: this.expectedEffort[id]?.volume,
                manhoursVolumeUnit: this.expectedEffort[id]?.volumeUnit,
              },
            })),
          });

          this.$emit('saved');
        } else if (this.skuGroupId) {
          await this.setSkuGroupLineConfiguration({
            params: {
              plantId: this.plantId,
              skuGroupId: this.skuGroupId,
            },
            data: this.lines.map(({ id }) => ({
              lineId: id,
              isEnabled: this.selectedLines[id] || false,
              flowId: this.lineConfigs[id],
              designedPerformance: {
                value: !this.selectedLines[id] ? 0 : this.designSpeed[id]?.value || 0,
                unit: this.designSpeed[id]?.unit,
              },
              validatedPerformance: {
                value: !this.selectedLines[id] ? 0 : this.validatedSpeed[id]?.value || 0,
                unit: this.validatedSpeed[id]?.unit || '',
              },
              expectedEffort: !this.selectedLines[id] || !this.expectedEffort[id] ? null : {
                manhours: this.expectedEffort[id]?.manhours,
                manhoursVolume: this.expectedEffort[id]?.volume,
                manhoursVolumeUnit: this.expectedEffort[id]?.volumeUnit,
              },
            })),
          });

          this.$emit('saved');
        }
      } catch (e) {
        this.error = e?.detail || e?.message || e;
      } finally {
        this.pending = false;
      }
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.item-line-configuration {
  padding-left: 0px;
  padding-right: 10px;
}

.column-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.1;
  padding: 0 15px;
}

.line-configuration-row {
  box-shadow: 2px 4px 8px #efefef;
  border: 1px solid #eee;
  padding: 10px 5px;
  margin-top: 5px;
  border-radius: 10px;
  position: relative;
}

.row-width {
  width: 1045px;
}

.inherited-from-group {
  position: absolute;
  left: -30px;
  color: $grey;
}

.column-cell {
  border-left: 1px solid #aaa;
  padding: 5px 15px;
}

.component-row {
  display: flex;
  min-width: 185px;
}

.space-width {
  max-width: 50px;
}

.title-size {
  line-height: 1;
}

.line-spacing {
  line-height: 1.3;
}

.slash-spacing {
  font-size: 24px;
  font-weight: 200;
}

.label {
  font-size: 12px;
  font-weight: 600;
}

.table th,
.table td {
  padding: 0.3rem;
  vertical-align: middle;
  border: 0;
}

@media screen and (max-width: 765px) {
  .item-line-configuration {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
