import axios from '@/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    items: null,
    skuGroups: [],
  },
})
  .get({
    action: 'getSkus',
    property: 'items',
    path: ({ plantId }) => `/v2/plant/${plantId}/skus`,
    onSuccess: (s, { data }, _, { params }) => {
      if (!params.query || !params.query.lineId) {
        s.items = data;
      }
    },
  })
  .get({
    action: 'getSku',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}`,
  })
  .post({
    action: 'createSku',
    path: ({ plantId }) => `/v2/plant/${plantId}/sku`,
    onSuccess: (s, { data }) => {
      if (s.items) {
        s.items.push(data);
      }
    },
  })
  .put({
    action: 'updateSku',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}`,
    onSuccess: (s, { data }) => {
      if (s.items) {
        const index = s.items.findIndex(x => x.id === data.id);
        Vue.set(s.items, index, data);
      }
    },
  })
  .delete({
    action: 'deleteSku',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}`,
    onSuccess: (s, _, a, { params }) => {
      if (s.items) {
        s.items = s.items.filter(x => x.id !== params.skuId);
      }
    },
  })

  /* Components */
  .get({
    action: 'getSkuComponents',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}/components`,
  })
  .post({
    action: 'addSkuComponent',
    path: ({ plantId, skuId, componentId }) => `/v2/plant/${plantId}/sku/${skuId}/component/${componentId}`,
  })
  .put({
    action: 'updateSkuComponent',
    path: ({ plantId, skuId, componentId }) => `/v2/plant/${plantId}/sku/${skuId}/component/${componentId}`,
  })
  .delete({
    action: 'deleteSkuComponent',
    path: ({ plantId, skuId, componentId }) => `/v2/plant/${plantId}/sku/${skuId}/component/${componentId}`,
  })

  /* Sku groups */
  .get({
    action: 'getPlantSkuGroups',
    path: ({ plantId }) => `/v2/plant/${plantId}/skugroups`,
    onSuccess: (s, { data }) => {
      s.skuGroups = data.sort((a, b) => a.name.localeCompare(b.name));
    },
  })
  .get({
    action: 'getSkuGroups',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}/groups`,
  })
  .get({
    action: 'getSkuGroup',
    path: ({ plantId, skuGroupId }) => `/v2/plant/${plantId}/skugroup/${skuGroupId}`,
  })
  .post({
    action: 'addSkuGroup',
    path: ({ plantId }) => `/v2/plant/${plantId}/skugroup`,
    onSuccess: (s, { data }) => {
      if (s.skuGroups
          && !s.skuGroups.some(x => x.id === data.id)) {
        s.skuGroups.push(data);
      }
    },
  })
  .put({
    action: 'updateSkuGroup',
    path: ({ plantId, groupId }) => `/v2/plant/${plantId}/skugroup/${groupId}`,
    onSuccess: (s, { data }) => {
      if (s.skuGroups) {
        const group = s.skuGroups.find(x => x.id === data.id);
        group.name = data.name;
      }
    },
  })
  .delete({
    action: 'deleteSkuGroup',
    path: ({ plantId, groupId }) => `/v2/plant/${plantId}/skugroup/${groupId}`,
    onSuccess: (s, _, a, { params }) => {
      if (s.skuGroups) {
        s.skuGroups = s.skuGroups.filter(x => x.id !== params.skuId);
      }
    },
  })
  .put({
    action: 'deleteSkuBulk',
    path: ({ plantId }) => `/v2/plant/${plantId}/skus/delete`,
  })
  .put({
    action: 'setSkusGroup',
    path: ({ plantId, groupId }) => `/v2/plant/${plantId}/skugroup/${groupId}/sku`,
    onSuccess: (s, _, a, { params, data }) => {
      s.items.filter(item => data.some(id => id === item.id))
        .forEach(x => {
          // eslint-disable-next-line no-param-reassign
          x.skuGroupId = params.groupId;
        });
    },
  })
  .post({
    action: 'importItems',
    path: ({ plantId }) => `/v2/plant/${plantId}/skus/import`,
  })
  .get({
    action: 'exportItems',
    path: ({ plantId }) => `/v2/plant/${plantId}/skus/export`,
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getSkuGroupLinesPerformance',
    path: ({ plantId, skuGroupId }) => `/v2/plant/${plantId}/skugroup/${skuGroupId}/lines/performance`,
  })
  .put({
    action: 'setSkuPerformances',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}/performances`,
  })
  .put({
    action: 'setSkuGroupLinePerformance',
    path: ({ plantId, skuGroupId }) => `/v2/plant/${plantId}/skugroup/${skuGroupId}/performance`,
  })
  .put({
    action: 'setSkuGroupLineConfiguration',
    path: ({ plantId, skuGroupId }) => `/v2/plant/${plantId}/skugroup/${skuGroupId}/lineconfiguration`,
  })
  .put({
    action: 'setSkuLineConfiguration',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}/lineconfiguration`,
  })
  .get({
    action: 'getSkuValidationErrors',
    path: ({ plantId, skuId }) => `/v2/plant/${plantId}/sku/${skuId}/validate`,
  })
  .getStore();

store.mutations.clearSkus = s => {
  s.items = null;
};

export default store;
