var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative",style:({
    'padding-bottom': _vm.displayAxis ? '20px' : null
  })},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('div',{staticClass:"worklog-wrapper pb-2",attrs:{"id":`worklog${_vm.$uId}`},on:{"mouseleave":_vm.mouseLeave,"mousemove":function($event){$event.stopPropagation();return _vm.track.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();return _vm.startZoom.apply(null, arguments)},"dblclick":_vm.doubleClick}},[_c('canvas',{ref:"chart",staticStyle:{"width":"100%","height":"100%","border-radius":"10px"},attrs:{"height":_vm.isMobile && '250' || '50',"width":"5000"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursor),expression:"cursor"}],ref:"cursor",staticClass:"cursor"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursor && !_vm.displayAxis),expression:"cursor && !displayAxis"}],ref:"cursorDate",staticClass:"cursor-date"},[_vm._v(" "+_vm._s(_vm.cursor)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zooming),expression:"zooming"}],ref:"zoom-box",staticClass:"zoom-box",style:({
          left: `${_vm.zoomLeft}px`,
          width: `${_vm.zoomWidth}px`
        })})]),(_vm.showTools)?_c('div',{staticClass:"toolbox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoomedIn),expression:"zoomedIn"}],staticClass:"worklog-tool zoom-out",on:{"click":function($event){$event.stopPropagation();return _vm.zoomOut.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-search-minus"})]),_c('div',{staticClass:"d-flex"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoomedIn),expression:"zoomedIn"}],staticClass:"worklog-tool w-50 move-left",on:{"click":function($event){$event.stopPropagation();return _vm.moveLeft.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoomedIn),expression:"zoomedIn"}],staticClass:"worklog-tool w-50 move-right",on:{"click":function($event){$event.stopPropagation();return _vm.moveRight.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-chevron-right"})])])]):_c('div',{staticClass:"toolbox"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursor && _vm.popoverContent.start),expression:"cursor && popoverContent.start"}],ref:"popover",staticClass:"popover show bs-popover-top worklog-popover",staticStyle:{"position":"absolute","top":"-10px"},style:({
        'background-color': _vm.popoverColor,
      }),attrs:{"id":`worklog-popover${_vm.$uId}`}},[_c('div',{staticClass:"popover-loss-info"},[(_vm.popoverContent.isPerformanceLoss)?_c('div',[_c('i',{staticClass:"fas fa-tachometer-alt"})]):(_vm.popoverContent.isAvailabilityLoss)?_c('div',[_c('i',{staticClass:"fas fa-plug"})]):(_vm.popoverContent.isUtilizationLoss)?_c('div',[_c('i',{staticClass:"fas fa-calendar-minus"})]):(_vm.popoverContent.isQualityLoss)?_c('div',[_c('i',{staticClass:"fas fa-clipboard-check"})]):_vm._e()]),_c('div',{staticClass:"popover-body text-center"},[(_vm.popoverContent.workState)?_c('StateBadge',{attrs:{"state":_vm.popoverContent.workState}}):_vm._e(),((!_vm.popoverContent.rootWorkLog || _vm.popoverContent.rootWorkLog.eventId != _vm.popoverContent.eventId)
            && !_vm.popoverContent.isDefault)?_c('div',{staticClass:"popover-description"},[_c('span',{style:({
              opacity: _vm.popoverContent.isDeleted || _vm.popoverContent.softDeleted ? 0.5 : 1
            })},[_vm._v(" "+_vm._s(_vm.popoverContent.name)+" ")]),(_vm.popoverContent.isDeleted || _vm.popoverContent.softDeleted)?_c('span',{staticClass:"badge badge-pill badge-secondary ml-1",staticStyle:{"background-color":"#F2F2F2","color":"#848484","opacity":"0.7"}},[_c('i',{staticClass:"fas fa-trash-can mr-1"}),_vm._v(" "+_vm._s(_vm.$t('general.deleted'))+" ")]):_vm._e()]):_vm._e(),(_vm.popoverContent.rootWorkLog && _vm.popoverContent.rootWorkLog.workEvent)?_c('div',{staticClass:"popover-rc"},[_c('i',{staticClass:"fas fa-long-arrow-alt-right pr-2"}),_vm._v(" [ "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.elementName(_vm.popoverContent.rootWorkLog.elementId))+" ")]),_vm._v(" ] "),(_vm.popoverContent.rootWorkLog.eventId != _vm.popoverContent.eventId)?_c('StateBadge',{staticStyle:{"margin-bottom":"2px"},style:({
              opacity: _vm.popoverContent.isDeleted || _vm.popoverContent.softDeleted ? 0.5 : 1
            }),attrs:{"state":_vm.popoverContent.rootWorkLog.workState}}):_vm._e(),_vm._v(" "+_vm._s(_vm.popoverContent.rootWorkLog.workEvent.name)+" "),(_vm.popoverContent.isDeleted || _vm.popoverContent.softDeleted)?_c('span',{staticClass:"badge badge-pill badge-secondary ml-1",staticStyle:{"background-color":"#F2F2F2","color":"#848484","opacity":"0.7"}},[_c('i',{staticClass:"fas fa-trash-can mr-1"}),_vm._v(" "+_vm._s(_vm.$t('general.deleted'))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"popover-dates"},[_vm._v(" "+_vm._s(_vm.popoverContent.start)+" - "+_vm._s(_vm.popoverContent.end)),_c('br'),_vm._v(" ("+_vm._s(_vm.popoverContent.duration)+") ")])],1)])]),_c('div',{staticStyle:{"margin-right":"50px","height":"10px"}},[(_vm.displayAxis)?_c('TimeAxis',{attrs:{"timestamp":_vm.timestamp + _vm.utcOffset,"start-date":_vm.currentStartDate + _vm.utcOffset,"end-date":_vm.currentEndDate + _vm.utcOffset,"curr-date-utc":true}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }