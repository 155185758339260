<template>
  <div class="w-100">
    <select
      v-model="innerValue"
      class="form-control-sm w-100"
    >
      <option
        v-for="(tag, index) in currentTags"
        :key="`${tag.edgeDevice}-${tag.nodeId}-${index}`"
        :value="tag.nodeId"
      >
        {{ tag.nodeId }} - {{ tag.value }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'CurrentTagSelect',
  props: {
    value: String,
    currentTags: Array,
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">

</style>
