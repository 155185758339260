<template>
  <div
    :class="activities && activities.activeOrder && 'flex-column justify-content-between'
      || isMobile && 'justify-content-center' || ''"
    class="d-flex h-100 flex-column align-content-center w-100"
  >
    <Offcanvas
      :show.sync="ordersOffcanvas"
      no-bottom-padding
      style="cursor: default; height: 0 !important;"
    >
      <LineOrdersForm
        v-if="ordersOffcanvas"
        :show-offcanvas.sync="ordersOffcanvas"
        :line-id="lineId"
        :order-id="orderId"
        :selected-activity="selectedActivity"
        :hide-complete="false"
        :init-state="initState"
        :hide-pending="false"
        :refresh-number="refresh"
        @close="ordersOffcanvas = false"
        @showProductionStats="currentProductionStatsModal = true"
        @update:selectedId="handleSelectedActivityIdChange"
      />
    </Offcanvas>

    <div
      v-if="activities && (activities.activeOrder || activities.startedActivity)"
      class="current-orders-container pt-md-2 text-center"
    >
      <CurrentActivityRow
        v-if="displayStartedActivities"
        v-bind="activities.startedActivity"
        class="current-order mb-0"
        :is-changeover="isActivityChangeover(activities.startedActivity)"
        @click.native.stop="showActivity(false)"
      />
      <i
        v-if="displayStartedActivities && areCorrelated"
        style="color:grey"
        class="ion ion-md-arrow-down"
      />
      <CurrentActivityRow
        v-if="activities.activeOrder"
        v-bind="activities.activeOrder"
        class="current-order"
        @click.native.stop="showActivity(true)"
      />
      <hr class="mb-2 mt-0 d-md-none">
    </div>
    <div
      v-else
      class="flex-grow-1"
    />

    <div
      class="mx-1 mx-md-0 d-flex w-100 justify-content-center route align-items-center"
      @click.stop="showOrders"
    >
      <div
        class="text-secondary text-center d-flex justify-content-around px-1 align-items-center"
      >
        <div style="width: 18px;">
          <i class="fas fa-calendar-day" />
        </div>
        <div class="pl-1 pr-2">
          {{ $t('menu.activities') }}
          ({{ $t('orders.orders') }})
        </div>
        <div class="align-self-center">
          <div class="badge align-self-center order-progress">
            {{ activities.completed || 0 }} / {{ (activities.released || 0) + (activities.completed || 0) }}
          </div>
        </div>
      </div>
    </div>

    <LModal
      v-if="selectedActivity && routeActivityId === selectedActivity.id"
      :show.sync="productionReconciliation"
      size="xl"
      :full-screen="isMobile"
    >
      <ProductionReconciliation
        :order="selectedActivity"
        hide-order
      />
    </LModal>

    <LModal
      v-if="selectedActivity && routeActivityId === selectedActivity.id"
      :show.sync="reportResources"
      size="xl"
      :full-screen="isMobile"
    >
      <div class="line-name text-fancy">
        {{ elementName(lineId) }}
      </div>
      <hr>
      <ActivityResources
        :line-id="lineId"
        :order="selectedActivity"
        :hide-manual-production="true"
        :activity-id="selectedActivity.id"
        :scope-start="selectedActivity.actualExecution.begin"
        :scope-end="selectedActivity.actualExecution.end"
        :activity-type="selectedActivity.type"
        :order-unit="selectedActivity.orderedQuantity ? selectedActivity.orderedQuantity.unit : ''"
        :human-resources.sync="humanResources"
        :order-production="orderProduction"
        :order-waste="orderWaste"
        @cancel="close"
        @confirm="confirmCurrentProduction"
        @refresh="refresh += 1"
      />
    </LModal>

    <LModal
      :show.sync="currentProductionStatsModal"
      size="xl"
      :full-screen="isMobile"
    >
      <CurrentProductionStats
        :line-id="lineId"
        @close="closeRouteModal"
      />
    </LModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { activityStatus, activityType } from '@/utils/dictionary';
import LineOrdersForm from '@/components/order/manage/LineOrdersForm';
import CurrentProductionStats from '@/components/modals/CurrentProductionStats';
import ActivityResources from '@/components/activity/ActivityResources';
import colors from '@/utils/colors';
import routeModal from '@/mixins/routeModal';
import ProductionReconciliation from '@/components/activity/ProductionReconciliation';
import CurrentActivityRow from '../activities/CurrentActivityRow';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    activities: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ordersOffcanvas: false,
    initState: null,
    refresh: 1,
    currentProductionStatsModal: false,
    selectedActivityId: null,
  }),
  components: {
    ProductionReconciliation,
    CurrentActivityRow,
    LineOrdersForm,
    ActivityResources,
    CurrentProductionStats,
  },
  mixins: [
    routeModal('reportResources'),
    routeModal('humanResources'),
    routeModal('productionReconciliation'),
  ],
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters(['plantId']),
    ...mapGetters('element', ['elementName']),
    ...mapState({
      rootActivities: state => state.activities.activities,
    }),
    selectedActivity() {
      return this.rootActivities.find(a => a.id === this.selectedActivityId);
    },
    displayStartedActivities() {
      return this.activities?.startedActivity && this.activities.startedActivity.type !== activityType.order;
    },
    queryChange() {
      return this.$route.query?.orderNumber;
    },
    areCorrelated() {
      return this.activities?.startedActivity?.correlationId === this.activities?.activeOrder?.correlationId;
    },
    routeActivityId() {
      return this.$route.query?.activityId;
    },
    orderProduction() {
      return this.activities.activeOrder?.producedQuantity?.value;
    },
    orderWaste() {
      return this.activities.activeOrder?.wastedQuantity?.value;
    },
    orderId() {
      return this.initState === activityStatus.started
        ? this.activities.startedActivity?.id : this.activities.activeOrder?.id;
    },
  },
  watch: {
    ordersOffcanvas(val) {
      if (!val && this.queryChange) {
        this.$router.push(`/${this.plantId}/dashboard/line/${this.lineId}/machines`);
      }
    },
    queryChange(val) {
      if (val) {
        this.ordersOffcanvas = true;
      }
    },
  },
  methods: {
    colors,
    close() {
      this.reportResources = false;
    },
    showOrders() {
      this.ordersOffcanvas = true;
      this.initState = null;
    },
    showActivity(isOrder) {
      this.ordersOffcanvas = true;
      this.initState = isOrder ? this.activities.activeOrder?.status : this.activities.startedActivity?.status;
    },
    confirmCurrentProduction() {
      this.reportResources = false;
      this.currentProductionStatsModal = true;
    },
    isActivityChangeover(activity) {
      return activity.type === activityType.changeover;
    },
    handleSelectedActivityIdChange(id) {
      if (!id) this.selectedActivityId = null;
      this.selectedActivityId = id;
    },
  },
  created() {
    if (this.queryChange) {
      this.ordersOffcanvas = true;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";
  @import "../line";

  .jobs-title {
    font-size: 12px;
    line-height: 1;
    font-weight: 300;
  }

  .order-progress {
    padding: 3px 6px !important;
    border-radius: 7px;
    background-color: #f6f6f6;
  }

  .jobs-comment {
    font-size: 10px;
    font-weight: 400;
    padding-left: 5px;
  }

  .jobs-action-box, .components-action-box {
    border: 2px solid rgba(150, 150, 150, 0.2);
    border-radius: 20px;
    padding: 3px 10px;
    transition: box-shadow 300ms;
    font-size: 18px;
    width: 100%;

    &:hover {
      box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
    }
  }

  .components-action-box {
    width: auto;
    line-height: 26px;
    margin-left: 5px;
  }

  .current-order {
    margin: -5px;
    margin-bottom: 10px;
    padding: 5px 20px;
    border-radius: 10px;
    transition: box-shadow 200ms;

    &:hover {
      box-shadow: 0 0 5px rgba(100,100,100,0.5);
    }
  }

  .ready-orders {
    background-color: $orange;
  }

  @media screen and (min-width: 992px) {
    .current-orders-container {
      height: 120px;
    }
    .jobs-action-box {
      max-width: 130px;
    }

    .current-order {
      border-left: 3px solid #5886C1;
      border-radius: 6px;
      padding: 5px 15px;
    }
  }
</style>
