<template>
  <BOverlay
    :show="savingRootCause"
    opacity="0.5"
    spinner-variant="primary"
    class="h-100"
  >
    <div
      v-if="parsedDowntime"
      class="h-100 d-flex flex-column"
    >
      <div
        v-if="isRootRouteVisible"
        class="d-flex flex-column mx-n4 content-wrapper"
        :class="{'no-downtime-edit': !canEditDowntimes}"
      >
        <div class="d-flex justify-content-between align-items-center px-4 title-box">
          <h5 class="mb-0">
            {{ $t('downtimes.downtimeSummary') }}
          </h5>
          <div class="h5 mb-0 text-black-50">
            <i
              v-if="isVisionAvailable"
              class="fas fa-video cursor-pointer p-1 mr-2"
              @click="openVisionPlayer"
            />
            <i
              class="fas fa-times cursor-pointer p-1"
              @click="hideSummary"
            />
          </div>
        </div>
        <hr class="mx-0 mb-0">

        <Transition name="subpage">
          <div
            v-if="showView"
            class="h-100 d-flex flex-column overflow-auto pt-4"
          >
            <DowntimeVerticalSummary
              v-if="parsedDowntime"
              :line-id="lineId"
              :parsed-downtime="parsedDowntime"
              class="px-4 mb-4"
            />

            <VisionCameraGroupedClips
              class="px-4 mb-4"
              :clips="parsedDowntime.clips"
              :start-date="parsedDowntime.startDate"
              :end-date="parsedDowntime.endDate || $now"
              :element-id="parsedDowntime.elementId"
              :root-work-log="parsedDowntime.rootWorkLog"
              :work-event="parsedDowntime.workEvent"
              :is-line="parsedDowntime.isLine"
              :is-vision-active="isVisionAvailable"
            />

            <DowntimeCommentsList
              v-if="parsedDowntime"
              :comments="parsedDowntime.comments"
              class="mt-auto"
            />
          </div>
        </Transition>
      </div>

      <div
        v-else
        class="h-100 d-flex flex-column mx-n4"
      >
        <div class="d-flex justify-content-between align-items-center px-4 title-box">
          <div class="d-flex align-items-center">
            <div
              v-if="canGoBack"
              class="mr-3 h3 mb-0 text-black-50 cursor-pointer"
              style="line-height: 0"
              @click="goBack"
            >
              <i class="fas fa-chevron-circle-left" />
            </div>

            <div>
              <p class="small text-black-50 mb-0">
                {{ activeSubComponent.subTitle }}
              </p>
              <h5 class="mb-0">
                {{ activeSubComponent.title }}
              </h5>
            </div>
          </div>

          <div class="h5 mb-0 text-black-50">
            <i
              class="fas fa-times cursor-pointer p-1"
              @click="hideSummary"
            />
          </div>
        </div>
        <hr class="mx-0 mb-0">

        <div class="px-4 pt-4 content-wrapper overflow-y-auto">
          <KeepAlive>
            <Transition name="subpage">
              <Component
                :is="activeSubComponent.component"
                v-if="showView"
                v-bind="parsedDowntime"
                :worklogs="worklogs"
                :target-element-id="targetElementId"
                :target-state-id="targetStateId"
                :target-work-event-id="targetWorkEventId"
                :line-flow="lineFlow"
                @hide-summary="hideSummary"
              />
            </Transition>
          </KeepAlive>
        </div>
      </div>

      <DowntimeCommentCreateBox
        v-if="isRootRouteVisible && canEditDowntimes"
        :start-date="parsedDowntime.startDate"
        :element-id="parsedDowntime.elementId"
        :start-date-time="parsedDowntime.startDateTime"
        :work-event="parsedDowntime.workEvent"
        :root-work-log-id="rootWorkLogId"
        class="mx-n4"
      />
    </div>
  </BOverlay>
</template>

<script>
import DowntimeCommentCreateBox from '@/components/downtimes/DowntimeCommentCreateBox';
import DowntimeCommentsList from '@/components/downtimes/DowntimeCommentsList';
import DowntimeVerticalSummary from '@/components/downtimes/DowntimeVerticalSummary';
import VisionCameraGroupedClips
  from '@/components/downtimes/subcomponents/common/VisionCameraGroupedClips';
import { plantProperty } from '@/utils/dictionary';
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import {
  PACKOS_DOWNTIME_CHANGE,
  PACKOS_DOWNTIME_MANAGEMENT,
  PACKOS_MANUAL_DOWNTIME,
} from '@core/dict/permissions';

export const downtimeSummaryRoutes = {
  root: '',
  correctiveAction: 'correctiveAction',
  lineMachine: 'lineMachine',
  problem: 'problem',
  reason: 'reason',
  state: 'state',
  duration: 'duration',
  vision: 'vision',
  visionRequestClip: 'visionRequestClip',
  summary: 'summary',
  taxonomy: index => `taxonomy-${index}`,
};

export default {
  name: 'DowntimeSummaryOffcanvas',
  props: {
    lineId: String,
    parsedDowntime: {
      type: Object,
      default: () => ({}),
    },
    worklogs: {
      type: Array,
      default: () => [],
    },
    lineFlow: {
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      navigateTo: this.switchRoute,
      goBack: this.goBack,
      clearRouteStack: this.clearRouteStack,
      overwriteRouteStack: this.overwriteRouteStack,

      setTargetElementId: this.setTargetElementId,
      setTargetStateId: this.setTargetStateId,
      setTargetWorkEventId: this.setTargetWorkEventId,
      selectTaxonomyNode: this.selectTaxonomyNode,
      popTaxonomyNode: this.popTaxonomyNode,
      lastTaxonomyNodeId: () => this.lastTaxonomyNodeId,
      updateRootCause: this.updateRootCause,
      resetSelectTaxonomyNode: this.resetSelectTaxonomyNode,
      workEventsTaxonomies: () => this.workEventsTaxonomies,
    };
  },
  data: () => ({
    routesStack: [''],
    showView: true,

    savingRootCause: false,
    targetElementId: '',
    targetStateId: '',
    targetWorkEventId: '',

    lineMachineTitle: '',
    selectedTaxonomyNodes: [],
    workEventsTaxonomies: [],
  }),
  components: {
    VisionCameraGroupedClips,
    DowntimeCommentCreateBox,
    DowntimeCommentsList,
    DowntimeVerticalSummary,
  },
  computed: {
    ...mapGetters([
      'plantId',
      'propertyDayStart',
    ]),
    ...mapGetters('plant', [
      'plantProperty',
    ]),
    ...mapGetters('core', ['canOnElement']),
    ...mapState({
      workEventTaxonomies: state => state.taxonomies.workEventTaxonomies,
    }),
    ...mapState({
      cameras: state => state.vision.visionElementCameras,
      clips: state => state.vision.visionElementClips,
    }),
    canEditDowntimes() {
      return (this.canOnElement(PACKOS_DOWNTIME_MANAGEMENT, this.lineId)
        || this.canOnElement(PACKOS_DOWNTIME_CHANGE, this.lineId)
        || this.canOnElement(PACKOS_MANUAL_DOWNTIME, this.lineId));
    },
    rootWorkLogId() {
      return this.parsedDowntime?.rootWorkLog?.id || '';
    },
    currentRoute() {
      return this.routesStack[0];
    },
    hasRootCause() {
      if (!this.parsedDowntime) return false;
      return !this.parsedDowntime.isLine && !!this.parsedDowntime.rootWorkLog;
    },
    canGoBack() {
      return this.routesStack.length > 1;
    },
    isRootRouteVisible() {
      return this.currentRoute === downtimeSummaryRoutes.root;
    },
    workEventTaxonomy() {
      const code = this.plantProperty('TAXONOMY_CODE_FOR_REGISTERING_DOWNTIMES');
      return this.workEventTaxonomies.find(t => t.code === code);
    },
    lastTaxonomyNodeId() {
      if (this.selectedTaxonomyNodes.length > 0) {
        return this.selectedTaxonomyNodes[this.selectedTaxonomyNodes.length - 1];
      }
      return null;
    },
    activeSubComponent() {
      if (this.currentRoute.includes('taxonomy')) {
        return {
          subTitle: this.$t('downtimes.step', { step: 2 }),
          title: this.$t('downtimes.selectProblem'),
          component: () => import('@/components/downtimes/subcomponents/DowntimeTaxonomySelector.vue'),
        };
      }
      switch (this.currentRoute) {
        case downtimeSummaryRoutes.lineMachine:
          return {
            title: this.lineMachineTitle,
            subTitle: this.$t('downtimes.step', { step: 1 }),
            component: () => import('@/components/downtimes/subcomponents/DowntimeLineMachineSelector'),
          };
        case downtimeSummaryRoutes.state:
          return {
            title: this.$t('downtimes.selectState'),
            subTitle: this.$t('downtimes.step', { step: 2 }),
            component: () => import('@/components/downtimes/subcomponents/DowntimeStateSelector'),
          };
        case downtimeSummaryRoutes.problem:
          return {
            title: this.$t('downtimes.selectProblem'),
            subTitle: this.$t('downtimes.step', { step: 3 }),
            component: () => import('@/components/downtimes/subcomponents/DowntimeProblemSelector'),
          };
        case downtimeSummaryRoutes.reason:
          return {
            title: this.$t('downtimes.specifyAReason'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeReasonSelector'),
          };

        case downtimeSummaryRoutes.correctiveAction:
          return {
            title: this.$t('downtimes.correctiveActionTaken'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeCorrectiveActionSelector'),
          };
        case downtimeSummaryRoutes.duration:
          return {
            title: this.$t('downtimes.downtimeDuration'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeDurationSelector'),
          };
        case downtimeSummaryRoutes.vision:
          return {
            title: this.$t('downtimes.vision'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeVision'),
          };
        case downtimeSummaryRoutes.visionRequestClip:
          return {
            title: this.$t('downtimes.visionRequestClip'),
            component: () => import('@/components/downtimes/subcomponents/DowntimeVisionRequestClip'),
          };
        default:
          return {};
      }
    },
    parsedDowntimeId() {
      if (!this.parsedDowntime) return null;
      return this.parsedDowntime.id;
    },
    elementCameras() {
      return this.cameras.filter(c => c.elementId === this.parsedDowntime.elementId);
    },
    isVisionAvailable() {
      const camerasAvailable = this.elementCameras.length > 0;
      const clipsAvailable = (this.parsedDowntime?.clips || []).length > 0;
      return this.plantProperty(plantProperty.vision) === 'true'
        && (camerasAvailable || clipsAvailable);
    },
    visionRouteUrl() {
      const {
        elementId,
        startDate,
        startDateTime,
        endDate,
        elementCameras,
      } = this.parsedDowntime;

      const nextDayStart = moment
        .unix(startDate)
        .startOf('day')
        .add(this.propertyDayStart, 'second')
        .unix();
      const start = moment(startDateTime)
        .startOf('day')
        .minute(0)
        .hour(0)
        .add(this.propertyDayStart, 'second');

      let timelineSeconds = (endDate || this.$now) - startDate + 120;
      const oneDayInSeconds = 86400;
      timelineSeconds = timelineSeconds > oneDayInSeconds
        ? oneDayInSeconds
        : timelineSeconds;

      const params = new URLSearchParams({
        elementId,
        rootDate: startDate,
        startDate: startDate - 60,
        playerTime: startDate,
        calendarRootDate: startDate < nextDayStart
          ? start.subtract(1, 'day').format('YYYY-MM-DD HH:mm')
          : start.format('YYYY-MM-DD HH:mm'),
        timelineSeconds,
      });
      const camerasString = [...elementCameras].slice(0, 4)
        .reduce((acc, curr) => `${acc}&cameras=${curr.cameraId}`, '');

      return `/${this.plantId}/vision?${params.toString()}${camerasString}`;
    },
  },
  watch: {
    parsedDowntimeId() {
      this.clearRouteStack();
      this.getWorkEventTaxonomiesBindings();
    },
    routesStack: {
      deep: true,
      handler(v) {
        this.transitionPageSwitch();

        if (v.length === 1) {
          this.resetRootCauseTargets();
        }
      },
    },
    hasRootCause() {
      this.updateLineMachineTitle();
    },
    isRootRouteVisible() {
      this.$store.commit('setSplittingDowntimes', []);
      this.$store.commit('setSplittingDowntimesEditIndex', -1);
    },
  },
  methods: {
    ...mapActions('work', [
      'updateWorkLogProblem',
    ]),
    ...mapActions('taxonomies', ['getWorkEventsTaxonomyBinding']),
    hideSummary() {
      this.$emit('hide-summary');
    },
    switchRoute(route) {
      this.routesStack.unshift(route);
    },
    clearRouteStack() {
      this.routesStack = [''];
    },
    overwriteRouteStack(stack = ['']) {
      this.routesStack = [...stack];
    },
    goBack() {
      if (this.selectedTaxonomyNodes.length > 0) {
        this.popTaxonomyNode();
      } else {
        this.routesStack.shift();
      }
    },
    transitionPageSwitch() {
      this.showView = false;
      this.$nextTick(() => {
        this.showView = true;
      });
    },
    resetRootCauseTargets() {
      this.targetElementId = '';
      this.targetStateId = '';
      this.targetWorkEventId = '';
    },
    setTargetElementId(elementId) {
      this.targetElementId = elementId;
    },
    setTargetStateId(stateId) {
      this.targetStateId = stateId;
    },
    setTargetWorkEventId(workEventId) {
      this.targetWorkEventId = workEventId;
    },
    resetSelectTaxonomyNode() {
      this.selectedTaxonomyNodes = [];
    },
    selectTaxonomyNode(nodeId) {
      this.selectedTaxonomyNodes = [...this.selectedTaxonomyNodes, nodeId];
    },
    popTaxonomyNode() {
      this.selectedTaxonomyNodes.pop();
      this.selectedTaxonomyNodes = [...this.selectedTaxonomyNodes];
    },
    updateRootCause() {
      this.savingRootCause = true;
      const updateData = [{
        workLogStartDate: this.isLine
          ? this.parsedDowntime?.rootWorkLog?.startDateTime
          : this.parsedDowntime.startDateTime,
        elementId: this.isLine ? this.parsedDowntime?.rootWorkLog?.elementId : this.parsedDowntime.elementId,
        targetElementId: this.targetElementId,
        targetWorkEventId: this.targetWorkEventId,
      }];

      this.updateWorkLogProblem({
        params: {
          plantId: this.plantId,
        },
        data: updateData,
      })
        .then(({ data }) => {
          data.operationIds.forEach(id => {
            this.$root.$emit('user-operation-requested', { id, type: 'SetWorkLogProblem' });
          });

          this.$store.commit('work/worklogUpdate',
            [{
              workLogId: this.isLine ? this.rootWorkLog.id : this.id,
              elementId: this.isLine ? this.rootWorkLog.elementId : this.elementId,
              rootWorkLog: {
                elementId: this.targetElementId,
                eventId: this.targetWorkEventId,
              },
            }]);
          this.resetRootCauseTargets();
          this.$emit('update-root-cause');
        })
        .catch(({ response }) => {
          this.$root.$bvToast.toast(response.data, {
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-bottom-left',
          });
        })
        .finally(() => {
          this.savingRootCause = false;
        });
    },
    updateLineMachineTitle() {
      if (this.hasRootCause) {
        this.lineMachineTitle = this.$t('downtimes.selectRootLineMachine');
      } else {
        this.lineMachineTitle = this.$t('downtimes.selectLineMachine');
      }
    },
    async getWorkEventTaxonomiesBindings() {
      if (!this.workEventTaxonomy) return;
      this.loadingWorkEventTaxonomiesBindings = true;
      try {
        const { data } = await this.getWorkEventsTaxonomyBinding({
          params: {
            plantId: this.plantId,
            query: {
              taxonomyId: this.workEventTaxonomy?.id,
            },
          },
        });
        this.workEventsTaxonomies = data;
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.title || '-', {
          title: this.$t('error.unableToDownloadData'),
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
      } finally {
        this.loadingWorkEventTaxonomiesBindings = false;
      }
    },
    openVisionPlayer() {
      window.open(this.visionRouteUrl, '_blank');
    },
  },
  created() {
    this.getWorkEventTaxonomiesBindings();
    this.clearRouteStack();
    this.updateLineMachineTitle();
  },
};
</script>

<style lang="scss" scoped>
.title-box {
  min-height: 40px;
  max-height: 40px;
}

.content-wrapper {
  min-height: calc(100% - 85px);
  max-height: calc(100% - 85px);

  &.no-downtime-edit {
    min-height: calc(100%);
    max-height: calc(100%);
  }
}

.subpage-enter-active, .subpage-leave-active {
  transition: all .25s;
}

.subpage-enter, .subpage-leave-to {
  transition: 0s;
  opacity: 0;
  transform: translateX(15px);
}
</style>
