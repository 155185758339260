<template>
  <div style="position: relative; overflow: hidden">
    <div class="row header-row align-items-center">
      <div class="col-xl-2 col-12 mb-3 mb-xl-0 h-100 text-center">
        <h2 class="line-name mb-0 text-fancy">
          {{ name }}
        </h2>

        <div class="health-check">
          <Healthcheck
            :element-id="line.id"
            size="sm"
          />
        </div>
      </div>

      <div class="col-xl-10 col-12">
        <CustomLayoutGrid
          :element-id="line.id"
          :layout-string-value="customLayoutString"
          :current-tags="filteredCurrentTags"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CustomLayoutGrid from '@/components/customLayout/CustomLayoutGrid.vue';
import Healthcheck from '@/components/utils/Healthcheck.vue';

export default {
  name: 'CustomLayoutLine',
  props: {
    line: Object,
  },
  components: {
    Healthcheck,
    CustomLayoutGrid,
  },
  computed: {
    ...mapState('customLayout', {
      elementProperties: s => s.customLayoutElementProperties,
      currentTags: s => s.currentTags,
    }),
    ...mapGetters('customLayout', [
      'elementCustomLayoutString',
    ]),
    ...mapGetters([
      'plantId',
      'screenWidth',
      'isMobile',
      'splitByOrder',
      'splitByShift',
    ]),
    name() {
      return this.line.name;
    },
    properties() {
      return this.elementProperties.find(p => p.elementId === this.line.id)?.properties || [];
    },
    customLayoutString() {
      return this.elementCustomLayoutString(this.line.id);
    },
    filteredCurrentTags() {
      const edgeDeviceId = this.properties.find(p => p.name === 'EdgeDevice')?.value;
      if (!edgeDeviceId) return [];
      return this.currentTags.filter(t => t.edgeDevice === edgeDeviceId);
    },
  },
  beforeDestroy() {
    const el = document.querySelector('.scope-container');
    if (!el) return;
    el.style.display = 'block';
  },
  created() {
    this.$emit('pageChange', 'customLayout');

    setTimeout(() => {
      const el = document.querySelector('.scope-container');
      if (!el) return;
      el.style.display = 'none';
    }, 10);
  },
};
</script>

<style scoped lang="scss">
  .line-name {
    margin: 10px -5px auto -5px;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
  }

  .header-row {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 7px;
    border-top: 5px solid rgba(180, 180, 180, 0.1);

    &.disabled {
      opacity: 0.8;
    }
  }

  .health-check {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
</style>
