<template>
  <div
    :class="{
      alien,
      'custom-layout': showWithCustomLayout,
    }"
    :style="{
      'border-color': borderColor,
      'box-shadow': `0px 0px 5px ${showDowntimesSections ? borderColor : 'unset'}`,
    }"
    class="machine"
  >
    <div
      v-if="alien"
      class="alien-icon"
    >
      <i class="ion ion-ios-git-pull-request" />
    </div>

    <div
      v-if="machine && !machine.isDummy"
      class="d-flex is-base-signs"
    >
      <div
        v-if="machine.isBaseAvailability"
        v-tippy
        :content="$t('monitoring.segment.baseAvailability')"
        class="sign"
      >
        A
      </div>
      <div
        v-if="machine.isBaseQuantity"
        v-tippy
        :content="$t('monitoring.segment.baseQuantity')"
        class="sign"
      >
        <i class="ion ion-ios-trending-up" />
      </div>
      <div
        v-if="hasMachineCameras"
        v-tippy
        :content="$t('vision.hasCamera')"
        class="sign"
      >
        <i
          class="fas fa-video"
          style="font-size: 8px"
        />
      </div>
    </div>

    <h3 class="machine-title mb-0 d-flex justify-content-between">
      <span class="flex-grow-1">
        {{ name }}
      </span>
      <Healthcheck
        v-if="machine && !machine.isDummy"
        :element-id="id"
        size="sm"
      />
    </h3>

    <div v-if="machine">
      <div class="d-flex justify-content-between w-100">
        <div
          v-if="currentState && showDowntimesSections && !machine.isDummy"
          :style="{color: textColor}"
          class="current-state"
        >
          <div class="d-flex">
            <div>
              <div class="state-duration">
                {{ currentState.startDate ? CalcDuration(currentState.startDate) : '' }}
              </div>
              <div class="current-state-desc">
                <StateBadge :state="workState" />
              </div>
            </div>
            <div
              v-if="haveCorrectiveActions"
              style="width: 25px; padding-left: 10px"
            >
              <CorrectiveActionPopover
                :corrective-actions="currentState.correctiveActions"
                @modal="caModal = $event"
              >
                <div class="state-icon wand">
                  <i class="icon-corrective-action" />
                </div>
              </CorrectiveActionPopover>
            </div>
          </div>

          <div class="current-work-event">
            {{ workEventDescription }}
          </div>
          <div
            v-if="rootEvent"
            style="font-size: 10px"
          >
            <i class="fas fa-long-arrow-alt-right pr-1" />
            [ {{ rootMachine }} ]
            {{ rootEvent.description }}
          </div>
        </div>
        <div
          v-else-if="!machine.isDummy"
          style="height: 30px"
        />

        <div>
          <CurrentPower
            v-if="!machine.isDummy && displayEnergy && energy"
            v-bind="energy"
            :production="production ? production.done : null"
            class="current-power"
            style="width: 70px;"
            size="sm"
          />
        </div>
      </div>

      <span
        v-if="machine.isDummy"
        style="font-size: 9px"
        class="badge border text-muted"
      >
        DUMMY
      </span>
    </div>

    <div
      v-else-if="!showWithCustomLayout"
      class="text-center w-100 mt-2 text-uppercase text-secondary"
    >
      {{ $t('general.noData') }}
    </div>

    <hr
      v-if="showWithCustomLayout"
      class="my-2"
    >

    <AssetParameters
      v-if="machine && !machine.isDummy && !showWithCustomLayout && showProductionSections"
      class="mt-2"
      :machine="machine"
      :performance="performance"
      :production="production"
      :type="type"
    />

    <CustomLayoutGrid
      v-if="machine && showWithCustomLayout"
      :element-id="id"
      :current-tags="currentTags"
      :layout-string-value="machineCustomLayoutString"
      class="pb-1 mt-n2"
    />

    <hr class="my-2">

    <MachineRoutes
      :element-id="id"
      :pareto-states="paretoStates"
      :machine="machine || {}"
      :current-state="currentState"
      @switch-state="$emit('switch-state', $event)"
      @downtimes="$emit('downtimes')"
      @current="$emit('current')"
    />

    <div
      v-if="showDowntimesSections"
      class="row no-gutters mt-2"
    >
      <div class="col-12">
        <StatePareto
          :pareto-states="sortStates(paretoStates)"
          class="round-chart"
        />
      </div>
    </div>

    <slot />
    <Error
      v-if="error"
      :message="error.detail"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import colors from '@/utils/colors';
import { elementCapabilities, plantProperty } from '@/utils/dictionary';
import CurrentPower from '@/components/line/modules/CurrentPower';
import CorrectiveActionPopover from '@/components/popovers/CorrectiveActions';
import CustomLayoutGrid from '@/components/customLayout/CustomLayoutGrid';
import AssetParameters from './AssetParameters';
import StatePareto from '../charts/state-pareto/StateParetoChart';
import CalcDuration from '../../utils/calcDuration';
import Healthcheck from '../utils/Healthcheck';
import MachineRoutes from './MachineRoutes';

export default {
  props: {
    name: String,
    id: {
      type: String,
      required: true,
    },
    currentState: Object,
    lineId: String,
    type: String,
    paretoStates: Array,
    meta: Object,
    energy: Object,
    machine: Object,
    production: Object,
    performance: Object,
    alien: Boolean,

    // SHOW AS CUSTOM LAYOUT VIEW PROPS
    currentTags: Array,
    machineCustomLayoutString: String,
    showWithCustomLayout: Boolean,
  },
  data: () => ({
    modalShown: false,
    message: false,
    showLiveVideo: false,
    error: null,
  }),
  components: {
    CustomLayoutGrid,
    StatePareto,
    Healthcheck,
    CurrentPower,
    AssetParameters,
    CorrectiveActionPopover,
    MachineRoutes,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('work', {
      workEventGetter: 'workEvent',
      workStateGetter: 'workState',
    }),
    ...mapGetters('plant', ['plantProperty', 'displayEnergy']),
    ...mapGetters('element', [
      'elementName',
      'hasCapabilities',
    ]),
    ...mapState({
      tenantCameras: state => state.vision.visionTenantCameras,
    }),
    showDowntimesSections() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.DOWNTIMES,
      ]);
    },
    showProductionSections() {
      return this.hasCapabilities(this.id, [
        elementCapabilities.PRODUCTION,
      ]);
    },
    showVision() {
      return this.plantProperty(plantProperty.vision) === 'true';
    },
    haveCorrectiveActions() {
      return this.currentState
        && this.currentState.correctiveActions
        && this.currentState.correctiveActions.length > 0;
    },
    workEventDescription() {
      if (!this.workEvent || this.workEvent.isDefault) return '';
      return this.workEvent.name;
    },
    workEvent() {
      if (!this.currentState || !this.currentState.eventId) return null;
      return this.workEventGetter(this.currentState.eventId, this.id);
    },
    rootEvent() {
      const { currentState } = this;
      if (!currentState.rootWorkLog) return null;
      const { elementId } = currentState.rootWorkLog;
      return this.workEventGetter(currentState.rootWorkLog.eventId, elementId);
    },
    rootMachine() {
      const { currentState } = this;
      if (!currentState.rootWorkLog) return null;
      return this.elementName(currentState.rootWorkLog.elementId);
    },
    workState() {
      if (!this.workEvent) return null;
      return this.workStateGetter(this.workEvent.workStateId);
    },
    borderColor() {
      if (!this.showDowntimesSections) {
        return tinycolor(colors('gray')).setAlpha(0.2).toRgbString();
      }

      if (this.machine && this.machine.isDummy) {
        return tinycolor(colors('green')).setAlpha(0.6).toRgbString();
      }

      const color = this.workState ? colors(this.workState.colorCode) : '#333';
      return tinycolor(color).setAlpha(0.6).toRgbString();
    },
    textColor() {
      return this.workState ? colors(this.workState.colorCode) : '#333';
    },
    hasMachineCameras() {
      if (!this.tenantCameras) return false;

      const cameras = this.tenantCameras;
      const elementGuidsWithCameras = cameras.map(c => c.elementId);

      return elementGuidsWithCameras.includes(this.id);
    },
  },
  methods: {
    colors,
    CalcDuration,
    openProcessParams() {
      const { plantId, id } = this;

      this.$router.push(`/${plantId}/element/${id}/params`);
    },
    sortStates(states) {
      return states ? states.slice().sort((a, b) => b.timeInPercents - a.timeInPercents) : null;
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .round-chart {
    height: 20px;
  }

  .state-icon {
    transition: border-color 500ms, background-color 500ms, transform 300ms;
    text-align: center;
    font-size: 13px;
    color: initial;
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 50%;
    width: 25px;
    color: #585858;
    height: 25px;
    margin: 5px 0 0;
    line-height: 25px;
    cursor: pointer;
    &.sm {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
  .state-icon:hover {
    transform: scale(1.2);
    border-color: #333;
    background-color: rgba(#333, 0.1);
  }

  .slide-enter-active {
    transition: max-height .8s ease-out, opacity .4s ease-out;
  }
  .slide-leave-active {
    transition: max-height .4s ease-in, opacity .2s ease-in;
  }

  .slide-leave, .slide-enter-to {
    max-height: 500px;
    opacity: 1;
  }

  .is-base-signs {
    top: -21px;
    position: absolute;

    > div {
      font-weight: 400;
      color: hsla(0,0%,39.2%,.8);
      border: 1px solid hsla(0,0%,39.2%,.2);
      display: block;
      text-align: center;
      cursor: default;
      margin-right: 2px;
      width: 15px;
      height: 15px;
      line-height: 13px;
      font-size: 10px;
      border-radius: 20px;
    }
  }

  .mach-action {
    width: 50px;
    text-align: center;
    font-size: 22px;
    line-height: 1;
    i {
      color: #333;
      transition: transform 200ms;
    }
  }
  .mach-action:hover {
    i {
      transform: scale(1.4);
    }
  }

  .slide-enter, .slide-leave-to {
    max-height: 0;
    opacity: 0;
  }

  .current-work-event {
    font-size: 12px;
    line-height: 1.1;
    padding-top: 4px;
    font-weight: 500;
  }

  .state-duration {
    font-weight: bold;
    font-size: 10px;
  }

  .current-state {
    font-size: 12px;
    font-weight: normal;
  }

  .current-state-desc {
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
  }

  .small-gutters {
    margin-left: -5px;
    margin-right: -5px;
    padding: 0 5px;
  }

  .machine {
    position: relative;
    border-radius: 10px;
    width: 250px;
    min-height: 160px;
    padding: 10px;
    background-color: white;
    border: 1px solid rgba(100, 100, 100, 0.1);

    &.custom-layout {
      width: 190px;
    }

    &.alien {
      opacity: 0.6;
      transition: opacity 500ms;

      &:hover {
        opacity: 1;
      }

      .alien-icon {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: #333;
        color: white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 13px;
        text-align: center;
        i {
          font-size: 11px;
        }
      }
    }

    .machine-title {
      font-size: 16px;
      font-weight: 500;
      margin-top: -2px
    }
  }

  .machine-states {
    height: 20px;
  }

  .state-info {
    display: inline;
    position: absolute;
    right: 0;
    padding: 2px 10px;
    top: 6px;
    font-size: 12px;
  }

  .circle-btn {
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: auto;
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-size: 17px;
  }

  .color-box {
    position: relative;
    top: 1px;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
</style>
