export default {
  'activities': 'Atividades',
  'activityType': 'Tipo de atividade',
  'addActivity': 'Planeje uma nova atividade',
  'order': 'Pedido',
  'plannedStart': 'Início planejado',
  'plannedEnd': 'Fim planejado',
  'cleaning': 'Limpeza',
  'changeover': 'Setup',
  'activityId': 'ID da atividade',
  'activity': 'Atividade',
  'toStart': 'para iniciar',
  'afterStart': 'após início',
  'activityAlreadyExists': 'ID da atividade já existe',
  'changeoverDuration': 'Duração do Setup (minutos)',
  'list': {'order': 'Pedidos', 'cleaning': 'Limpezas', 'changeover': 'Setups'},
  'activityAdded': 'Atividade adicionada',
  'noActivities': 'Sem atividades',
  'status': {
    'released': 'Liberado',
    'draft': 'Esboço',
    'started': 'Iniciado',
    'suspended': 'Suspenso',
    'completed': 'Concluído',
    'activated': 'Ativado',
    'scheduled': 'Agendado',
    'notPlanned': 'Não planejado'
  },
  'schedule': {
    'tableView': 'Tabela',
    'axisView': 'Linha do tempo',
    'full': 'Cheio',
    'collapsed': 'Colapsado',
    'scheduled': 'Agendado',
    'currentHour': 'Fim da hora atual',
    'previousHour': 'Hora anterior',
    'previousShift': 'Turno anterior',
    'currentShift': 'Turno atual'
  },
  'resources': {
    'production': 'Produção',
    'manhours': 'Hora-homem',
    'timeScope': 'escopo de tempo',
    'waste': 'Rejeito',
    'workers': 'Colaboradores',
    'worker': 'Colaborador',
    'history': 'Histórico',
    'noEntries': 'Sem entradas',
    'hours': 'Horas',
    'minutes': 'Minutos'
  },
  'add': 'Adicionar atividade',
  'edit': 'Editar atividade',
  'addChangeover': 'Adicionar setup',
  'step': 'Etapa',
  'general': 'Geral',
  'quantity': 'Quantidade',
  'dates': 'Datas',
  'configuration': 'Configuração',
  'activityStart': 'Início da atividade',
  'activityIdIsTooLong': 'O ID da atividade é muito longo',
  'manage': 'Gerenciar atividades',
  'changeoverType': 'Tipo de setup',
  'editExisting': 'Editar existente',
  'pickALine': 'Escolha uma linha',
  'typeEffort': 'Tipo de esforço',
  'showNotPlanned': 'Mostrar as atividade não planejadas',
  'summary': {
    'summary': 'Resumo',
    'orderTotal': 'Pedidos totais',
    'lineTotal': 'LINHA TOTAL',
    'inLineUnit': 'Unidade em linha',
    'inOrderUnit': 'Unidade em pedido',
    'goodCount': 'Contagem de peças boas',
    'wasteCount': 'Contagem de perda ou de rejeitos',
    'counterTotal': 'Contador Total',
    'performanceGuard': 'Guarda de Desempenho',
    'stateGuard': 'Guarda de Status',
    'performanceGuardTippy': 'Limite de desempenho excedido',
    'missingPackingStructure': 'Falta de estrutura de embalagem',
    'manualReconciliation': 'Reconciliação manual'
  },
  'actions': {
    'finishChangeover': 'Terminar e Iniciar Ordem',
    'abortChangeover': 'Abortar setup',
    'productionReconciliation': 'Reconciliação de produção',
    'finishOrder': 'Finalizar a ordem'
  },
  'validations': {
    'orderNotFound': 'Ordem não encontrada',
    'externalIdError': 'O ID pode conter apenas números, letras, caracteres \'-\', \'/\' e \'_\' e só pode começar com um número ou uma letra'
  },
  'deleteConfirmMessage': 'Tem certeza de que deseja excluir essa atividade?',
  'hasValidationErrors': 'Existem erros de validação',
  'noLine': 'Sem linha',
  'editOrder': 'Editar ordem',
  'editChangeover': 'Editar setup',
  'cannotCreateOrder': 'Não é possível criar ordem',
  'orderExists': 'Existe um pedido com ID de atividade especificado.',
  'endOfLastShift': 'Fim do último turno',
  'endOfLastHour': 'Fim da última hora',
  'productionHistory': 'Histórico de produção',
  'manhoursHistory': 'Histórico de horas de trabalho',
  'changeoverDurationTitle': 'Duração do Setup',
  'expectedEffort': 'Esforço esperado (HH)',
  'expectedChangeoverEffort': 'Esforço de setup previsto (HH)',
  'typeCorrectExpectedEffort': 'O esforço esperado não pode ser negativo',
  'typeCorrectValue': 'O valor não pode ser negativo',
  'typeChangeoverDurationIfChangeoverExpectedEffortGiven': 'A duração esperada do setup é necessária quando é dado o esforço de setup previsto',
  'editMode': 'Modo de edição',
  'validationErrors': {
    'skuError': 'Falta o produto ou foi configurado incorretamente',
    'flowIdError': 'Falta a configuração de fluxo',
    'endDateNotValid': 'A data de término planejada não é válida',
    'lineError': 'Falta a configuração da linha',
    'orderedQuantityError': 'Falta a quantidade solicitada',
    'orderedQuantityUnitError': 'Falta a unidade da quantidade solicitada ou está configurada incorretamente'
  }
};
