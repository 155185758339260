export default {
  'moveHereToGroup': 'Mover a coluna aqui para agrupar',
  'moveHereToHide': 'Mover a coluna aqui para ocultar',
  'sEmptyTable': 'Nenhum dado disponível na tabela',
  'sInfoFiltered': '(filtrado do total de entradas _MAX_)',
  'sLoadingRecords': 'Carregando...',
  'sProcessing': 'Em processamento...',
  'sSearch': 'Pesquisar:',
  'sZeroRecords': 'Nenhum registro correspondente encontrado',
  'oPaginate': {
    'sFirst': 'Primeiro',
    'sLast': 'Último',
    'sNext': 'Próximo',
    'sPrevious': 'Anterior'
  },
  'oAria': {
    'sSortAscending': ': ativar para organizar a coluna em ordem crescente',
    'sSortDescending': ': ativar para organizar a coluna em ordem decrescente'
  },
  'downloadAsXls': 'Baixar como xls'
};
