import { elementType } from '@/utils/dictionary';
import properties from './properties';
import signals from './signals';
import elementState from './element';
import asset from './asset';

export default {
  namespaced: true,
  getters: {
    element: (s, g, rs) => id => rs.plant.cache.elements[id],
    elementName: (s, g) => id => {
      const element = g.element(id);
      return element ? element.name : '?';
    },
    parentName: (s, g, rs, rg) => id => {
      const struct = rg['plant/structure'];
      const parentId = struct.parent(id, 1);
      if (!parentId) return null;
      const node = struct.node(parentId);
      if (!node || parentId.toLowerCase() === rg.plantId.toLowerCase()) return '';
      return node.name;
    },
    elementType: (s, g, rs, rg) => id => (rg['plant/structure'].node(id) || {}).type,
    lineAndMachineName: (s, g, rs, rg) => elementId => {
      if (g.elementType(elementId) === elementType.line) {
        return {
          lineName: g.elementName(elementId),
          machineName: '',
        };
      }
      const lineId = rg['plant/structure'].parent(elementId);
      return {
        lineName: g.elementName(lineId),
        machineName: g.elementName(elementId),
      };
    },
    elementFullName: (s, g, rs, rg) => elementId => {
      if (g.elementType(elementId) !== elementType.machine) {
        return g.elementName(elementId);
      }
      const lineId = rg['plant/structure'].parent(elementId, 1);
      return `${g.elementName(lineId)} / ${g.elementName(elementId)}`;
    },
    findLineId: (s, g, rs, rg) => elementId => {
      if (g.elementType(elementId) === elementType.line) return elementId;

      const struct = rg['plant/structure'];
      return struct.parent(elementId, 1);
    },
    image: (s, g, rs, rg) => id => {
      const img = rs.plant.structure.images.find(x => x.elementId === id);
      if (img && img.image) {
        return img.image;
      }
      const type = g.elementType(id);
      if (type === elementType.line) return `${rg.publicPath}img/line.png`;
      return `${rg.publicPath}img/machine.png`;
    },
    capabilities: (s, g, rs) => id => {
      const el = rs.plant.cache.elements[id];
      return el?.capabilities || [];
    },
    hasCapabilities: (s, g) => (id, capabilities) => capabilities
      .every(c => g.capabilities(id)[c] === 'true'),
    hasSomeCapabilities: (s, g) => (id, capabilities) => capabilities
      .some(c => g.capabilities(id)[c] === 'true'),
  },
  modules: {
    properties,
    signals,
    elementState,
    asset,
  },

};
