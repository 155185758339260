export default {
  report: '报告',
  new: '新的',
  sum: '和',
  time: '时间',
  quantity: '数量',
  aggregated: '聚合',
  detailed: '详细的',
  showNAlarms: '显示 <b>{0}</b> 个最长的警报',
  showOthers: '显示其他的',
  hideOthers: '隐藏其他的',
  sumOfSmaller: '总和其他的',
  dropHereToCreateNew: '拖放此处以创建新图表',
  completedOrders: '已完成订单',
  history: '历史',
  usedEnergy: '已用能源',
  splitByShifts: '按班次拆分',
  addPersonal: '个人仪表板',
  addFactory: '工厂仪表板',
  work: '工作',
  plannedDowntime: '计划',
  unplannedDowntime: '计划外的',
  expected: '预期的',
  rootElement: '根元素',
  performanceImpact: '性能损失',
  availabilityImpact: '可用性损失',
  minorStops: '点点停',
  oeeLoss: {
    oeeLoss: 'OEE损失',
    productionLoss: '生产损失',
    undefinedIssue: '问题类型未定义',
    utilizationLosses: '利用损失',
    availabilityLosses: '可用性损失',
    performanceLosses: '性能损失',
    qualityLosses: '质量损失',
    speedLoss: '速度损失',
    qualityLoss: '质量损失',
    speedIncrease: '提速增益',
    combined: '组合',
    byMachine: '按产线/机器拆分',
    byProblem: '按问题拆分',
    byState: '按状态拆分',
    base: '基准时间',
    time: {
      totalTime: '总时间', scheduledTime: '计划的时间', operatingTime: '工作时间', productionTime: '生产时间', effectiveTime: '有效时间',
    },
  },
  select: {
    allLines: '所有产线', allMachines: '所有机器', selectMachine: '选择机器', selectLine: '选择产线', clear: '清除',
  },
  alarms: {
    description: '描述', numberOfOccurances: '数字', duration: '期间', sumOfSmallerAlarms: '较小警报的总和', noDataInThisPeriod: '本期无数据',
  },
  selectors: {
    period: '时期', moment: '片刻', dateTo: '日期至', dateFrom: '日期从', shift: '班次', day: '日', week: '星期', month: '月', today: '今天', yesterday: '昨天', thisWeek: '本周', previousWeek: '上周', thisMonth: '本月', previousMonth: '上月', selectLineAndMachine: '选择产线和机器', selectLine: '选择产线', selectLineOrMachine: '选择产线或机器', timeFilter: '时间过滤器', lastThreeMonths: '最近 3 个月', selectExactDates: '选择确切日期', selectDatesFromList: '从列表中选择日期', addElement: '将工厂元素添加到报告中',
  },
  types: {
    custom: '定制', availability: '可用性', efficiency: '效率', oee: '设备综合效率', oeeLosses: 'OEE 损失', production: '生产', productionMap: '生产图', downtimes: '停机时间', rootcauses: '停机根本原因', alarms: '警报', column: '柱型图', pie: '饼图', operatorStops: '操作员停止', downtimeDuration: '停机时间', downtimeDurations: '停机时间', reasons: '原因', energy: '能量', energySankey: '桑基图', energyHistory: '使用历史', heatmap: '热图', commentsFromLosses: '损失评论', machines: '机器',
  },
  operatorStop: {
    waiting: '等待行动', fixing: '动作持续时间', stopsBecauseOf: '停止因为', timeSum: '总时长',
  },
  rootcause: { impact: { title: '影响', desc: '（效果持续时间/根本原因持续时间）' }, effectDuration: '效果持续时间', rootcauseDuration: '根本原因持续时间' },
  chart: { toggleFullView: '切换全视图' },
  machines: {
    line: '产线', automatic: '自动机器', manual: '手动机器', dummy: '虚拟机', sum: '总和', aggregate: '总计的',
  },
};
