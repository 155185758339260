<template>
  <div
    :id="`activities-${id}`"
    class="order-box my-1 position-relative"
    :class="{
      'selected': selected,
    }"
    :style="{
      'border-color': statusInfo.color,
    }"
  >
    <div class="row order-section">
      <div class="col-8">
        <p class="order-number">
          <span class="font-weight-bold">
            {{ $t('orders.number') }}:
          </span>
          <span>
            {{ externalId }}
          </span>
        </p>

        <p class="mb-0 order-title">
          {{ description || externalId }}
        </p>
      </div>
      <div class="col-4">
        <p
          v-b-tooltip.bottom="elementName(lineId)"
          class="text-right text-ellipsis element-name"
        >
          {{ elementName(lineId) }}
        </p>
        <div class="d-flex justify-content-end">
          <BBadge
            variant="light"
            style="background-color: #F0F1F3;"
          >
            <i
              style="font-size: 0.9em;"
              :class="activityInfo.icon"
            />
            {{ activityInfo.desc }}
          </BBadge>

          <BBadge
            :style="{
              backgroundColor: statusInfo.color,
              color: 'white',
            }"
            class="ml-1 px-2"
          >
            {{ statusInfo.desc.toUpperCase() }}
          </BBadge>
        </div>
      </div>
    </div>

    <div class="order-section">
      <p class="order-eta">
        <i
          class="fas fa-clock mr-1"
          :style="{
            color: statusInfo.color,
          }"
        />

        <span v-if="isDraft || isReleased || isActivated">
          {{ $t('orders.scheduled') }}: {{ scheduledExecution && scheduledExecution.begin | formatDate }}
          <span v-if="duration">
            (<span v-html="duration" />)
          </span>
        </span>
        <span v-if="isStarted">
          {{ $t('order.eta') }}: {{ estimatedEnd | formatDate }}
          <span v-if="estimatedEnd">
            ({{ estimatedEndDuration }})
          </span>
        </span>
        <span v-if="isCompleted">
          {{ $t('orders.completion') }}:
          {{ actualExecution && actualExecution.begin | formatDate }} -
          {{ actualExecution && actualExecution.end | formatDate }}
        </span>
      </p>

      <p
        v-if="isStarted"
        class="order-info-row"
      >
        <span class="order-info-row-title">
          {{ $t('orders.started') }}:
        </span>

        <span>
          {{ actualExecution && actualExecution.begin | formatDate }}
        </span>
      </p>

      <p
        v-if="isStarted || isCompleted"
        class="order-info-row soft-hide"
        :class="{
          'soft-hide--hidden': !selected,
        }"
      >
        <span class="order-info-row-title">
          {{ $t('orders.scheduled') }}:
        </span>

        <span>
          {{ scheduledExecution && scheduledExecution.begin | formatDate }} -
          {{ scheduledExecution && scheduledExecution.end | formatDate }}
        </span>
      </p>
    </div>

    <div
      v-if="isOrder"
      class="order-section"
    >
      <p class="order-info-row">
        <span class="order-info-row-title">
          {{ $t('orders.skuNumber') }}:
        </span>

        <span>
          {{ skuNumber }}

          <BSpinner
            v-if="pendingSkuErrors"
            small
          />
          <span
            v-else
            v-b-tooltip.top="$t('orders.viewItemDetails')"
            class="cursor-pointer ml-1"
            :class="{'text-danger': hasSkuErrors}"
            @click.prevent.stop="showItemForm"
          >
            <i class="fas fa-external-link" />
          </span>
        </span>
      </p>

      <p
        class="order-info-row soft-hide"
        :class="{
          'soft-hide--hidden': !selected,
        }"
      >
        <span class="order-info-row-title">
          {{ $t('orders.skuDescription') }}:
        </span>

        <span>
          {{ skuDescription || '-' }}
        </span>
      </p>

      <p
        v-for="(f, index) in additionalFields"
        :key="index"
        class="order-info-row soft-hide"
        :class="{
          'soft-hide--hidden': !selected,
        }"
      >
        <span class="order-info-row-title">
          {{ f.name }}:
        </span>

        <span>
          {{ f.value }}
        </span>
      </p>
    </div>

    <div class="order-section bars-section">
      <div
        v-if="isStartedOrCompleted"
        style="margin-top: 10px"
      >
        <div
          v-if="orderedQuantity"
          class="d-flex justify-content-between font-weight-bold"
        >
          <div>
            <i class="far fa-clipboard pr-1" />
            {{ producedFormatted | round | addSpaces }} |
            <span v-if="wastedQuantity">
              <span class="text-danger">
                {{ wastedFormatted | round | addSpaces }}
              </span> |
            </span>
            {{ plannedFormatted | round | addSpaces }}
            {{ (orderedQuantity.unit) }}
          </div>
          <div>
            {{ producedPercentage | integer }}%
          </div>
        </div>

        <ProductionChart
          v-if="orderedQuantity"
          :done="producedQuantity && producedQuantity.value || 0"
          :wasted="wastedQuantity && wastedQuantity.quantity || 0"
          :estimated="estimated && estimated.quantity || 0"
          :planned="orderedQuantity.value"
          :unit="orderedQuantity.unit"
          :hide-labels="true"
          :order-completed="isCompleted"
          style="height: 20px;"
        />
      </div>
      <div v-else-if="orderedQuantity">
        <div>
          <i class="far fa-clipboard mr-1" />

          {{ ( orderedQuantity.value || 0) | addSpaces({unbreakable:true}) }}{{ `\xa0${orderedQuantity.unit}` }}
        </div>
      </div>

      <div
        v-if="displayLabour && isStartedOrCompleted"
        style="margin-top: 10px"
      >
        <div class="d-flex justify-content-between font-weight-bold">
          <div>
            <i class="fas fa-male pr-1" />
            {{ actualEffort ? actualEffort.manhours : 0 | round | addSpaces }} |
            {{ expectedEffort ? expectedEffort.manhours : 0 | round | addSpaces }}
            {{ 'mh' }}
          </div>
          <div>
            {{ manHoursPercentage | integer }}%
          </div>
        </div>

        <ProductionChart
          :done="actualEffort && actualEffort.manhours || 0"
          :planned="expectedEffort && expectedEffort.manhours || 0"
          :unit="'mh'"
          :hide-labels="true"
          :order-completed="isCompleted"
          style="height: 20px;"
        />
      </div>
      <div
        v-else-if="displayLabour && expectedEffort"
        class="soft-hide"
        :class="{
          'soft-hide--hidden': !selected,
        }"
      >
        <i class="fas fa-male pr-1" />
        {{ expectedEffort ? expectedEffort.manhours : 0 | round | addSpaces }} {{ 'mh' }}
      </div>
    </div>

    <div
      v-if="validationErrors.length"
      class="order-section p-1"
      style="background-color: #FFF4F0;"
    >
      <div class="p-1">
        <BSpinner
          v-if="loadingValidationErrors"
          small
          variant="danger"
        />
        <ValidationErrors
          :validation-errors="validationErrors"
        />
      </div>
    </div>

    <LModal
      :show.sync="itemFormModal"
      :lazy="true"
      class="col-md-5"
      size="xl"
    >
      <button
        type="button"
        class="close modal-close position-absolute"
        @click="itemFormModal = false"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row px-4">
        <ItemForm
          v-if="!pendingSkuErrors"
          :plant-id="plantId"
          :sku="sku"
          style="width: 100%"
          :init-page="hasSkuErrors ? 'validationErrors' : 'details'"
          @cancelled="itemFormModal = false"
        />
      </div>
    </LModal>
  </div>
</template>

<script>
import ValidationErrors from '@/components/activity/ValidationErrors';
import ProductionChart from '@/components/charts/production/ProductionChart';
import loopRequest from '@/mixins/loopRequest';
import calcDuration from '@/utils/calcDuration';
import { activityStatus, activityType } from '@/utils/dictionary';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import ItemForm from '@/components/item/ItemForm.vue';

export default {
  props: {
    id: String,
    description: String,
    lineId: String,
    scheduledExecution: Object,
    estimated: Object,
    externalId: String,
    status: String,
    statusDesc: Object,
    hoverable: {
      type: Boolean,
      default: true,
    },
    hideElement: Boolean,
    selected: Boolean,
    orderedQuantity: Object,
    producedQuantity: Object,
    wastedQuantity: Object,
    actualExecution: Object,
    productId: String,
    estimatedEnd: String,
    additionalFields: Array,
    type: String,
    validationErrorCount: Number,
    actualEffort: Object,
    expectedEffort: Object,
  },
  data: () => ({
    activityStatus,
    now: 0,
    elementForm: false,
    loadingValidationErrors: false,
    validationErrors: [],
    pendingSkuErrors: false,
    skuErrors: [],
    itemFormModal: false,
  }),
  components: {
    ItemForm,
    ValidationErrors,
    ProductionChart,
  },
  filters: {
    formatDate(d) {
      if (!d) return '...';
      return moment(d).format('Do\xa0MMM\xa0HH:mm');
    },
    round(v) {
      return Math.round((v || 0) * 100) / 100;
    },
  },
  mixins: [loopRequest('updateNow', 3000)],
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters(['getActivityState', 'getActivityType']),
    ...mapGetters('element', ['elementName']),
    ...mapGetters('plant', ['displayLabour']),
    ...mapState({
      items: state => state.item.items,
    }),
    hasSkuErrors() {
      return this.skuErrors.length > 0;
    },
    isOrder() {
      return this.type === activityType.order;
    },
    isDraft() {
      return this.statusInfo.name === this.activityStatus.draft;
    },
    isStarted() {
      return this.statusInfo.name === this.activityStatus.started;
    },
    isReleased() {
      return this.statusInfo.name === this.activityStatus.released;
    },
    isActivated() {
      return this.statusInfo.name === this.activityStatus.activated;
    },
    isStartedOrCompleted() {
      return this.isStarted || this.isCompleted;
    },
    sku() {
      return this.items?.find(i => i.id === this.productId);
    },
    skuNumber() {
      return this.sku?.skuNo;
    },
    skuDescription() {
      return this.sku?.description;
    },
    isCompleted() {
      return this.status === activityStatus.completed || this.statusInfo.name === activityStatus.completed;
    },
    statusInfo() {
      return this.getActivityState({ status: this.statusDesc?.name || this.status });
    },
    activityInfo() {
      return this.getActivityType({ type: this.type });
    },
    producedFormatted() {
      return Math.round(((this.producedQuantity?.value) || 0 + Number.EPSILON) * 100) / 100;
    },
    wastedFormatted() {
      return Math.round((this.wastedQuantity?.value + Number.EPSILON) * 100) / 100;
    },
    plannedFormatted() {
      return Math.round((this.orderedQuantity.value || 0 + Number.EPSILON) * 100) / 100;
    },
    producedPercentage() {
      return (this.producedQuantity?.value && this.orderedQuantity?.value
        && (this.producedQuantity?.value / this.orderedQuantity?.value) * 100) || 0;
    },
    manHoursPercentage() {
      return (this.actualEffort?.manhours && this.expectedEffort?.manhours
        && (this.actualEffort?.manhours / this.expectedEffort?.manhours) * 100) || 0;
    },
    duration() {
      if (this.status || this.statusDesc?.desc === activityStatus.started) {
        const end = moment(this.scheduledExecution?.end).unix();
        if (!end) return '';
        if (end < this.now) {
          return `<span class="text-danger">
            <b>${calcDuration(end, this.$now)}</b> ${this.$t('orders.afterPlannedEnd')}
          </span>`;
        }
        return `<b>${calcDuration(this.$now, end)}</b> ${this.$t('orders.toPlannedEnd')}`;
      }
      if (this.status || this.statusDesc?.desc === activityStatus.released) {
        const start = moment(this.scheduledExecution?.begin).unix();
        if (!start) return '';
        if (start < this.now) {
          return `<span class="text-danger">
            <b>${calcDuration(start, this.$now)}</b> ${this.$t('orders.afterPlannedStart')}
          </span>`;
        }
        return `<b>${calcDuration(this.$now, start)}</b> ${this.$t('orders.toPlannedStart')}`;
      }
      return '';
    },
    estimatedEndDuration() {
      return calcDuration(this.now, moment(this.estimatedEnd).unix());
    },
    hasValidationErrors() {
      return this.status === this.activityStatus.draft && this.validationErrorCount;
    },
  },
  watch: {
    validationErrorCount() {
      this.getValidationErrors();
    },
  },
  methods: {
    ...mapActions(['getOrderErrors']),
    ...mapActions([
      'getSkuValidationErrors',
    ]),
    async requestSkuValidationErrors() {
      if (!this.sku?.id) return;
      try {
        this.pendingSkuErrors = true;
        const { data } = await this.getSkuValidationErrors({
          params: {
            plantId: this.plantId,
            skuId: this.sku.id,
          },
        });
        this.skuErrors = data;
      } catch ({ response }) {
        this.$root.$bvToast
          .toast(response.data?.detail || JSON.stringify(response.data), {
            title: this.$t('error.error'),
            variant: 'danger',
            appendToast: true,
            toaster: 'b-toaster-top-right',
          });
      } finally {
        this.pendingSkuErrors = false;
      }
    },
    calcDuration,
    updateNow() {
      this.now = this.$now;
    },
    formatToEpoch(d) {
      return moment(d).unix();
    },
    getValidationErrors() {
      if (!this.id) return;

      this.loadingValidationErrors = true;
      this.getOrderErrors({
        params: {
          orderId: this.id,
          query: { plantId: this.plantId },
        },
      }).then(({ data }) => {
        this.validationErrors = data;
      }).finally(() => {
        this.loadingValidationErrors = false;
      });
    },
    showItemForm() {
      this.itemFormModal = true;
    },
  },
  created() {
    this.now = this.$now;
    this.requestSkuValidationErrors();
    if (this.validationErrorCount > 0) {
      this.getValidationErrors();
    }
  },
};
</script>

<style lang="scss" scoped>

  .order-box {
    background-color: white;
    box-shadow: 0 1px 7px #e2e2e3;
    border-radius: 5px;
    padding: 20px;
    border-left-width: 3px;
    border-left-style: solid;

    &.selected {
      background-color: #F9F9F9;
      box-shadow: 0 1px 7px #989898;
      border: 1px solid;
      border-left-width: 3px;
    }

    .order-section {
      margin-bottom: 18px;

      &.bars-section {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .order-number {
      color: #7E7E7E;
      font-size: 12.5px;
      line-height: 14.65px;
      text-align: left;
      margin-bottom: 3px;
    }

    .element-name {
      font-size: 12.5px;
      font-weight: 700;
      line-height: 14.65px;
      color: #7E7E7E;
      margin-bottom: 3px;
    }

    .order-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 18.75px;
      color: #272A2D;
    }

    .order-eta {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      color: #272A2D;
      margin-bottom: 3px;
    }

    .order-info-row {
      font-size: 12.5px;
      font-weight: 400;
      line-height: 14.65px;
      color: #7E7E7E;
      margin-bottom: 3px;

      &-title {
        font-weight: 700;
      }
    }
  }

  .soft-hide {
    transform: scaleY(1);
    max-height: 200px;
    overflow: hidden;

    &--hidden {
      max-height: 0;
      transform: scaleY(0);
    }
  }

</style>
