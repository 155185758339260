<template>
  <div>
    <div class="d-flex justify-content-center">
      <PlantBreadcrumb
        breadcrumb="machine"
        :element-id="elementId"
        class="order-md-2 col-12 col-md-auto"
      />
    </div>

    <div class="d-flex justify-content-center py-3">
      <SubPageSelector
        class="justify-content-end"
        :list="routes"
        :selected="selectedPage"
        @selected="changePage"
      />
    </div>

    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlantBreadcrumb from '@/layout/dashboard/PlantBreadcrumb';
import SubPageSelector from '@/layout/dashboard/selectors/SubPageSelector';
import { elementCapabilities } from '@/utils/dictionary';

export default {
  props: {
    elementId: {
      type: String,
      required: true,
    },
    selectedPage: String,
  },
  components: {
    SubPageSelector,
    PlantBreadcrumb,
  },
  computed: {
    ...mapGetters(['plantId']),
    ...mapGetters('core', ['canOnElement']),
    ...mapGetters('element', ['hasCapabilities']),
    routes() {
      return [
        {
          key: 'details',
          icon: 'fas fa-info-circle',
          name: this.$t('general.details'),
          route: `/${this.plantId}/element/${this.elementId}/details`,
          show: true,
        },
        {
          key: 'params',
          icon: 'ion ion-ios-stats',
          name: this.$t('processParams.signals'),
          route: `/${this.plantId}/element/${this.elementId}/params`,
          show: true,
        },
        {
          key: 'counters',
          icon: 'ion ion-ios-speedometer',
          name: this.$t('monitoring.machine.counters'),
          route: `/${this.plantId}/element/${this.elementId}/counters`,
          show: this.showProductionRoutes,
        },
      ].filter(x => x.show);
    },
    showProductionRoutes() {
      return this.hasCapabilities(this.elementId, [
        elementCapabilities.PRODUCTION,
      ]);
    },
    currentRoute() {
      const filtered = this.routes.filter(el => el.selected);
      if (filtered.length > 0) {
        return filtered[0];
      }
      return {
        extendedDateSelector: false,
      };
    },
  },
  methods: {
    changePage({ key, route }) {
      this.selected = key;
      this.$router.push(route);
    },
  },
};
</script>

<style>

</style>
