<template>
  <div>
    <div>
      <div class="progress-bar-container">
        <div
          class="bar-style progress"
          style="border-radius: 15px"
        >
          <div
            :style="{
              width: `${percentage}%`,
              'background-color': percentage && color,
            }"
            aria-valuemax="100"
            aria-valuemin="0"
            role="progressbar"
          />
        </div>
        <div
          class="bar-style d-flex justify-content-center text-white"
          style="z-index: 2"
        >
          <div class="m-auto">
            <i
              v-if="iconClass"
              :class="iconClass"
            />
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: Number,
    color: String,
    label: String,
    iconClass: String,
  },
};
</script>

<style lang="scss" scoped>
.bar-style {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 0.656rem;
}

.progress-bar-container {
  height: 20px;
  position: relative;
}

.progress {
  background-color: lightgrey;
}
</style>
