export default {
  'error': 'Erro',
  'unknown': 'Erro desconhecido',
  'unableToDownloadData': 'Incapaz de baixar os dados solicitados',
  'jobFailed': 'Ocorreu um erro no processamento do arquivo',
  'wrongInput': 'Os dados fornecidos são inválidos!',
  'passwordsMustBeEqual': 'As senhas devem ser iguais!',
  'toShortPassword': 'A senha deve ter pelo menos {número} caracteres',
  'descriptionIsRequired': 'É necessária a descrição',
  'reasonRequired': 'Primeiramente criar ou selecionar motivo',
  'criticalPleaseRefresh': 'Ah, que pena! Ocorreu um erro, tente atualizar a página',
  'invalidHour': 'Hora inválida',
  'importNotAvailable': 'A importação não está disponível no momento. Tente novamente mais tarde',
  'packageAddError': 'Pacote inválido',
  'code': {
    'NoRequiredComponents': 'Falta de componentes necessários:',
    'NoTrackingId': 'Nenhuma identificação de rastreamento para pacotes de rastreamento'
  },
  'importError': 'Nenhum item para importar foi encontrado. Modelo de importação errado?',
  'nameExist': 'O nome dado já existe!',
  'numberExist': 'O número dado já existe!',
  'invalidAuthUrl': 'URL de autenticação inválido'
};
