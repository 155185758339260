export default {
  activities: 'Activities',
  activityType: 'Activity type',
  addActivity: 'Plan new activity',
  add: 'Add activity',
  edit: 'Edit activity',
  addChangeover: 'Add changeover',
  step: 'Step',
  order: 'Order',
  general: 'General',
  quantity: 'Quantity',
  dates: 'Dates',
  configuration: 'Configuration',
  plannedStart: 'Planned start',
  plannedEnd: 'Planned end',
  cleaning: 'Cleaning',
  changeover: 'Changeover',
  activityId: 'Activity ID',
  activity: 'Activity',
  activityStart: 'Activity start',
  toStart: 'to start',
  afterStart: 'after start',
  activityAlreadyExists: 'Activity ID already exists',
  activityIdIsTooLong: 'Activity ID is too long',
  changeoverDuration: 'Changeover duration (minutes)',
  manage: 'Manage activities',
  changeoverType: 'Changeover type',
  editExisting: 'Edit existing',
  pickALine: 'Pick a line',
  typeEffort: 'Type effort',
  showNotPlanned: 'Show not planned activities',
  summary: {
    summary: 'Summary',
    orderTotal: 'Order total',
    lineTotal: 'LINE TOTAL',
    inLineUnit: 'in line unit',
    inOrderUnit: 'in order unit',
    goodCount: 'GOOD COUNT',
    wasteCount: 'WASTE COUNT',
    counterTotal: 'Counter Total',
    performanceGuard: 'Performance Guard',
    stateGuard: 'State Guard',
    performanceGuardTippy: 'Performance threshold exceeded',
    missingPackingStructure: 'Missing packing structure',
    manualReconciliation: 'Manual reconciliation',
  },
  list: {
    order: 'Orders',
    cleaning: 'Cleanings',
    changeover: 'Changeovers',
  },
  activityAdded: 'Activity added',
  noActivities: 'No activities',
  status: {
    released: 'Released',
    draft: 'Draft',
    started: 'Started',
    suspended: 'Suspended',
    completed: 'Completed',
    activated: 'Activated',
    scheduled: 'Scheduled',
    notPlanned: 'Not planned',
  },
  schedule: {
    tableView: 'Table',
    axisView: 'Timeline',
    full: 'Full',
    collapsed: 'Collapsed',
    scheduled: 'Scheduled',
    currentHour: 'End of current hour',
    previousHour: 'Previous hour',
    previousShift: 'Previous shift',
    currentShift: 'Current shift',
  },
  resources: {
    production: 'Production',
    waste: 'Waste',
    manhours: 'Manhours',
    timeScope: 'Time scope',
    workers: 'Workers',
    worker: 'Worker',
    history: 'History',
    noEntries: 'No entries',
    hours: 'hours',
    minutes: 'minutes',
  },
  actions: {
    finishChangeover: 'Finish and start order',
    abortChangeover: 'Abort changeover',
    productionReconciliation: 'Production reconciliation',
    finishOrder: 'Finish order',
  },
  productionReconciliation: {
    increase: 'Increase',
    fromTo: 'From - To',
    rawCounter: 'Raw counter',
    who: 'Who',
    lastRefresh: 'Last refresh',
    saveEntry: 'Save entry',
    saveAndFinishOrder: 'Save and finish order',
    arithmeticExpressionsInfoBoxText: 'In the input fields you can use simple arithmetic expressions, for example “100-10”. After you hit “save entry” system will save and count the value.',
    entryWithDifferentUnitError: 'Some entries units differs from order unit. To add new entry delete entry with different unit.',
    orderUnit: 'Order unit',
    manualIncrease: 'Manual increase',
    rawCounterIncrease: 'Raw counter increase',
  },
  validations: {
    orderNotFound: 'Related order not found',
    externalIdError: "ID can contain only numbers, letters, characters '-', '/' and '_' and may only start with a number or a letter",
  },
  deleteConfirmMessage: 'Are you sure you want to delete this activity?',
  hasValidationErrors: 'Has validation errors',
  noLine: 'No line',
  editOrder: 'Edit order',
  editChangeover: 'Edit changeover',
  cannotCreateOrder: 'Cannot create order',
  orderExists: 'Order with specified Activity ID exists.',
  endOfLastShift: 'End of last shift',
  endOfLastHour: 'End of last hour',
  productionHistory: 'Production history',
  manhoursHistory: 'Manhours history',
  changeoverDurationTitle: 'Changeover duration',
  expectedEffort: 'Expected effort (mh)',
  expectedChangeoverEffort: 'Expected changeover effort (mh)',
  typeCorrectExpectedEffort: 'Expected effort cannot be negative',
  typeCorrectValue: 'Value cannot be negative',
  typeChangeoverDurationIfChangeoverExpectedEffortGiven: 'Changeover expected duration is required when expected changeover effort is given',
  editMode: 'Edit mode',
  validationErrors: {
    skuError: 'Product is missing or configured incorrectly',
    flowIdError: 'Flow configuration is missing',
    endDateNotValid: 'Planned end date is not valid',
    lineError: 'Line configuration is missing',
    orderedQuantityError: 'Ordered quantity is missing',
    orderedQuantityUnitError: 'Ordered quantity unit is missing or configured incorrectly',
  },
};
