// eslint-disable-next-line import/prefer-default-export
export const customLayoutWidgetFieldType = {
  static: 'STATIC',
  tag: 'TAG',
  noInput: 'NO_INPUT',
};

export const customLayoutWidgetType = {
  text: 'TEXT',
  gaugeChart: 'GAUGE_CHART',
  percentageChart: 'PERCENTAGE_CHART',
  progressBar: 'PROGRESS_BAR',
};
