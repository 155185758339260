export default {
  rules: 'Правила',
  addRule: 'Добавить правило',
  countingOEE: 'Подсчет OEE',
  excludeOEE: 'Исключить OEE',
  thresholds: 'Пороги',
  saved: 'Сохранено',
  baseUnit: 'Базовый блок',
  notSelected: 'Не выбран',
  saveFailed: 'Сохранить не удалось',
  addMachine: 'Добавить машину к фильтрам',
  rule: {
    rule: 'Правило', condition: 'Состояние', addAndCondition: 'И', showAdvanced: 'Использование расширенного редактора', info: 'состояние машины определяется на основании приведенных ниже правил. Когда наблюдается изменение значения сигнала, \\n правило-двигатель будет анализировать правила ниже, начиная с самого высокого приоритета. \\n Первое правило, которое будет «истинно» определит новое состояние',
  },
  events: { forcedOnly: 'Только принудительно', showSystem: 'Показать события о системе' },
  value: 'Значение',
  priority: 'Приоритет',
  dayOfWork: 'День работы',
  plantConfiguration: 'Конфигурация завода',
  machineSignals: 'Сигналы машины',
  signals: 'Сигналы',
  signalsExport: 'Экспорт сигналов',
  logixGateConfigExport: 'Экспорт конфигурации LogixGate',
  signalsImport: 'Импорт сигналов',
  workEventsImport: 'Проблемы с импортом',
  csvImport: 'Импортировать CSV',
  csvExport: 'Экспорт CSV',
  properties: 'Характеристики',
  signalsAndProperties: 'Сигналы и свойства',
  machineLicenses: 'Лицензии на машину',
  addSignal: 'Добавить сигнал',
  addProperty: 'Добавить недвижимость',
  electricity: 'Электричество',
  electricityBaseline: 'Базовый уровень электроэнергии',
  electricityMax: 'Электричество в среднем макс.',
  shiftDefinitions: 'Определения Смены',
  info: 'Информация',
  deleteInfo: 'Нажмите кнопку «Сохранить», чтобы удалить',
  differentTimezoneInfo: 'Вы находитесь в другом часовом поясе, чем на заводе!',
  differentTimezoneSettingsInfo: 'Приведенные ниже настройки будут применены к заводскому часовому поясу.',
  differentTimezoneSelectorInfo: 'Мы будем отображать время, преобразованное в ваш часовой пояс',
  wasteBaseDiffCounter: 'Базовая машина для расчета дифференциальных отходов',
  workingHours: 'Рабочие часы',
  addNewShift: 'Добавить новую смену',
  productionExpectations: 'Производственные цели',
  doYouWantToDelete: 'Вы хотите удалить?',
  newShift: 'Новая смена',
  editShift: 'Изменить смену',
  removeShift: 'Удалить смену',
  users: 'Пользователи',
  nothingToAdd: 'Нечего не добавлять',
  attachedLinesAndMachines: 'Прикрепленные линии и машины',
  addLineOrMachine: 'Добавить линию / машину',
  layout: 'Местоположение',
  saveLayout: 'Сохранить местоположение',
  elementsOrder: 'порядок элементов',
  advanced: 'Передовой',
  default: 'По умолчанию',
  noLayoutLine: 'Линия не определена в схеме завода',
  units: 'Единицы',
  unitCode: 'Код объекта',
  isBaseUnit: 'Это база',
  fromUnit: 'Ведомая единиа измерения',
  toUnit: 'Ведущая единица измерения',
  multiplier: 'Множитель',
  shortName: 'Короткое имя',
  blockCard: 'Заблокировать карту',
  activeSince: 'Действует с',
  production: 'Производство',
  card: 'Карта',
  cardId: 'Идентификатор карты',
  cardPin: 'PIN-код карты',
  oldPin: 'Предыдущий PIN-код',
  newPin: 'Новый PIN-код',
  pinChange: 'Изменить PIN-код',
  pinLengthError: 'ПИН-код должен состоять из 4 цифр.',
  cardIdLengthError: 'Идентиф. карты должен содерж. не менее 4 символов',
  connections: 'Потоки',
  displaySettings: 'Отображать',
  adminPlantSettings: 'Админ настройки заводов',
  orderAutostartThreshold: 'Заказать автозапуск',
  forcedStateThreshold: 'Прекращение вынужденного простоя',
  downtimeThreshold: 'Прекращение продолжающегося простоя',
  minorStopThreshold: 'Порог малой остановки',
  designPerformanceInfo: 'Производительность машины, которая может быть достигнута \\n по данным производителя',
  validatedPerformanceInfo: 'Производительность машины, которая была \\n измерена и подтверждена в реальных условиях',
  orderAutostartThresholdInfo: 'Порог, определяющий количество \\n продукции, необходимое для автоматического запуска заказа',
  forcedStateThresholdInfo: 'Порог, определяющий количество \\n продукции, необходимое для автоматического завершения вынужденного простоя',
  downtimeThresholdInfo: 'Порог, определяющий количество \\n производства, необходимое для автоматического завершения текущего простоя',
  expectations: {
    expectedOee: 'Ожидаемый OEE', expectedPerformance: 'Ожидаемая производительность', maxExpectedFailures: 'Ожидаемое максимальное количество отказов', validatedPerformance: 'Подтвержденная производительность', designPerformance: 'Исполнение дизайна',
  },
  automation: {
    automation: 'Автоматизация', add: 'Добавить автоматизацию', 'type__|': 'Быстрая проверка рабочих событий', 'type__|__title': 'Создать задачу', 'type__|__description': 'Создавать задачу при возникновении проблемы такого типа на машине',
  },
  unit: { usedInPackageStructure: 'Снять блок невозможно, он используется в определении структуры пакета' },
};
